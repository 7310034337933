import { Check, ChevronLeft, ChevronRight, RefreshCcw } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextEditor from "../globalcomponents/TextEditor";
import { apiClient } from "../../actions/api";
import { FetchData } from "../../actions/data";
import { REASONS } from "../../constants/actions";

const Reasons = ({
  formData,
  handleChange,
  SubmitHandler,
  ResetHandler,
  edit,
}) => {
  const reasons = useSelector((state) => state?.data?.reasons) || [];
  const [reasonsData, setReasonsData] = useState(reasons);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (reasons) {
      setReasonsData(reasonsData);
    }
  }, [reasons]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getAvisSlide = useCallback(() => {
    // if (windowWidth >= 1280) return 2;
    // if (windowWidth >= 1024) return 2;
    if (windowWidth >= 768) return 2;
    return 1;
  }, [windowWidth]);

  useEffect(()=>{
    setReasonsData(reasons);
  },[edit])

  const nextSlide = () => {
    const imagesPerSlide = getAvisSlide();
    const maxIndex = Math.ceil(reasons?.length / imagesPerSlide) - 1;
    if (currentIndex < maxIndex) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    try {
      let isChanged = false;
      const changedReasons = reasonsData?.filter((reason) => {
        if (
          JSON.stringify(reason) !==
          JSON.stringify(reasons?.find((reas) => reas?.id === reason?.id))
        ) {
          isChanged = true;
          return reason;
        }
      });

      SubmitHandler(e, ["titleWhyUs"], isChanged);

      for (const reason of changedReasons) {
        await apiClient().put(`/reasons/${reason?.id}`, {
          title: reason?.title,
          description: reason?.description,
        });
      }

      dispatch(FetchData("/reasons", REASONS));
    } catch (err) {
      console.error(err);
    }
  };

  const ResetFunc = () => {
    ResetHandler(["titleWhyUs"]);
    setReasonsData(reasons);
  };

  const handleReasonChange = useCallback(
    (id, field, value) => {
      setReasonsData((prevReasonsData) =>
        prevReasonsData?.map((item) =>
          item.id === id ? { ...item, [field]: value } : item
        )
      );
    },
    [setReasonsData]
  );

  return (
    <form onSubmit={SubmitForm}>
      <div className="flex items-center justify-between">
        <h1 className="text-lg md:text-xl lg:text-2xl font-semibold my-2">
          Section 2
        </h1>
      </div>
      <div className="space-y-4 p-2 border rounded-lg bg-white">
        {edit && (
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              onClick={ResetFunc}
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-gray-500 hover:bg-gray-600"
            >
              <RefreshCcw className="w-4 h-4 md:h-6 md:w-6" />
            </button>
            <button
              type="submit"
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-blue-900 hover:bg-blue-950"
            >
              <Check className="w-4 h-4 md:h-6 md:w-6" />
            </button>
          </div>
        )}
        <div className="space-y-4">
          <div className="flex flex-col gap-2">
            <label htmlFor="titleWhyUs">Titre section 2</label>
            <input
              type="text"
              name="titleWhyUs"
              id="titleWhyUs"
              placeholder="Titre section 2"
              value={formData?.titleWhyUs || ""}
              onChange={handleChange}
              className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                !edit && "opacity-70"
              }`}
              readOnly={!edit}
              required={true}
            />
          </div>
          <div className="overflow-hidden">
            <div
              className="flex transition-transform duration-300 ease-in-out"
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
              }}
            >
              {Array.from({
                length: Math.ceil(reasons?.length / getAvisSlide()),
              }).map((_, slideIndex) => (
                <div
                  key={`slide-${slideIndex}`}
                  className="w-full flex-shrink-0 flex gap-2"
                >
                  {reasonsData
                    ?.slice(
                      slideIndex * getAvisSlide(),
                      (slideIndex + 1) * getAvisSlide()
                    )
                    ?.map((item) => (
                      <div
                        key={item.id}
                        className={`px-1 relative space-y-2 ${
                          getAvisSlide() === 1
                            ? "w-full"
                            : getAvisSlide() === 2
                            ? "w-1/2"
                            : getAvisSlide() === 3
                            ? "w-1/3"
                            : "w-1/4"
                        }`}
                      >
                        <div className="flex flex-col gap-1">
                          <label htmlFor={`reason${item?.id}`}>
                            Avis {item?.id}
                          </label>
                          <input
                            type="text"
                            name={`reason${item?.id}`}
                            id={`reason${item?.id}`}
                            placeholder="Titre"
                            value={item?.title || ""}
                            onChange={(e) =>
                              handleReasonChange(
                                item?.id,
                                "title",
                                e.target.value
                              )
                            }
                            className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                              !edit && "opacity-70"
                            }`}
                            readOnly={!edit}
                            required={true}
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <label htmlFor={`desc${item?.id}`}>Description</label>
                          <div className="min-h-fit h-fit bg-white overflow-hidden border-b">
                            <TextEditor
                              texteditor={item?.description || ""}
                              setTexteditor={(value) =>
                                handleReasonChange(
                                  item?.id,
                                  `description`,
                                  value
                                )
                              }
                              disabled={!edit}
                              style={!edit ? "opacity-70" : ""}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
          {reasons?.length > 0 && (
            <div className="flex items-center justify-end gap-4">
              <div className="font-bold">
                Slide {currentIndex + 1} /{" "}
                {Math.max(1, Math.ceil(reasons?.length / getAvisSlide()))}
              </div>
              <div className="flex gap-3">
                <button
                  type="button"
                  onClick={prevSlide}
                  className={`p-3 rounded-full overflow-hidden ${
                    currentIndex === 0
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "bg-gray-300 text-gray-800 hover:bg-gray-800 hover:text-white"
                  }`}
                  disabled={currentIndex === 0}
                >
                  <ChevronLeft className="w-5 h-5 md:h-7 md:w-7" />
                </button>
                <button
                  type="button"
                  onClick={nextSlide}
                  className={`p-3 rounded-full overflow-hidden ${
                    currentIndex ===
                    Math.ceil(reasons?.length / getAvisSlide()) - 1
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "bg-gray-300 text-gray-800 hover:bg-gray-800 hover:text-white"
                  }`}
                  disabled={
                    currentIndex ===
                    Math.ceil(reasons?.length / getAvisSlide()) - 1
                  }
                >
                  <ChevronRight className="w-5 h-5 md:h-7 md:w-7" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default Reasons;
