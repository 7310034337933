import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { MagnifyingGlassIcon, ClipboardDocumentListIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import ParametresLayout from '../../components/parametresComponents/ParametresLayout';
import { ComplaintesListe, Suggestions } from '../../components';
import { useAuth } from '../../context/authContext';

const Complaints = () => {
  const data = useSelector(state => state?.data);
  const [filteredComplaints, setFilteredComplaints] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const { isSuperAdmin } = useAuth();

  useEffect(() => {
    if (!searchInput) {
      setFilteredComplaints(data?.complaints);
      return;
    }

    const searchLowerCase = searchInput?.trim().toLowerCase();

    const filtered = data?.complaints?.filter((complaint) => {
      const fullName = `${complaint?.user?.first_name} ${complaint?.user?.last_name} ${complaint?.guest?.name}`.toLowerCase();
      const reversedFullName = `${complaint?.user?.last_name} ${complaint?.user?.first_name} ${complaint?.guest?.name}`.toLowerCase();
      const email = complaint?.user?.email.toLowerCase();

      return (
        fullName?.includes(searchLowerCase) ||
        reversedFullName?.includes(searchLowerCase) ||
        email?.includes(searchLowerCase)
      );
    });

    setFilteredComplaints(filtered);
  }, [searchInput, data?.complaints]);

  return (
    <ParametresLayout>
      <div className="px-2 py-6 min-h-screen bg-gray-50">
        <h1 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
          <ClipboardDocumentListIcon className="w-8 h-8 mr-2 text-blue-600" />
          Tableau de bord des plaintes
        </h1>
        <div className="flex flex-col xl:flex-row gap-4">
          <div className="w-full xl:w-7/12 bg-white rounded-lg shadow-md p-4">
            <h2 className="text-xl font-semibold mb-4 flex items-center">
              <ChatBubbleBottomCenterTextIcon className="w-6 h-6 mr-2 text-green-600" />
              Liste des plaintes
            </h2>
            <div className="mb-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Recherche par nom ou email"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border rounded-full outline-none focus:ring-2 focus:ring-blue-300 transition duration-150 ease-in-out"
                />
                <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
              </div>
            </div>
            <ComplaintesListe
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              complaints={filteredComplaints}
            />
          </div>
          {isSuperAdmin && (
            <div className="w-full xl:w-5/12 bg-white rounded-lg shadow-md p-4">
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <ChatBubbleBottomCenterTextIcon className="w-6 h-6 mr-2 text-purple-600" />
                Suggestions
              </h2>
              <Suggestions suggestions={data?.suggestions} />
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </ParametresLayout>
  );
};

export default Complaints;