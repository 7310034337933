
const getBookingStatus = (date, start_time, end_time, defaultStatus = 1) => {
    const now = new Date();
    const bookingDate = new Date(`${date}T${start_time}`);
    const bookingEndDate = new Date(`${date}T${end_time}`);

    if(!defaultStatus){
        return { type: "Annulé", style: "text-red-900 bg-red-200" }
    }else if (now < bookingDate) {
        return { type: "En Attente", style: "text-blue-900 bg-blue-200" }
    } else if (now >= bookingDate && now <= bookingEndDate) {
        return { type: "En Cours", style: "text-green-900 bg-green-200" }
    } else if (now > bookingEndDate) {
        return { type: "Terminé", style: "text-gray-900 bg-gray-200" }
    }else{
        return;
    }
}

export default getBookingStatus;
