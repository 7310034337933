import React from "react";
import { ProfileImg } from "../../../assets/images";
import { BASE_IMAGE_URL } from "../../../constants/actions";
import formatDate2 from "../../../helpers/formatDate2";
import FormateDateRange from "../../../helpers/FormateDateRange";
import getBookingStatus from "../../../helpers/getBookingStatus";
import { CreditCardIcon, CalendarIcon, BuildingOfficeIcon, ScissorsIcon, UserIcon, EnvelopeIcon, PhoneIcon, UserCircleIcon, CurrencyEuroIcon, CheckCircleIcon, ClockIcon, IdentificationIcon } from '@heroicons/react/24/outline';
import { CoinsIcon } from "lucide-react";

const PaiementsDetailsComponent = ({ payment }) => {
  const InfoItem = ({ icon: Icon, label, value }) => (
    <div className="flex flex-col gap-2">
      <p className="text-gray-500 text-sm flex items-center">
        <Icon className="w-4 h-4 mr-2" />
        {label}
      </p>
      <p className="text-gray-800 font-bold text-sm sm:text-base">
        {value}
      </p>
    </div>
  );

  const Section = ({ title, children }) => (
    <div className="bg-white p-6 space-y-4 shadow rounded-lg border border-gray-100">
      <h2 className="text-xl font-bold text-gray-800 flex items-center">
        {title.icon && <title.icon className="w-6 h-6 mr-2 text-orange-500" />}
        {title.text}
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {children}
      </div>
    </div>
  );

  const PaymentMethodCell = (paymentMethod) => {
    const getIconAndText = () => {
      switch (paymentMethod) {
        case 'CASH':
          return { 
            Icon: CoinsIcon, 
            text: 'Espèces' 
          };
          case 'cash':
            return { 
              Icon: CoinsIcon, 
              text: 'Espèces' 
            };
        case 'PAYPAL':
          return { 
            Icon: (props) => (
              <svg {...props} viewBox="0 0 24 24" fill="currentColor">
                <path d="M7.076 21.337H2.47a.641.641 0 0 1-.633-.74L4.944.901C5.026.382 5.474 0 5.998 0h7.46c2.57 0 4.578.543 5.69 1.81 1.01 1.15 1.304 2.42 1.012 4.287-.023.143-.047.288-.077.437-.983 5.05-4.349 6.797-8.647 6.797h-2.19c-.524 0-.968.382-1.05.9l-1.12 7.106zm14.146-14.42a3.35 3.35 0 0 0-.607-.541c-.013.076-.026.175-.041.254-.59 3.025-2.596 4.692-6.001 4.692h-2.19a.563.563 0 0 0-.556.476l-1.187 7.517h4.282c.47 0 .876-.339.947-.806l.039-.23.74-4.693.047-.245c.072-.467.478-.806.948-.806h.597c3.863 0 6.889-1.57 7.772-6.11.37-1.904.18-3.501-.8-4.508z"/>
              </svg>
            ), 
            text: 'PayPal' 
          };
          case 'paypal':
            return { 
              Icon: (props) => (
                <svg {...props} viewBox="0 0 24 24" fill="currentColor">
                  <path d="M7.076 21.337H2.47a.641.641 0 0 1-.633-.74L4.944.901C5.026.382 5.474 0 5.998 0h7.46c2.57 0 4.578.543 5.69 1.81 1.01 1.15 1.304 2.42 1.012 4.287-.023.143-.047.288-.077.437-.983 5.05-4.349 6.797-8.647 6.797h-2.19c-.524 0-.968.382-1.05.9l-1.12 7.106zm14.146-14.42a3.35 3.35 0 0 0-.607-.541c-.013.076-.026.175-.041.254-.59 3.025-2.596 4.692-6.001 4.692h-2.19a.563.563 0 0 0-.556.476l-1.187 7.517h4.282c.47 0 .876-.339.947-.806l.039-.23.74-4.693.047-.245c.072-.467.478-.806.948-.806h.597c3.863 0 6.889-1.57 7.772-6.11.37-1.904.18-3.501-.8-4.508z"/>
                </svg>
              ), 
              text: 'PayPal' 
            };
        case 'GATEWAY':
          return { 
            Icon: CreditCardIcon, 
            text: 'Gateway' 
          };
          case 'gateway':
            return { 
              Icon: CreditCardIcon, 
              text: 'Gateway' 
            };
        default:
          return { 
            Icon: null, 
            text: paymentMethod 
          };
      }
    };
  
    const { Icon, text } = getIconAndText();
  
    return (
      <td className="py-3 px-2 text-sm text-left">
        <div className="flex items-center">
          {Icon && <Icon className="h-5 w-5 mr-2 text-orange-600" />}
          <span>{text}</span>
        </div>
      </td>
    );
  };

  return (
    <div className="space-y-6">
      <Section title={{ icon: CreditCardIcon, text: "Détails du Paiement" }}>
        <InfoItem icon={IdentificationIcon} label="ID de Transaction" value={payment?.payment_method === "CASH" || "cash" ? "Pas d'identifiant" : payment?.payment_transaction_id} />
        <InfoItem icon={CurrencyEuroIcon} label="Montant" value={`${payment?.amount} Dhs`} />
        <InfoItem icon={CreditCardIcon} label="Méthode de Paiement" value={PaymentMethodCell(payment?.payment_method)} />
        <InfoItem 
          icon={CheckCircleIcon} 
          label="Statut du Paiement" 
          value={payment?.payment_method === "CASH" ? "En Attente" : "Payé"}
        />
        <InfoItem icon={CalendarIcon} label="Date du Paiement" value={payment?.created_at && formatDate2(payment?.created_at)} />
      </Section>

      <Section title={{ icon: CalendarIcon, text: "Détails de la Réservation" }}>
        <InfoItem 
          icon={ClockIcon} 
          label="Statut de la Réservation" 
          value={payment?.booking?.availability?.date && getBookingStatus(payment?.booking?.availability?.date, payment?.booking?.availability?.start_time, payment?.booking?.availability?.end_time, payment?.booking?.status)?.type}
        />
        <InfoItem 
          icon={CalendarIcon} 
          label="Date de la Réservation" 
          value={payment?.booking?.availability && FormateDateRange(payment?.booking?.availability?.date, payment?.booking?.availability?.start_time, payment?.booking?.availability?.end_time)}
        />
        <InfoItem icon={BuildingOfficeIcon} label="Salon" value={payment?.booking?.salon?.name} />
        <InfoItem icon={ScissorsIcon} label="Services" value={payment?.booking?.services?.map((service) => service.name).join(", ")} />
      </Section>

      <Section title={{ icon: UserIcon, text: "Détails du Client" }}>
        <div className="col-span-full flex items-center gap-4 mb-4">
          <div className="w-16 h-16 bg-gray-200 rounded-full overflow-hidden">
            <img
              src={payment?.booking?.user?.avatar ? BASE_IMAGE_URL + payment?.booking?.user?.avatar : ProfileImg}
              alt="avatar"
              className="object-cover w-full h-full"
            />
          </div>
          <div>
            <p className="text-gray-500 text-sm">Client</p>
            <h4 className="text-gray-800 font-bold text-lg capitalize">
              {payment?.booking?.user?.first_name ? `${payment?.booking?.user?.first_name} ${payment?.booking?.user?.last_name}` : payment?.booking?.guest?.name}
            </h4>
          </div>
        </div>
        <InfoItem 
          icon={EnvelopeIcon} 
          label="Email" 
          value={payment?.booking?.user?.email || payment?.booking?.guest?.email} 
        />
        <InfoItem 
          icon={PhoneIcon} 
          label="Téléphone" 
          value={payment?.booking?.user?.phone || payment?.booking?.guest?.phone} 
        />
        <InfoItem 
          icon={UserCircleIcon} 
          label="Genre" 
          value={(payment?.booking?.user?.gender ? payment?.booking?.user?.gender : (payment?.booking?.guest?.gender ? (payment?.booking?.guest?.gender) : "Non spécifié"))} 
        />
      </Section>
    </div>
  );
};

export default PaiementsDetailsComponent;