import React, { useEffect, useState } from "react";
import { apiClient } from "../../actions/api";
import { useDispatch } from "react-redux";
import { FetchData } from "../../actions/data";
import { SUGGESTIONS } from "../../constants/actions";
import { toast } from "react-toastify";
import { LoadingIcon } from "../../assets/images";
import {
  TrashIcon,
  PencilIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";

const itemsPerPage = 10;

const Suggestions = ({ suggestions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editedText, setEditedText] = useState("");
  const [newComplaint, setNewComplaint] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const totalPages = Math.ceil(suggestions?.length / itemsPerPage);
  const maxVisibleButtons = 6;

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [suggestions]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleComplaintes = suggestions?.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handleDelete = async (suggestion) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette suggestion ?")) {
      try {
        await apiClient().delete(`/suggestions/${suggestion?.id}`);
        toast.success("Suggestion supprimée avec succès");
        dispatch(FetchData("/suggestions", SUGGESTIONS));
      } catch (err) {
        console.error(err);
        toast.error("Échec de la suppression de la suggestion");
      }
    }
  };

  const handleUpdate = (suggestion) => {
    setEditingId(suggestion?.id);
    setEditedText(suggestion?.suggestion);
  };

  const handleSubmit = async (e, suggestion) => {
    e.preventDefault();
    if (editedText !== suggestion?.suggestion) {
      try {
        await apiClient().put(`/suggestions/${suggestion?.id}`, {
          suggestion: editedText,
        });
        setEditingId(null);
        toast.success("Suggestion mise à jour avec succès");
        dispatch(FetchData("/suggestions", SUGGESTIONS));
      } catch (err) {
        console.error(err);
        toast.error("Échec de la mise à jour de la suggestion");
      }
    }
  };

  const handleBlur = (e, suggestion) => {
    handleSubmit(e, suggestion);
    setEditingId(null);
  };

  const AddComplaintHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await apiClient().post(`/suggestions`, { suggestion: newComplaint });
      toast.success("Suggestion ajoutée avec succès");
      dispatch(FetchData("/suggestions", SUGGESTIONS));
      setLoading(false);
      setNewComplaint("");
    } catch (err) {
      console.error(err);
      toast.error("Échec de l'ajout de la suggestion");
      setLoading(false);
    }
  };

  return (
    <div className="max-w-5xl mx-auto px-1 py-4 space-y-4">
      <form onSubmit={AddComplaintHandler} className="flex items-center gap-2">
        <input
          type="text"
          placeholder="Ajouter une suggestion"
          value={newComplaint}
          onChange={(e) => setNewComplaint(e.target.value)}
          className="w-full p-2 border rounded-md outline-none focus:ring-2 focus:ring-orange-500"
          required
        />
        <button
          disabled={loading}
          type="submit"
          className="bg-orange-500 px-4 p-2 rounded-md text-white flex items-center justify-center hover:bg-orange-600 disabled:bg-gray-400"
        >
          {loading ? (
            <img src={LoadingIcon} alt="Chargement" className="h-5" />
          ) : (
            <>
              <PlusIcon className="w-5 h-5 mr-1" />
              Ajouter
            </>
          )}
        </button>
      </form>

      <div className="overflow-x-auto text-sm">
        <div className="flex items-center border-b">
          <div className="w-10/12 py-2 px-1">Suggestion</div>
          <div className="w-2/12 py-2 px-1">Action</div>
        </div>

        {visibleComplaintes?.map((sugg) => (
          <div key={sugg?.id} className="flex items-center border-b">
            {editingId === sugg.id ? (
              <form
                className="w-10/12 py-2 px-1"
                onSubmit={(e) => handleSubmit(e, sugg)}
              >
                <input
                  type="text"
                  value={editedText}
                  onChange={(e) => setEditedText(e.target.value)}
                  onBlur={(e) => handleBlur(e, sugg)}
                  className="w-full p-1 border rounded"
                  autoFocus
                />
              </form>
            ) : (
              <div className="w-10/12 py-2 px-1">{sugg?.suggestion}</div>
            )}

            <div className="w-2/12 flex items-center justify-center gap-2 py-2 px-1">
              <button
                onClick={() => handleDelete(sugg)}
                className="text-red-500 hover:text-red-700"
              >
                <TrashIcon className="w-5 h-5" />
              </button>
              <button
                onClick={() => handleUpdate(sugg)}
                className="text-blue-500 hover:text-blue-700"
              >
                <PencilIcon className="w-5 h-5" />
              </button>
            </div>
          </div>
        ))}
      </div>

      {suggestions?.length > itemsPerPage && (
        <div className="flex flex-col sm:flex-row items-center justify-between text-xs sm:text-sm py-4">
          <p className="font-medium text-gray-600 mb-2 sm:mb-0">
            Affichage des données {startIndex + 1} à{" "}
            {Math.min(startIndex + itemsPerPage, suggestions?.length)} sur{" "}
            {suggestions?.length} entrées
          </p>

          <nav className="flex items-center space-x-1">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="w-8 h-8 text-gray-500 bg-gray-200 rounded-full hover:bg-gray-300 disabled:opacity-50"
            >
              <ChevronLeftIcon className="w-5 h-5" />
            </button>

            {visiblePages?.map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`w-8 h-8 rounded-full ${
                  currentPage === page
                    ? "bg-orange-500 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}
              >
                {page}
              </button>
            ))}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="w-8 h-8 text-gray-500 bg-gray-200 rounded-full hover:bg-gray-300 disabled:opacity-50"
            >
              <ChevronRightIcon className="w-5 h-5" />
            </button>
          </nav>
        </div>
      )}

      {suggestions?.length === 0 && (
        <div className="text-gray-500 text-center">Aucune suggestion</div>
      )}
    </div>
  );
};

export default Suggestions;