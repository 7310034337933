import { useEffect, useState } from "react";
import { DemandeListe, Layout } from "../../components";
import { useSelector } from "react-redux";
import SortByBtn from "../../components/globalcomponents/SortByBtn";

const sortOptions = ["Date Création", "Nom"];

const Demmandes = () => {
  const demandes = useSelector((state) => state?.data?.demandes);
  const [filtredDemandes, setFiltredDemandes] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortOption, setSortOption] = useState("Date Création");

  useEffect(() => {
    if (!Array.isArray(demandes)) return;
    if (sortOption === "Date Création") {
      const sortedByCreatedDate = [...demandes]?.sort(
        (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
      );
      setFiltredDemandes(sortedByCreatedDate);
    } else if (sortOption === "Nom") {
      const sortedByName = [...demandes]?.sort((a, b) => {
        const firstNameComparison = a?.first_name.localeCompare(b?.first_name);

        if (firstNameComparison === 0) {
          return a?.last_name.localeCompare(b?.last_name);
        }
        return firstNameComparison;
      });
      setFiltredDemandes(sortedByName);
    } else {
      setFiltredDemandes(demandes);
    }
  }, [sortOption, demandes]);

  useEffect(() => {
    if (!Array.isArray(demandes)) return;
    if (!searchInput) {
      setFiltredDemandes(demandes);
      return;
    }

    const searchLowerCase = searchInput.trim().toLowerCase();

    const filtered = demandes?.filter((user) => {
      const fullName =
        `${user?.first_name} ${user?.last_name}`.toLowerCase();
      const reversedFullName =
        `${user?.last_name} ${user?.first_name}`.toLowerCase();
      const firstName = user?.first_name.toLowerCase();
      const lastName = user?.last_name.toLowerCase();
      const email = user?.email.toLowerCase();

      return (
        firstName?.includes(searchLowerCase) ||
        lastName?.includes(searchLowerCase) ||
        fullName?.includes(searchLowerCase) ||
        reversedFullName?.includes(searchLowerCase) ||
        email?.includes(searchLowerCase)
      );
    });

    setFiltredDemandes(filtered);
  }, [searchInput, demandes]);

  return (
    <Layout>
      <div className="p-1 min-h-screen">
        <div className="flex items-center justify-between flex-wrap md:flex-nowrap gap-2 p-4 mt-5 text-xs sm:text-sm">
          <div className="w-full md:w-1/3">
            <input
              type="text"
              name="nom"
              id="nom"
              placeholder="Nom, Prénom"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              className="w-full py-2 px-3 border rounded-full outline-none focus:outline-none"
            />
          </div>
          <div className="flex items-center justify-end gap-x-2 w-full lg:w-fit h-8 sm:h-9 md:h-10 max-h-10">
            <div className="min-w-32 h-full">
              <SortByBtn
                sortOptions={sortOptions}
                sortOption={sortOption}
                setSortOption={setSortOption}
              />
            </div>
          </div>
        </div>
        <DemandeListe
          demandes={filtredDemandes}
          itemsPerPage={10}
        />
      </div>
    </Layout>
  );
};

export default Demmandes;
