const FormatChatDate = (date) => {
  const chatDate = new Date(date);
  const today = new Date();

  if (chatDate.toDateString() === today.toDateString()) {
    return chatDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    return chatDate.toLocaleString();
  }
};

export default FormatChatDate;
