import { Especes, LoadingIcon } from "../../../assets/images";
import { BASE_IMAGE_URL } from "../../../constants/actions";
import formatDate2 from "../../../helpers/formatDate2";
import FormateDateRange from "../../../helpers/FormateDateRange";
import { UserIcon } from "@heroicons/react/24/solid"; // Import UserIcon from Heroicons
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { CoinsIcon } from "lucide-react";

const ReservationDetails = ({ reservation, handlePayer, loading }) => {

  const PaymentMethodCell = (paymentMethod) => {
    const getIconAndText = () => {
      switch (paymentMethod) {
        case 'CASH':
          return { 
            Icon: CoinsIcon, 
            text: 'Espèces' 
          };
          case 'cash':
            return { 
              Icon: CoinsIcon, 
              text: 'Espèces' 
            };
        case 'PAYPAL':
          return { 
            Icon: (props) => (
              <svg {...props} viewBox="0 0 24 24" fill="currentColor">
                <path d="M7.076 21.337H2.47a.641.641 0 0 1-.633-.74L4.944.901C5.026.382 5.474 0 5.998 0h7.46c2.57 0 4.578.543 5.69 1.81 1.01 1.15 1.304 2.42 1.012 4.287-.023.143-.047.288-.077.437-.983 5.05-4.349 6.797-8.647 6.797h-2.19c-.524 0-.968.382-1.05.9l-1.12 7.106zm14.146-14.42a3.35 3.35 0 0 0-.607-.541c-.013.076-.026.175-.041.254-.59 3.025-2.596 4.692-6.001 4.692h-2.19a.563.563 0 0 0-.556.476l-1.187 7.517h4.282c.47 0 .876-.339.947-.806l.039-.23.74-4.693.047-.245c.072-.467.478-.806.948-.806h.597c3.863 0 6.889-1.57 7.772-6.11.37-1.904.18-3.501-.8-4.508z"/>
              </svg>
            ), 
            text: 'PayPal' 
          };
          case 'paypal':
            return { 
              Icon: (props) => (
                <svg {...props} viewBox="0 0 24 24" fill="currentColor">
                  <path d="M7.076 21.337H2.47a.641.641 0 0 1-.633-.74L4.944.901C5.026.382 5.474 0 5.998 0h7.46c2.57 0 4.578.543 5.69 1.81 1.01 1.15 1.304 2.42 1.012 4.287-.023.143-.047.288-.077.437-.983 5.05-4.349 6.797-8.647 6.797h-2.19c-.524 0-.968.382-1.05.9l-1.12 7.106zm14.146-14.42a3.35 3.35 0 0 0-.607-.541c-.013.076-.026.175-.041.254-.59 3.025-2.596 4.692-6.001 4.692h-2.19a.563.563 0 0 0-.556.476l-1.187 7.517h4.282c.47 0 .876-.339.947-.806l.039-.23.74-4.693.047-.245c.072-.467.478-.806.948-.806h.597c3.863 0 6.889-1.57 7.772-6.11.37-1.904.18-3.501-.8-4.508z"/>
                </svg>
              ), 
              text: 'PayPal' 
            };
        case 'GATEWAY':
          return { 
            Icon: CreditCardIcon, 
            text: 'Gateway' 
          };
          case 'gateway':
            return { 
              Icon: CreditCardIcon, 
              text: 'Gateway' 
            };
        default:
          return { 
            Icon: null, 
            text: paymentMethod 
          };
      }
    };
  
    const { Icon, text } = getIconAndText();
  
    return (
      <td className="text-sm text-left">
        <div className="flex items-center">
          {Icon && <Icon className="h-5 w-5 mr-2 text-orange-600" />}
          <span>{text}</span>
        </div>
      </td>
    );
  };


  return (
    <div className="space-y-6">
      {/* Client Information */}
      <div className="p-6 bg-white shadow rounded-lg flex flex-col sm:flex-row gap-4 items-start sm:items-center">
        <div className="w-14 h-14 rounded-full bg-gray-200 overflow-hidden flex items-center justify-center">
          {reservation?.user?.avatar ? (
            <img
              src={BASE_IMAGE_URL + reservation?.user?.avatar}
              alt="avatar"
              className="object-cover w-full h-full"
            />
          ) : (
            <UserIcon className="mt-6 h-14 w-14 text-gray-400" /> // Fallback to UserIcon
          )}
        </div>
        <div className="flex flex-wrap w-full gap-6 sm:justify-between">
          <div className="space-y-2">
            <p className="text-gray-pt text-xs">Client</p>
            <h4 className="text-gray-gt font-bold text-sm sm:text-base capitalize">
              {reservation?.user?.first_name
                ? `${reservation?.user?.first_name} ${reservation?.user?.last_name}`
                : reservation?.guest?.name}
            </h4>
          </div>
          <div className="space-y-2">
            <p className="text-gray-pt text-xs">Salon</p>
            <h4 className="text-gray-gt font-bold text-sm sm:text-base capitalize">
              {reservation?.salon?.name || "N/A"}
            </h4>
          </div>
          <div className="space-y-2">
            <p className="text-gray-pt text-xs">Email</p>
            <h4 className="text-gray-gt font-bold text-sm sm:text-base">
              {reservation?.user?.email || reservation?.guest?.email}
            </h4>
          </div>
          <div className="space-y-2">
            <p className="text-gray-pt text-xs">Téléphone</p>
            <h4 className="text-gray-gt font-bold text-sm sm:text-base">
              {reservation?.user?.phone || reservation?.guest?.phone}
            </h4>
          </div>
        </div>
      </div>

      {/* Payment & Appointment Details */}
      <div className="p-6 bg-white shadow rounded-lg grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <p className="text-gray-pt text-xs">Date de rendez-vous</p>
          <h4 className="text-gray-gt font-bold text-sm sm:text-base">
            {reservation?.availability?.date &&
              FormateDateRange(
                reservation?.availability?.date,
                reservation?.availability?.start_time,
                reservation?.availability?.end_time
              )}
          </h4>
        </div>
        <div>
          <p className="text-gray-pt text-xs">Réservé le</p>
          <h4 className="text-gray-gt font-bold text-sm sm:text-base">
            {reservation?.created_at && formatDate2(reservation?.created_at)}
          </h4>
        </div>
        <div>
          <p className="text-gray-pt text-xs">Prix Total</p>
          <h4 className="text-gray-gt font-bold text-sm sm:text-base">
            {reservation?.payment?.amount} Dhs
          </h4>
        </div>
        <div>
          <p className="text-gray-pt text-xs">Mode Paiement</p>
          <h4 className="text-gray-gt font-bold text-sm sm:text-base uppercase">
            {PaymentMethodCell(reservation?.payment?.payment_method)}
          </h4>
        </div>
      </div>

      {/* Pay Button for Cash Payments */}
      {reservation?.payment?.payment_method.toLowerCase() === "cash" &&
        reservation?.payment?.payment_status === 0 && (
          <div className="flex justify-end mr-4">
            <button
              onClick={() => handlePayer(reservation?.payment?.id)}
              disabled={loading}
              className={`group flex items-center space-x-2 px-4 py-2 ${
                loading ? "bg-gray-200 text-gray-500 cursor-not-allowed" : "bg-white text-green-600 hover:bg-green-50"
              } border border-green-300 rounded-full font-semibold shadow-sm transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50`}
              >
              {loading ? (
                <img src={LoadingIcon} alt="loading" className="h-6" />
              ) : (
                <div className="flex gap-4">
                  <img src={Especes} alt="especes" className="h-6" /> 
                  Confirmer le paiement en espèces
                </div>
              )}
            </button>
          </div>
        )}

      {/* Services */}
      <div className="p-6 bg-white shadow rounded-lg">
        <h3 className="text-lg font-bold text-gray-gt">Services</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
          {reservation?.services?.map((service, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300 overflow-hidden"
            >
              <div className="bg-lightorange text-white text-center py-3 font-semibold text-lg">
                {service?.name}
              </div>
              <div className="text-center py-3 border-t border-gray-300">
                <span className="text-gray-700 font-medium">
                  {service?.pivot?.price} Dhs
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Notes Section */}
      {reservation?.note && (
        <div className="p-6 bg-white shadow rounded-lg">
          <h3 className="text-lg font-bold text-gray-gt">Notes</h3>
          <p className="mt-4 text-gray-700">{reservation?.note}</p>
        </div>
      )}

      {/* Employee Information */}
      <div className="p-6 bg-white shadow rounded-lg flex items-center gap-4">
        <div className="w-14 h-14 rounded-full bg-gray-200 overflow-hidden flex items-center justify-center">
          {reservation?.employee?.avatar ? (
            <img
              src={BASE_IMAGE_URL + reservation?.employee?.avatar}
              alt="avatar"
              className="object-cover w-full h-full"
            />
          ) : (
            <UserIcon className="mt-6 h-14 w-14 text-gray-400" /> // Fallback to UserIcon
          )}
        </div>
        <div className="space-y-2">
          <p className="text-gray-pt text-xs">Employé</p>
          <h4 className="text-gray-gt font-bold text-sm sm:text-base capitalize">
            {reservation?.availability?.employee?.first_name}{" "}
            {reservation?.availability?.employee?.last_name}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ReservationDetails;
