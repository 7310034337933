import { useEffect, useState } from "react";
import formatDate2 from "../../helpers/formatDate2";
import { BASE_IMAGE_URL } from "../../constants/actions";
import { ProfileImg } from "../../assets/images";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  CalendarIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/solid";
const itemsPerPage = 7;

const ComplaintesListe = ({ complaints, searchInput, setSearchInput }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);

  // Toggle dropdown with better performance
  const handleToggleDropdown = (id) => {
    setOpenDropdown((prev) => (prev === id ? null : id));
  };

  const totalPages = Math.ceil(complaints?.length / itemsPerPage);
  const maxVisibleButtons = 6;

  // Update visible pagination pages
  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);
    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }
    const pages = Array.from({ length: end - start + 1 }, (_, i) => start + i);
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  // Update pages on complaints change
  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [complaints]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleComplaintes = complaints?.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <div className="max-w-5xl mx-auto space-y-4">
      {/* Complaints List */}
      {visibleComplaintes?.map((complaint) => (
        <div
          key={complaint?.id}
          className="border rounded-lg bg-white shadow-sm transition-all duration-200"
        >
          <div
            className="flex items-center gap-4 p-3 sm:p-4 cursor-pointer flex-col sm:flex-row"
            onClick={() => handleToggleDropdown(complaint?.id)}
            aria-expanded={openDropdown === complaint?.id}
            role="button"
            tabIndex="0"
            onKeyPress={() => handleToggleDropdown(complaint?.id)}
          >
            <div className="w-12 h-12 overflow-hidden rounded-full border-2 border-orange-200">
              <img
                src={
                  complaint?.user?.avatar
                    ? BASE_IMAGE_URL + complaint?.user?.avatar
                    : ProfileImg
                }
                alt={`${complaint?.user?.first_name} avatar`}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="w-full flex justify-between items-center sm:flex-row flex-col gap-2 sm:gap-0">
              <div className="flex-grow text-center sm:text-left">
                <h3 className="text-base sm:text-md font-semibold text-gray-800">
                  {complaint?.user?.first_name} {complaint?.user?.last_name}
                </h3>
                <div className="flex items-center justify-center sm:justify-start text-xs sm:text-xs text-gray-500">
                  <EnvelopeIcon className="w-4 h-4 mr-1" />
                  {complaint?.user?.email}
                </div>
              </div>
              <div className="text-sm text-gray-500 flex items-center justify-center sm:justify-start">
                <CalendarIcon className="w-4 h-4 mr-1" />
                {complaint?.created_at && formatDate2(complaint?.created_at)}
              </div>
            </div>
            <div>
            {openDropdown === complaint?.id ? (
                <ChevronUpIcon className="w-6 h-6 text-gray-500" />
              ) : (
                <ChevronDownIcon className="w-6 h-6 text-gray-500" />
              )}
            </div>
          </div>

          {/* Dropdown Content */}
          {openDropdown === complaint?.id && (
            <div className="p-4 bg-gray-50">
              <div>
                {complaint?.suggestion?.suggestion && (
                  <h4 className="text-orange-700 font-semibold mt-2">
                    {complaint?.suggestion?.suggestion}
                  </h4>
                )}
                <p className="mt-2 text-sm">{complaint?.description}</p>
              </div>
            </div>
          )}
        </div>
      ))}

      {/* Pagination */}
      {complaints?.length > itemsPerPage && (
        <div className="flex items-center justify-center py-4 text-sm">
          <nav className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-2 py-1 bg-gray-200 rounded-md disabled:opacity-50"
              aria-label="Previous Page"
            >
              <i className="bi bi-chevron-left" />
            </button>
            {visiblePages?.map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`px-3 py-1 rounded-md ${
                  currentPage === page
                    ? "bg-orange-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                aria-current={currentPage === page}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-2 py-1 bg-gray-200 rounded-md disabled:opacity-50"
              aria-label="Next Page"
            >
              <i className="bi bi-chevron-right" />
            </button>
          </nav>
        </div>
      )}

      {/* No Complaints Message */}
      {complaints?.length === 0 && (
        <div className="text-center text-gray-500">Aucune Remarque</div>
      )}
    </div>
  );
};

export default ComplaintesListe;