import { useSelector } from "react-redux";
import { ComeBackButton, ParametresLayout, SalonProfile } from "../../components";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";

const SalonDetails = () => {
  const { id } = useParams();
  const salons = useSelector((state) => state?.data?.salons) || [];
  const [salon, setSalon] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isNaN(id)) {
      navigate("/parametres/salons");
      return;
    }
    const sal = salons?.find((salon) => salon?.id === parseInt(id));
    if (salons?.length > 0 && !sal) {
      navigate("/parametres/salons");
      return;
    }
    setSalon(sal);
  }, [id, salons]);

  return (
    <ParametresLayout>
      <div className="p-1">
        <ComeBackButton prev={location?.state?.prevPath} next={"/parametres/salons"} />
        <SalonProfile salon={salon} />
      </div>
    </ParametresLayout>
  );
};

export default SalonDetails;
