import React from "react";
import { ProfileImg } from "../../../assets/images";
import { useLocation, useNavigate } from "react-router";
import formatDate2 from "../../../helpers/formatDate2";
import { BASE_IMAGE_URL } from "../../../constants/actions";
import { CalendarIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { CoinsIcon } from "lucide-react";

const RendezVousDashboard = ({ rendezvous, isLoading }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const LoadingState = () => (
    <>
      <div className="flex items-center gap-x-3 animate-pulse">
        <div className="w-9 h-9 min-w-9 min-h-9 rounded-full bg-gray-100" />
        <div className="flex flex-col gap-y-1">
          <div className="w-24 h-4 bg-gray-100 rounded" />
          <div className="w-16 h-3 bg-gray-100 rounded" />
        </div>
      </div>

      <div className="text-sm">
        <p className="text-gray-500">Date</p>
        <div className="w-32 h-4 bg-gray-100 rounded mt-1 animate-pulse" />
      </div>

      <div className="text-sm">
        <p className="text-gray-500">Service</p>
        <div className="w-40 h-4 bg-gray-100 rounded mt-1 animate-pulse" />
      </div>

      <div className="flex items-center justify-between text-sm">
        <div className="">
          <p className="text-gray-500">Prix</p>
          <div className="w-20 h-4 bg-gray-100 rounded mt-1 animate-pulse" />
        </div>
        <div className="">
          <div className="w-24 h-8 bg-gray-100 rounded-full animate-pulse" />
        </div>
      </div>
    </>
  );

  const NoAppointmentState = () => (
    <div className="flex flex-col items-center justify-center h-full">
      <p className="text-white text-lg font-bold text-center">Aucun rendez-vous prévu</p>
    </div>
  );

  return (
    <div className="rounded-lg bg-orange-primary min-w-fit sm:min-w-80 md:min-w-fit p-6 space-y-6 font-inter flex flex-col justify-between">
      <div className="flex flex-row items-center justify-between">
        <h2 className="text-white font-bold text-lg">Prochain rendez-vous</h2>
        <div className="w-3 h-3 min-w-3 min-h-3 rounded-full bg-white"></div>
      </div>
      {isLoading ? (
        <LoadingState />
      ) : rendezvous ? (
        <>
          <div className="flex items-center gap-x-3">
            <div className="w-11 h-11 min-w-11 min-h-11 rounded-full overflow-hidden">
              <img
                src={rendezvous?.user?.avatar ? BASE_IMAGE_URL + rendezvous?.user?.avatar : ProfileImg}
                alt="avatar"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="text-sm">
              <h3 className="text-white font-bold">
                {rendezvous?.user?.first_name ? `${rendezvous?.user?.first_name} ${rendezvous?.user?.last_name}` : rendezvous?.guest?.name}
              </h3>
              <p className="text-white font-medium text-xs">{rendezvous?.user?.email || rendezvous?.guest?.email}</p>
            </div>
          </div>

          <div className="text-sm">
            <p className="flex items-center">
              <CalendarIcon className="w-5 h-5 mr-1 text-[#393720]" />
              <span className="font-bold text-[#393720] text-base">Date</span>
            </p>
            <time className="text-white font-bold">
              {rendezvous?.availability?.date && formatDate2(
                `${rendezvous?.availability?.date} ${rendezvous?.availability?.start_time}`,
                "fr-FR"
              )}
            </time>
          </div>

          <div className="text-sm">
            <p className="flex items-center">
              <InformationCircleIcon className="w-5 h-5 mr-1 text-[#393720]" />
              <span className="font-bold text-[#393720] text-base">Services</span>
            </p>
            <p className="text-white font-bold">
              {rendezvous?.services?.map((service) => service.name).join(", ")}
            </p>
          </div>

          <div className="flex items-center justify-between text-sm">
            <div className="">
              <p className="flex items-center">
                <CoinsIcon className="w-5 h-5 mr-1 text-[#393720]" />
                <span className="font-bold text-[#393720] text-base">Prix</span>
              </p>
              <p className="text-white font-bold">
                {rendezvous?.payment?.amount} Dhs
              </p>
            </div>
            <div className="">
              <button
                onClick={() =>
                  navigate(`/reservations/${rendezvous?.id}`, {
                    state: { prevPath: location?.pathname },
                  })
                }
                className="bg-white py-2 px-4 rounded-full font-medium"
              >
                Voir Détails
              </button>
            </div>
          </div>
        </>
      ) : (
        <NoAppointmentState />
      )}
    </div>
  );
};

export default RendezVousDashboard;