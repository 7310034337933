import React, { useEffect, useState, useCallback } from "react";
import FilterBtn from "../../components/globalcomponents/FilterBtn";
import SortByBtn from "../../components/globalcomponents/SortByBtn";
import { useNavigate } from "react-router";
import { ParametresLayout } from "../../components";
import { useSelector } from "react-redux";
import { BASE_IMAGE_URL } from "../../constants/actions";
import { Filter } from "lucide-react";

const sortOptions = ["Date création", "Nom", "Ville"];
const filterOptions = ["Tout", "En Service", "Hors Service"];
const itemsPerPage = 10;

const Salons = () => {
  const salons = useSelector((state) => state?.data?.salons) || [];
  const [filteredSalons, setFilteredSalons] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortOption, setSortOption] = useState("Date création");
  const [filterOption, setFilterOption] = useState("Tout");
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const [isFilterSliderOpen, setIsFilterSliderOpen] = useState(false);

  const navigate = useNavigate();

  const sortSalons = useCallback((salonsToSort, option) => {
    return [...salonsToSort].sort((a, b) => {
      if (option === "Date création") {
        return new Date(b?.created_at) - new Date(a?.created_at);
      } else if (option === "Nom") {
        return a?.name.localeCompare(b?.name);
      } else if (option === "Ville") {
        return a?.city.localeCompare(b?.city);
      }
      return 0;
    });
  }, []);

  const filterSalons = useCallback((salonsToFilter, search, filter) => {
    let result = salonsToFilter;

    if (search) {
      const searchLowerCase = search.trim().toLowerCase();
      result = result.filter((salon) => {
        const name = salon?.name?.toLowerCase() || "";
        const email = salon?.email?.toLowerCase() || "";
        const ville = salon?.city?.toLowerCase() || "";
        const adresse = salon?.address?.toLowerCase() || "";
        return (
          name.includes(searchLowerCase) ||
          email.includes(searchLowerCase) ||
          ville.includes(searchLowerCase) ||
          adresse.includes(searchLowerCase)
        );
      });
    }

    if (filter === "En Service") {
      result = result.filter((salon) => salon?.active);
    } else if (filter === "Hors Service") {
      result = result.filter((salon) => !salon?.active);
    }

    return result;
  }, []);

  const updateSalonsList = useCallback(() => {
    let result = salons;
    result = filterSalons(result, searchInput, filterOption);
    result = sortSalons(result, sortOption);
    setFilteredSalons(result);
  }, [salons, searchInput, filterOption, sortOption, filterSalons, sortSalons]);

  useEffect(() => {
    updateSalonsList();
  }, [updateSalonsList]);

  const totalPages = Math.ceil(filteredSalons.length / itemsPerPage);
  const maxVisibleButtons = 6;

  const updateVisiblePages = useCallback(
    (page) => {
      let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
      let end = Math.min(totalPages, start + maxVisibleButtons - 1);

      if (end - start < maxVisibleButtons - 1) {
        start = Math.max(1, end - maxVisibleButtons + 1);
      }

      setVisiblePages(
        Array.from({ length: end - start + 1 }, (_, i) => start + i)
      );
    },
    [totalPages]
  );

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [filteredSalons, updateVisiblePages]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSortChange = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const handleFilterChange = (newFilterOption) => {
    setFilterOption(newFilterOption);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentSalons = filteredSalons.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const toggleFilterSlider = () => {
    setIsFilterSliderOpen((prev) => !prev);
  };

  return (
    <ParametresLayout>
      <div className="p-3 min-h-screen space-y-5">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="w-full md:w-1/3">
            <input
              type="text"
              name="nom"
              id="nom"
              placeholder="Nom, Email, ville, Adresse"
              value={searchInput}
              onChange={handleSearchChange}
              className="w-full py-2 px-3 border rounded-full outline-none focus:outline-none"
            />
          </div>
          <div className="md:hidden">
            <button
              onClick={toggleFilterSlider}
              className="w-full bg-orange-500 text-white py-2 px-4 rounded-full flex items-center justify-center"
            >
              <Filter size={18} className="mr-2" /> Filtres
            </button>
          </div>
          <div
            className={`flex flex-col md:flex-row gap-2 ${
              isFilterSliderOpen ? "block" : "hidden md:flex"
            }`}
          >
            <div className="relative">
              <SortByBtn
                sortOptions={sortOptions}
                sortOption={sortOption}
                setSortOption={handleSortChange}
              />
            </div>
            <div className="relative">
              <FilterBtn
                filterOptions={filterOptions}
                filterOption={filterOption}
                setFilterOption={handleFilterChange}
              />
            </div>
          </div>
        </div>

        <div className="bg-white text-sm shadow-md rounded-lg">
          <div className="overflow-x-auto">
            <table className="w-full table-auto border-collapse">
              <thead>
                <tr className="bg-gray-100 text-gray-800 text-left border-b text-xs sm:text-sm md:text-sm lg:text-md font-semibold">
                  <th className="py-3 px-4">Nom</th>
                  <th className="py-3 px-4">Téléphone</th>
                  <th className="py-3 px-4">Email</th>
                  <th className="py-3 px-4">Ville</th>
                  <th className="py-3 px-4">Adresse</th>
                  <th className="py-3 px-4 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentSalons.length ? (
                  currentSalons.map((salon) => (
                    <tr
                      key={salon?.id}
                      className="hover:bg-gray-50 text-gray-700 border-b text-xs sm:text-sm md:text-sm lg:text-md"
                    >
                      <td className="py-3 px-4 capitalize">{salon?.name}</td>
                      <td className="py-3 px-4">{salon?.phone_number}</td>
                      <td className="py-3 px-4">{salon?.email}</td>
                      <td className="py-3 px-4 capitalize">{salon?.city}</td>
                      <td className="py-3 px-4 capitalize">{salon?.address}</td>
                      <td className="py-3 px-4 text-center">
                        <button
                          onClick={() =>
                            navigate(`/parametres/salons/${salon?.id}`)
                          }
                          className="text-blue-500 hover:text-blue-700 text-lg sm:text-xl md:text-2xl transition-colors"
                        >
                          <i className="bi bi-pencil-square"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-gray-500 text-center py-4">
                      Aucun salon
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {filteredSalons.length > itemsPerPage && (
            <div className="flex items-center justify-between text-xs sm:text-sm md:text-base py-5 mt-4">
              <p className="font-medium text-gray-700">
                Affichage des données {startIndex + 1} à{" "}
                {Math.min(startIndex + itemsPerPage, filteredSalons.length)} sur{" "}
                {filteredSalons.length} entrées
              </p>

              <nav className="flex items-center space-x-1 md:space-x-2 font-medium">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300 text-gray-700 disabled:opacity-50"
                >
                  <i className="bi bi-chevron-left"></i>
                </button>
                {visiblePages.map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`w-8 h-8 flex items-center justify-center rounded-full ${
                      currentPage === page
                        ? "bg-orange-primary text-white"
                        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                    }`}
                  >
                    {page}
                  </button>
                ))}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300 text-gray-700 disabled:opacity-50"
                >
                  <i className="bi bi-chevron-right"></i>
                </button>
              </nav>
            </div>
          )}
        </div>
      </div>
    </ParametresLayout>
  );
};

export default Salons;
