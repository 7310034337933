import { DELETE_ALL_DATA, REMOVE_USER_INFOS, STROE_USER_INFOS } from "../constants/actions";
import { apiClient } from '../actions/api';
import { toast } from "react-toastify";

export const loginUser = (data, rememberMe = false) => async (dispatch) => {
  try {
    const response = await apiClient().post(`/login`, data);
    console.log(response);
    if(response?.data?.message){
      console.log(response?.data?.message);
      toast.warning(response?.data?.message, { autoClose: 800 });
    }else{
      dispatch({ type: STROE_USER_INFOS, payload: response?.data });
    }
  } catch (error) {
    console.error("Login Faild !", error);
  }
};

export const logoutUser = () => (dispatch) => {
  dispatch({ type: REMOVE_USER_INFOS });
  dispatch({ type: DELETE_ALL_DATA });
};
