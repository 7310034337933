import { useState } from "react";
import { BgLogin, SkippSvg1 } from "../../assets/images";
import { useDispatch } from "react-redux";
import { loginUser } from "../../actions/auth";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const [formData, setUser] = useState({ email: "", password: "" });
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...formData, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch(loginUser(formData));
  };

  return (
    <section style={{ background: `#fffff`, backgroundPosition: 'center', backgroundSize: 'cover' }} className="font-nunito min-h-screen flex flex-col justify-between">
      <div className="flex-grow flex flex-col items-center justify-center px-6 py-8">
        <div to="/" className="flex items-center mb-5 text-2xl font-semibold">
          <img className="w-32" src={SkippSvg1} alt="logo" />
        </div>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
          <form className="space-y-4 md:space-y-6 p-6" onSubmit={handleLogin}>
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData?.email}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="...@..."
                required
              />
            </div>
            <div>
              <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Mot de passe</label>
              <input
                type="password"
                name="password"
                id="password"
                value={formData?.password}
                onChange={handleChange}
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full text-white bg-orange-primary hover:bg-orange-400 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Se Connecter
            </button>
          </form>
        </div>
      </div>

      <footer className="bg-white-900 text-black py-4 w-full">
  <div className="text-center text-sm font-nunito font-semibold">
    &copy; {new Date().getFullYear()} Skipp - Tous droits réservés.
  </div>
</footer>


      <ToastContainer />
    </section>
  );
};

export default Login;
