import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../../components";
import { BgClientDetails, ProfileImg } from "../../assets/images";
import { BASE_IMAGE_URL, STROE_USER_INFOS } from "../../constants/actions";
import { useDispatch, useSelector } from "react-redux";
import { apiClient } from "../../actions/api";
import uploadImage from "../../helpers/uploadImages";
import { toast, ToastContainer } from "react-toastify";
import { CheckCircleIcon, PencilIcon, XMarkIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { Loader } from "lucide-react";

const Profile = () => {
  const user = useSelector((state) => state?.auth?.user);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState(user || {});
  const [selectedImage, setSelectedImage] = useState(
    user?.avatar ? BASE_IMAGE_URL + user?.avatar : ProfileImg
  );
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  useEffect(() => {
    setFormData(user || {});
    if (user?.avatar) {
      setSelectedImage(BASE_IMAGE_URL + user?.avatar);
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      toast.error("Les mots de passe ne correspondent pas.");
      return;
    }

    setLoading(true); // Start loading

    try {
      let avatarPath = user?.avatar;
      if (formData?.avatar && formData?.avatar !== user?.avatar) {
        avatarPath = await uploadImage(
          "avatar",
          { avatar: formData?.avatar },
          { avatar: user?.avatar },
          "avatars"
        );
      }
      const updatedData = { ...formData, avatar: avatarPath };
      if (password) {
        updatedData.password = password;
      }
      const response = await apiClient().put(`/users/${user?.id}`, updatedData);

      if (response?.status === 200) {
        toast.success("Les informations du profil ont été modifiées avec succès.");
        dispatch({ type: STROE_USER_INFOS, payload: response?.data });
        setEditMode(false);
      }
    } catch (error) {
      toast.error("Échec de la modification des informations du profil.");
      console.error(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setFormData((prev) => ({ ...prev, avatar: file }));
    }
  };

  const toggleEdit = () => {
    setEditMode(!editMode);
    setFormData(user || {});
    setSelectedImage(user?.avatar ? BASE_IMAGE_URL + user?.avatar : ProfileImg);
    if (fileInputRef.current) fileInputRef.current.value = "";
    setPassword("");
    setPasswordConfirm("");
  };


  return (
    <Layout>
      <ToastContainer />
      <div className="p-4">
        <div className="space-y-8">
          <div className="relative w-full h-36 rounded-lg overflow-hidden">
            <div className="w-full h-full bg-gradient-to-r from-orange-500 via-yellow-500 to-orange-500 object-cover" />
            <div className="absolute top-3 left-3">
              <div className="relative w-24 h-24 rounded-full bg-white">
                <input
                  type="file"
                  id="avatarUpload"
                  ref={fileInputRef}
                  onChange={handleAvatarChange}
                  className="sr-only"
                  disabled={!editMode}
                />
                <label htmlFor="avatarUpload">
                  <img
                    src={selectedImage}
                    alt="avatar"
                    className={`object-cover w-full h-full rounded-full ${editMode ? "cursor-pointer" : ""}`}
                  />
                </label>
                {editMode && (
                  <button
                    type="button"
                    className="absolute bottom-1 right-1 text-white bg-black w-6 h-6 rounded-full"
                    onClick={() => fileInputRef.current.click()}
                  >
                    📷
                  </button>
                )}
              </div>
            </div>
            <button
              onClick={toggleEdit}
              className={`absolute flex items-center py-2 top-2 right-2 px-4 text-white font-semibold rounded-md ${editMode ? "bg-gray-500" : "bg-green-500"}`}
            >
              {editMode ? <XMarkIcon className="w-5 h-5 mr-2" /> : <PencilIcon className="w-5 h-5 mr-2" />}
              {editMode ? "Annuler" : "Modifier"}
            </button>
          </div>
          <form className="grid grid-cols-1 md:grid-cols-2 gap-4" onSubmit={handleSubmit}>
            {[
              { label: "Prénom", name: "first_name", type: "text" },
              { label: "Nom", name: "last_name", type: "text" },
              { label: "Genre", name: "gender", type: "select", options: ["", "Femme", "Homme"] },
              { label: "Date Naissance", name: "birthday", type: "date" },
              { label: "Email", name: "email", type: "email" },
              { label: "Téléphone", name: "phone", type: "text" },
              { label: "Code Postal", name: "code_postal", type: "text" }
            ].map((field) => (
              <div className="flex flex-col gap-2" key={field.name}>
                <label htmlFor={field.name} className="font-semibold">{field.label}</label>
                {field.type === "select" ? (
                  <select
                    name={field.name}
                    id={field.name}
                    value={formData[field.name] || ""}
                    onChange={handleChange}
                    disabled={!editMode}
                    className={`p-2 border rounded-md ${!editMode ? "bg-gray-100" : ""}`}
                  >
                    {field.options.map((option, idx) => (
                      <option key={idx} value={option}>
                        {option || "-- Sélectionner --"}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type={field.type}
                    name={field.name}
                    id={field.name}
                    value={formData[field.name] || ""}
                    onChange={handleChange}
                    readOnly={!editMode}
                    className={`p-2 border rounded-md ${!editMode ? "bg-gray-100" : ""}`}
                  />
                )}
              </div>
            ))}
            <div className="flex flex-col gap-2"></div>
            <div className="flex flex-col gap-2">
              <label htmlFor="password" className="font-semibold">Mot de passe</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={!editMode}
                  className={`w-full p-2 border rounded-md ${!editMode ? "bg-gray-100" : ""}`}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-2 top-2"
                >
                  {showPassword ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                </button>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="passwordConfirm" className="font-semibold">Confirmer le mot de passe</label>
              <div className="relative">
                <input
                  type={showPasswordConfirm ? "text" : "password"}
                  name="passwordConfirm"
                  id="passwordConfirm"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  disabled={!editMode}
                  className={`w-full p-2 border rounded-md ${!editMode ? "bg-gray-100" : ""}`}
                />
                <button
                  type="button"
                  onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                  className="absolute right-2 top-2"
                >
                  {showPasswordConfirm ? <EyeSlashIcon className="w-5 h-5" /> : <EyeIcon className="w-5 h-5" />}
                </button>
              </div>
            </div>
            <div className="col-span-full mx-auto">
            <button
                type="submit"
                className={`flex items-center mt-4 px-4 py-2 text-white font-semibold rounded-md ${
                  editMode ? "bg-green-500" : "bg-gray-400 cursor-not-allowed"
                }`}
                disabled={!editMode || loading}
              >
                {loading ? (
                  <Loader className="w-5 h-5 mr-2 animate-spin" />
                ) : (
                  <CheckCircleIcon className="w-5 h-5 mr-2" />
                )}
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;