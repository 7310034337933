import { useEffect, useState } from "react";
import formatDate2 from "../../helpers/formatDate2";
import { BASE_IMAGE_URL } from "../../constants/actions";
import { ProfileImg } from "../../assets/images";

const itemsPerPage = 10;

const ListeRemarques = ({ notes }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);

  const handleToggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  const totalPages = Math.ceil(notes?.length / itemsPerPage);
  const maxVisibleButtons = 6;

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [notes]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleNotes = notes?.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="max-w-5xl mx-auto space-y-2">
      {visibleNotes?.map((note) => (
        <div key={note?.id} className="border rounded-lg bg-white">
          <div className="flex items-center gap-3 cursor-pointer p-3" onClick={() => handleToggleDropdown(note?.id)}>
            <div className="w-10 h-10 md:w-12 md:h-12 min-w-10 min-h-10 md:min-w-12 md:min-h-12 overflow-hidden rounded-full">
              <img
                src={
                  note?.user?.avatar
                    ? BASE_IMAGE_URL + note?.user?.avatar
                    : ProfileImg
                }
                alt="img"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="w-full flex items-center justify-between">
              <div className="flex flex-col justify-between">
                <h3 className="text-sm md:text-base font-bold">
                  {note?.user?.first_name
                    ? `${note?.user?.first_name} ${note?.user?.last_name}`
                    : note?.guest?.name}
                </h3>
                <h3 className="text-xs md:text-sm text-gray-500">
                  {note?.user?.email || note?.guest?.email}
                </h3>
              </div>
              <div className="text-xs md:text-sm text-gray-500 min-w-fit">
                {note?.created_at && formatDate2(note?.created_at)}
              </div>
            </div>
            <div>
              <i
                className={`bi bi-arrow-${
                  openDropdown === note?.id ? "up" : "down"
                }-circle-fill text-xl md:text-2xl text-gray-500`}
              ></i>
            </div>
          </div>
          {openDropdown === note?.id && (
            <div className="p-3 space-y-2 border-t">
              <h3 className="font-semibold">{note?.title}</h3>
              <p className="text-gray-700 text-sm md:text-base text-justify">
                {note?.content}
              </p>
            </div>
          )}
        </div>
      ))}
      {notes?.length > itemsPerPage && (
        <div className="flex items-center justify-between text-xs sm:text-xs md:text-sm px-7 py-5 my-4">
          <div>
            <p className="font-medium text-gray-pt">
              Affichage des données {startIndex + 1} à{" "}
              {Math.min(startIndex + itemsPerPage, notes?.length)} sur{" "}
              {notes?.length} entrées
            </p>
          </div>
          <nav className="flex items-center space-x-1 md:space-x-2 font-medium">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 text-gray-950 bg-gray-200 disabled:opacity-50"
            >
              <i className="bi bi-chevron-left"></i>
            </button>
            {visiblePages?.map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 ${
                  currentPage === page
                    ? "bg-orange-primary text-white"
                    : "text-gray-950 bg-gray-200"
                }`}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 text-gray-950 bg-gray-200 disabled:opacity-50"
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          </nav>
        </div>
      )}
      {notes?.length === 0 && (
        <div className="text-gray-500 text-center">Aucune Remarque</div>
      )}
    </div>
  );
};

export default ListeRemarques;
