import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import formatDate2 from "../../helpers/formatDate2";
import FormatDuration from "../../helpers/FormatDuration";
import { toast, ToastContainer } from "react-toastify";
import { apiClient } from "../../actions/api";
import { FetchData } from "../../actions/data";
import { SERVICES } from "../../constants/actions";
import { useDispatch } from "react-redux";

const ServicesListe = ({ services, itemsPerPage = 10, user }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);

  const totalPages = Math.ceil(services?.length / itemsPerPage);
  const maxVisibleButtons = 6;
  const location = useLocation();

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [services]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleRows = services?.slice(startIndex, startIndex + itemsPerPage);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const handleDelete = (serviceId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce service ?")) {
      apiClient()
        .delete(`/services/${serviceId}`)
        .then((res) => {
          toast.success("Service supprimé avec succès !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            toastId: "delete-success",
          });
          dispatch(FetchData(`/salonServices/${user?.id}`, SERVICES));        
        })
        .catch((error) => {
          toast.error("Erreur lors de la suppression du service.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            toastId: "delete-error",
          });
        });
    }
  };

  
  return (
    <div className="py-4 px-1 min-h-screen rounded-lg shadow-sm">
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto bg-white shadow-lg rounded-lg">
          <thead className="bg-gray-100 text-gray-600 text-sm uppercase font-bold text-left">
            <tr>
              <th className="py-3 px-4">Nom</th>
              {user?.role === "admin" && <th className="py-3 px-4">Prix</th>}
              {user?.role === "admin" && <th className="py-3 px-4">Durée</th>}
              <th className="py-3 px-4">Date Création</th>
              <th className="py-3 px-4 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {services?.length ? (
              visibleRows?.map((service) => (
                <tr
                  key={service?.id}
                  className="border-b hover:bg-gray-100 transition-all duration-150"
                >
                  <td className="py-3 px-4 capitalize">{service?.name}</td>
                  {user?.role === "admin" && (
                    <td className="py-3 px-4">{service?.pivot?.price} Dhs</td>
                  )}
                  {user?.role === "admin" && (
                    <td className="py-3 px-4">
                      {FormatDuration(service?.pivot?.duration)}
                    </td>
                  )}
                  <td className="py-3 px-4">
                    {service?.created_at && formatDate2(service?.created_at)}
                  </td>
                  <td className="py-3 px-4 text-center flex justify-center gap-4">
                    {/* Edit button */}
                    <button
                      onClick={() =>
                        navigate(`/parametres/services/${service?.id}`, {
                          state: { prevPath: location?.pathname },
                        })
                      }
                      className="text-green-500 hover:text-green-700 text-lg"
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>

                    {/* Delete button */}
                    <button
                      onClick={() => handleDelete(service?.id)} // Function to handle the delete
                      className="text-red-500 hover:text-red-700 text-lg"
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="py-4 text-center text-gray-500 italic"
                >
                  Aucun Service
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {services?.length > itemsPerPage && (
        <div className="flex justify-between items-center py-4">
          <p className="text-gray-600 text-sm">
            Affichage des données {startIndex + 1} à{" "}
            {Math.min(startIndex + itemsPerPage, services?.length)} sur{" "}
            {services?.length} entrées
          </p>
          <nav className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-1 bg-gray-200 hover:bg-gray-300 text-gray-600 disabled:opacity-50 rounded-md"
            >
              <i className="bi bi-chevron-left"></i>
            </button>
            {visiblePages?.map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`px-3 py-1 rounded-md ${
                  currentPage === page
                    ? "bg-orange-primary text-white"
                    : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                }`}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-3 py-1 bg-gray-200 hover:bg-gray-300 text-gray-600 disabled:opacity-50 rounded-md"
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          </nav>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ServicesListe;
