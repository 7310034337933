import { REMOVE_USER_INFOS, STROE_USER_INFOS } from "../constants/actions";

const initialState = {
    user: null,
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch(type) {
        case STROE_USER_INFOS:
            return {
                ...state,
                user: payload,
            };
        case REMOVE_USER_INFOS:
            return {
                ...state,
                user: null,
            };
        default:
            return state;
    }
}
