import React, { useEffect, useState } from "react";
import {
  StarIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ChatBubbleLeftIcon,
  CalendarIcon,
  EnvelopeIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { ProfileImg } from "../../assets/images";
import formatDate2 from "../../helpers/formatDate2";
import { BASE_IMAGE_URL } from "../../constants/actions";

const itemsPerPage = 10;

const ListeAvis = ({ reviews }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);

  const totalPages = Math.ceil(reviews?.length / itemsPerPage);
  const maxVisibleButtons = 6;

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handleToggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [reviews]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleReviews = reviews?.slice(startIndex, startIndex + itemsPerPage);

  if (!reviews || reviews.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-64 bg-gray-100 rounded-lg shadow-md">
        <ChatBubbleLeftIcon className="w-16 h-16 text-gray-400 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-700 mb-2">
          Aucun Avis
        </h2>
        <p className="text-gray-500">
          Il n'y a pas encore d'avis pour le moment.
        </p>
      </div>
    );
  }

  return (
    <div className="max-w-5xl mx-auto space-y-4 p-2 sm:p-4">
      {visibleReviews.map((review) => {
        const user = review?.user || review?.guest;

        return (
          <div
            key={review?.id}
            className="border rounded-lg bg-white shadow-sm hover:shadow-md transition-shadow duration-300"
          >
            <div
              className="flex items-center gap-4 p-3 sm:p-4 cursor-pointer flex-col sm:flex-row"
              onClick={() => handleToggleDropdown(review?.id)}
            >
              <div className="w-12 h-12 overflow-hidden rounded-full border-2 border-orange-200">
                <img
                  src={user?.avatar ? BASE_IMAGE_URL + user?.avatar : ProfileImg}
                  alt="Profile"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex-grow text-center sm:text-left">
                <h3 className="text-base sm:text-lg font-semibold text-gray-800">
                  {user?.first_name
                    ? `${user?.first_name} ${user?.last_name}`
                    : user?.name}
                </h3>
                <div className="flex items-center justify-center sm:justify-start text-xs sm:text-sm text-gray-500">
                  <EnvelopeIcon className="w-4 h-4 mr-1" />
                  {user?.email}
                </div>
              </div>
              <div className="text-sm text-gray-500 flex items-center justify-center sm:justify-start">
                <CalendarIcon className="w-4 h-4 mr-1" />
                {review?.created_at && formatDate2(review?.created_at)}
              </div>
              {openDropdown === review?.id ? (
                <ChevronUpIcon className="w-6 h-6 text-gray-500" />
              ) : (
                <ChevronDownIcon className="w-6 h-6 text-gray-500" />
              )}
            </div>
            {openDropdown === review?.id && (
              <div className="border-t p-3 sm:p-4 bg-gray-50">
                <div className="flex items-center justify-center sm:justify-start mb-2">
                  {Array.from({ length: 5 }, (_, index) => (
                    <StarIcon
                      key={index}
                      className={`w-4 sm:w-5 h-4 sm:h-5 ${
                        index < review?.rating
                          ? "text-yellow-400"
                          : "text-gray-300"
                      }`}
                    />
                  ))}
                </div>
                <p className="text-gray-700 text-sm sm:text-base italic text-center sm:text-left">
                  "{review?.comment}"
                </p>
              </div>
            )}
          </div>
        );
      })}
      {reviews.length > itemsPerPage && (
        <div className="flex flex-col sm:flex-row items-center justify-between text-xs sm:text-sm px-2 sm:px-4 py-4 sm:py-5 bg-white rounded-lg shadow-sm">
          <div className="mb-2 sm:mb-0 text-center sm:text-left">
            <p className="font-medium text-gray-700">
              Affichage des données {startIndex + 1} à{" "}
              {Math.min(startIndex + itemsPerPage, reviews.length)} sur{" "}
              {reviews.length} entrées
            </p>
          </div>
          <nav className="flex items-center space-x-1 sm:space-x-2 font-medium">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="p-2 rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <ChevronLeftIcon className="w-4 sm:w-5 h-4 sm:h-5" />
            </button>
            {visiblePages.map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`w-7 h-7 sm:w-8 sm:h-8 rounded-md ${
                  currentPage === page
                    ? "bg-orange-500 text-white"
                    : "text-gray-700 bg-gray-200 hover:bg-gray-300"
                }`}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="p-2 rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <ChevronRightIcon className="w-4 sm:w-5 h-4 sm:h-5" />
            </button>
          </nav>
        </div>
      )}
    </div>
  );
};

export default ListeAvis;