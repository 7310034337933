import { useLocation, useNavigate, useParams } from "react-router";
import { ComeBackButton, Layout } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { handleSeenMessages } from "../../actions/cms";
import FormatChatDate from "../../helpers/FormatChatDate";
import { BASE_IMAGE_URL } from "../../constants/actions";
import { ProfileImg } from "../../assets/images";

const MessagerieDetails = () => {
  const { id } = useParams();
  const data = useSelector((state) => state?.data) || [];
  const user = useSelector((state) => state?.auth?.user);
  const [messages, setMessages] = useState([]);
  const [client, setClient] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNaN(id)) {
      navigate("/messagerie");
      return;
    }
    let filtredChats;
    if(location?.state?.role === 'user'){
      filtredChats = data?.chats?.filter((chat) => chat?.user?.id === parseInt(id))?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));
    }else{
      filtredChats = data?.chats?.filter((chat) => chat?.guest?.id === parseInt(id))?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));
    }
    setMessages(filtredChats);
    setClient(filtredChats[0]?.user || filtredChats[0]?.guest);
    if (!filtredChats[0]?.seen) {
      dispatch(handleSeenMessages(user?.id, filtredChats));
    }
  }, [id]);

  return (
    <Layout>
      <div className="p-1">
        <ComeBackButton prev={location?.state?.prevPath} next={"/messagerie"} />
        <div className="bg-white">
          <div className="flex items-center gap-3 p-4 bg-gray-100">
            <div className="w-10 h-10 md:w-12 md:h-12 min-w-10 min-h-10 md:min-w-12 md:min-h-12 overflow-hidden rounded-full">
              <img
                src={
                  client?.avatar ? BASE_IMAGE_URL + client?.avatar : ProfileImg
                }
                alt="img"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="flex flex-col justify-between gap-1 w-fit">
              <h3 className="text-sm md:text-base font-bold">
                {client?.first_name ? `${client?.first_name} ${client?.last_name}` : client?.name}
              </h3>
              <h3 className="text-xs md:text-sm text-gray-500">
                {client?.email}
              </h3>
            </div>
          </div>
          <div className="p-4 space-y-7 h-screen overflow-y-auto">
            {messages?.map((chat) => (
              <div key={chat?.id} className="">
                <div className="flex items-center gap-2">
                  <div className="border w-full"></div>
                  <div className="min-w-fit text-xs sm:text-sm">
                    {chat?.created_at && FormatChatDate(chat?.created_at)}
                  </div>
                  <div className="border w-full"></div>
                </div>
                <div className="text-justify font-nunito bg-gray-50 w-fit p-2 min-w-44 max-w-6xl border rounded-bl-xl rounded-e-xl text-sm md:text-base">
                  {chat?.message}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MessagerieDetails;
