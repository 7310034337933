import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  UserIcon,
  EnvelopeIcon,
  PhoneIcon,
  CakeIcon,
  MapPinIcon,
  HomeIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
  CheckIcon,
  CameraIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { BgClientDetails, ProfileImg } from "../../assets/images";
import FormattingDate from "../../helpers/FormattingDate";
import { BASE_IMAGE_URL, CLIENTS, USERS } from "../../constants/actions";
import uploadImage from "../../helpers/uploadImages";
import { apiClient } from "../../actions/api";
import { FetchData } from "../../actions/data";

const UserProfile = ({ user }) => {
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState(user);
  const [selectedImage, setSelectedImage] = useState(
    BASE_IMAGE_URL + user?.avatar
  );
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setFormData(user);
      setSelectedImage(
        user?.avatar ? BASE_IMAGE_URL + user?.avatar : ProfileImg
      );
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const ToggleEdit = () => {
    setEdit(!edit);
    setFormData(user);
    setSelectedImage(user?.avatar ? BASE_IMAGE_URL + user?.avatar : ProfileImg);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChangeAvatar = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setFormData({ ...formData, avatar: file });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let newPath = false;
      if (formData?.avatar && formData?.avatar !== user?.avatar) {
        newPath = await uploadImage(
          "avatar",
          { avatar: formData?.avatar },
          { avatar: user?.avatar },
          "avatars"
        );
      }
      const data = {
        ...formData,
        avatar: !newPath ? formData?.avatar : newPath,
      };
      const response = await apiClient().put(`/users/${user?.id}`, data);
      if (response?.status === 200) {
        toast.success(
          "Les informations d'utilisateur ont été modifiées avec succès."
        );
        setEdit(false);
        setSelectedImage(BASE_IMAGE_URL + response?.data?.avatar);
      }
    } catch (err) {
      toast.error("Échec de la modification des informations d'utilisateur.");
      console.error(err);
    }
  };

  const DeleteUser = async (id) => {
    const confirmDelete = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
    );
    if (confirmDelete) {
      try {
        await apiClient().delete(`/users/${id}`);
        dispatch(FetchData("/users", USERS));
        dispatch(FetchData("/allClients", CLIENTS));
        navigate("/parametres/utilisateurs");
        toast.success("L'utilisateur a été supprimé avec succès.");
      } catch (error) {
        console.error("Failed to delete user", error);
        toast.error(
          "Une erreur s'est produite lors de la suppression de l'utilisateur."
        );
      }
    }
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="space-y-8 p-3 bg-gray-50 rounded-lg shadow-md">
      {/* <div className="w-full h-48 rounded-lg overflow-hidden relative"> */}
      {!isSmallScreen ? (
        <div className="relative h-36 bg-gradient-to-r rounded-lg from-yellow-500 to-orange-600">
          <div className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-t from-white to-transparent"></div>
          <div className="absolute top-4 left-4">
            {/* Image Upload and Preview */}
            <input
              type="file"
              id="img"
              accept="image/*"
              onChange={handleChangeAvatar}
              className="sr-only"
              disabled={!edit}
              ref={fileInputRef}
            />
            <div className="relative w-24 h-24 rounded-full border-4 border-white overflow-hidden bg-gray-100 flex items-center justify-center">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="avatar"
                  className="object-cover w-full h-full"
                />
              ) : (
                <UserCircleIcon className="w-32 h-32 text-gray-400" />
              )}
            </div>
            {edit && (
              <button
                type="button"
                className="absolute bottom-0 right-0 z-400 text-white bg-black w-8 h-8 rounded-full flex items-center justify-center"
                onClick={() => fileInputRef.current.click()}
              >
                <CameraIcon className="w-5 h-5" />
              </button>
            )}
          </div>
          <div className="absolute top-4 right-4 flex items-center gap-2 flex-wrap justify-end">
            <button
              onClick={ToggleEdit}
              className={`min-w-24 h-10 font-semibold rounded-md flex items-center justify-center gap-2 ${
                edit
                  ? "bg-gray-400 text-white"
                  : "bg-green-500 text-white hover:bg-green-600 px-2"
              }`}
            >
              {edit ? (
                <div className="flex px-4 items-center gap-1">
                  <XMarkIcon className="w-5 h-5" />
                  Annuler
                </div>
              ) : (
                <div className="flex px-4 items-center gap-1">
                  <PencilIcon className="w-5 h-5" />
                  Modifier
                </div>
              )}
            </button>
            <button
              onClick={() => DeleteUser(user?.id)}
              className="min-w-fit px-3 h-10 font-semibold bg-red-500 text-white rounded-md hover:bg-red-600 flex items-center justify-center gap-2"
            >
              <TrashIcon className="w-5 h-5" />
              Supprimer
            </button>
          </div>
          <div className="absolute bottom-4 right-4 text-white bg-black bg-opacity-50 px-3 py-1 rounded-md text-sm sm:text-base">
            Compte créé le{" "}
            {formData?.created_at && FormattingDate(formData?.created_at)}
          </div>
        </div>
      ) : (
        <div className={`space-y-6 p-1 bg-gray-50 flex flex-col`}>
          <div className="flex flex-col gap-3">
            <button
              onClick={ToggleEdit}
              className={`min-w-24 h-10 font-semibold rounded-md flex items-center justify-center gap-2 ${
                edit
                  ? "bg-gray-400 text-white"
                  : "bg-green-500 text-white hover:bg-green-600 px-2"
              }`}
            >
              {edit ? (
                <div className="flex px-4 items-center gap-1">
                  <XMarkIcon className="w-5 h-5" />
                  Annuler
                </div>
              ) : (
                <div className="flex px-4 items-center gap-1">
                  <PencilIcon className="w-5 h-5" />
                  Modifier
                </div>
              )}
            </button>
            <button
              onClick={() => DeleteUser(user?.id)}
              className="min-w-fit px-3 h-10 font-semibold bg-red-500 text-white rounded-md hover:bg-red-600 flex items-center justify-center gap-2"
            >
              <TrashIcon className="w-5 h-5" />
              Supprimer
            </button>
          </div>
          <div className="relative h-36 bg-gradient-to-r rounded-lg from-yellow-500 to-orange-600">
            <div className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-t from-white to-transparent"></div>
            <div className="absolute top-4 left-4">
              {/* Image Upload and Preview */}
              <input
                type="file"
                id="img"
                accept="image/*"
                onChange={handleChangeAvatar}
                className="sr-only"
                disabled={!edit}
                ref={fileInputRef}
              />
              <div className="relative w-24 h-24 rounded-full border-4 border-white overflow-hidden bg-gray-100 flex items-center justify-center">
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="avatar"
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <UserCircleIcon className="w-32 h-32 text-gray-400" />
                )}
              </div>
              {edit && (
                <button
                  type="button"
                  className="absolute bottom-0 right-0 z-400 text-white bg-black w-8 h-8 rounded-full flex items-center justify-center"
                  onClick={() => fileInputRef.current.click()}
                >
                  <CameraIcon className="w-5 h-5" />
                </button>
              )}
            </div>
            <div className="absolute bottom-4 right-4 text-white bg-black bg-opacity-50 px-3 py-1 rounded-md text-xs sm:text-sm">
            Compte créé le{" "}
            {formData?.created_at && FormattingDate(formData?.created_at)}
          </div>
          </div>
        </div>
      )}
      <form
        className={`grid grid-cols-1 md:grid-cols-2 gap-6 p-1`}
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-2">
          <label htmlFor="first_name" className="font-semibold">
            Prénom
          </label>
          <div className="relative">
            <UserIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              name="first_name"
              id="first_name"
              value={formData?.first_name || ""}
              onChange={handleChange}
              className={`w-full p-2 pl-10 border rounded-md outline-none focus:ring-2 focus:ring-orange-500 ${
                !edit && "bg-gray-100"
              }`}
              readOnly={!edit}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="last_name" className="font-semibold">
            Nom
          </label>
          <div className="relative">
            <UserIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              name="last_name"
              id="last_name"
              value={formData?.last_name || ""}
              onChange={handleChange}
              className={`w-full p-2 pl-10 border rounded-md outline-none focus:ring-2 focus:ring-orange-500 ${
                !edit && "bg-gray-100"
              }`}
              readOnly={!edit}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="font-semibold">
            Email
          </label>
          <div className="relative">
            <EnvelopeIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="email"
              name="email"
              id="email"
              value={formData?.email || ""}
              onChange={handleChange}
              className={`w-full p-2 pl-10 border rounded-md outline-none focus:ring-2 focus:ring-orange-500 ${
                !edit && "bg-gray-100"
              }`}
              readOnly={!edit}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="phone" className="font-semibold">
            Téléphone
          </label>
          <div className="relative">
            <PhoneIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              name="phone"
              id="phone"
              value={formData?.phone || ""}
              onChange={handleChange}
              className={`w-full p-2 pl-10 border rounded-md outline-none focus:ring-2 focus:ring-orange-500 ${
                !edit && "bg-gray-100"
              }`}
              readOnly={!edit}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="gender" className="font-semibold">
            Genre
          </label>
          <div className="relative">
            <UserIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
            <select
              onChange={handleChange}
              name="gender"
              id="gender"
              className={`w-full p-2 pl-10 border rounded-md outline-none focus:ring-2 focus:ring-orange-500 ${
                !edit && "bg-gray-100"
              }`}
              disabled={!edit}
              value={formData?.gender || ""}
            >
              <option value="">--genre--</option>
              <option value="femme">Femme</option>
              <option value="homme">Homme</option>
            </select>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="birthday" className="font-semibold">
            Date Naissance
          </label>
          <div className="relative">
            <CakeIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="date"
              name="birthday"
              id="birthday"
              value={formData?.birthday || ""}
              onChange={handleChange}
              className={`w-full p-2 pl-10 border rounded-md outline-none focus:ring-2 focus:ring-orange-500 ${
                !edit && "bg-gray-100"
              }`}
              readOnly={!edit}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="code_postal" className="font-semibold">
            Code Postal
          </label>
          <div className="relative">
            <MapPinIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              name="code_postal"
              id="code_postal"
              value={formData?.code_postal || ""}
              onChange={handleChange}
              className={`w-full p-2 pl-10 border rounded-md outline-none focus:ring-2 focus:ring-orange-500 ${
                !edit && "bg-gray-100"
              }`}
              readOnly={!edit}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="adresse" className="font-semibold">
            Adresse
          </label>
          <div className="relative">
            <HomeIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              name="adresse"
              id="adresse"
              value={formData?.adresse || ""}
              onChange={handleChange}
              className={`w-full p-2 pl-10 border rounded-md outline-none focus:ring-2 focus:ring-orange-500 ${
                !edit && "bg-gray-100"
              }`}
              readOnly={!edit}
            />
          </div>
        </div>
        {edit && (
          <div className="flex justify-end gap-2 col-span-1 md:col-span-2">
            <button
              type="submit"
              className="bg-orange-500 text-white rounded-md px-6 py-2 hover:bg-orange-600 flex items-center gap-2"
            >
              <CheckIcon className="w-5 h-5" />
              Valider
            </button>
          </div>
        )}
      </form>

      <ToastContainer />
    </div>
  );
};

export default UserProfile;
