import {
  ACCUEIL,
  AVAILABILITIES,
  BLOGS,
  BOOKINGS,
  CALENDARS,
  CHATS,
  CLIENTS,
  COMPLAINTS,
  COMPLETEDSALONS,
  CONDITIONS,
  DELETE_ALL_DATA,
  DEMANDES,
  EMAILS,
  EMPLOYEES,
  ENTREPRISE,
  FAQS,
  FEEDBACKS,
  FOOTER,
  GALERIE,
  GUESTS,
  NOTES,
  PAYMENTS,
  POLITIQUES,
  PROPOS,
  REASONS,
  REVIEWS,
  RGBD,
  SALON,
  SALONS,
  SERVICES,
  SUGGESTIONS,
  USERS,
} from "../constants/actions";

const initialState = {};

const actionTypeToStateKey = {
  [USERS]: 'users',
  [CLIENTS]: 'clients',
  [SALONS]: 'salons',
  [COMPLETEDSALONS]: 'completedSalons',
  [SERVICES]: 'services',
  [AVAILABILITIES]: 'availabilities',
  [BOOKINGS]: 'bookings',
  [PAYMENTS]: 'payments',
  [REVIEWS]: 'reviews',
  [COMPLAINTS]: 'complaints',
  [CHATS]: 'chats',
  [CALENDARS]: 'calendars',
  [NOTES]: 'notes',
  [EMPLOYEES]: 'employees',
  [BLOGS]: 'blogs',
  [PROPOS]: 'propos',
  [SALON]: 'salon',
  [EMAILS]: 'emails',
  [DEMANDES]: 'demandes',
  [GUESTS]: 'guests',
  [SUGGESTIONS]: 'suggestions',
  [CONDITIONS]: 'conditions',
  [POLITIQUES]: 'politiques',
  [RGBD]: 'rgbd',
  [ACCUEIL]: 'accueil',
  [FAQS]: 'faqs',
  [REASONS]: 'reasons',
  [FEEDBACKS]: 'feedbacks',
  [ENTREPRISE]: 'entreprise',
  [GALERIE]: 'galerie',
  [FOOTER]: 'footer',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  const stateKey = actionTypeToStateKey[type];
  
  if (stateKey) {
    return { ...state, [stateKey]: payload };
  }else if(type === DELETE_ALL_DATA){
      return {};
  }else{
    return state;
  }

};
