
const defaultState = {
    loading:false
}

export default (state = defaultState, action) => {
    const { type, payload = {} } = action

    switch (type) {
        case "":
            return {...state, loading:payload}
        default:
            return state
    }
}
