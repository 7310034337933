import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProfileImg } from "../../../assets/images";
import getBookingStatus from "../../../helpers/getBookingStatus";
import ServiceTimes from "../../../helpers/ServiceTimes";
import { BASE_IMAGE_URL } from "../../../constants/actions";
import FormatDuration from "../../../helpers/FormatDuration";

const ReservationInProgress = ({ reservation, isLoading }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [services, setServices] = useState([]);

  useEffect(() => {
    if (reservation) {
      const servicesWithTimes = reservation?.availability?.start_time && reservation?.services && ServiceTimes(
        reservation?.availability?.start_time,
        reservation?.services
      );
      setServices(servicesWithTimes);
    }
  }, [reservation]);

  if (isLoading) {
    return (
      <div className="bg-white border rounded-lg">
        <div className="flex flex-col space-y-4 py-4">
          <div className="flex items-center justify-between px-5">
            <div className="flex items-center gap-x-2">
              <div className="w-11 h-11 min-w-11 min-h-11 rounded-full bg-gray-300 animate-pulse" />
              <div className="flex flex-col justify-between gap-1">
                <div className="w-32 h-4 bg-gray-300 rounded animate-pulse" />
                <div className="w-24 h-3 bg-gray-300 rounded animate-pulse" />
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="w-24 h-8 bg-gray-300 rounded-full animate-pulse" />
              <div className="w-8 h-8 bg-gray-300 rounded-full animate-pulse" />
            </div>
          </div>

          <div className="py-4 border-t space-y-4 px-5">
            {Array.from({ length: 3 })?.map((_, i) => (
              <div key={i} className="flex items-center gap-x-3">
                <div className="w-10 h-10 min-w-10 min-h-10 bg-gray-300 rounded-full flex justify-center items-center animate-pulse" />
                <div className="flex items-center justify-between w-full">
                  <div className="flex flex-col justify-between gap-1">
                    <div className="w-24 h-4 bg-gray-300 rounded animate-pulse" />
                    <div className="w-20 h-3 bg-gray-300 rounded animate-pulse" />
                  </div>
                  <div className="flex flex-col justify-end min-w-fit gap-1">
                    <div className="w-24 h-4 bg-gray-300 rounded animate-pulse" />
                    <div className="w-20 h-3 bg-gray-300 rounded animate-pulse" />
                  </div>
                </div>
              </div>
            ))}
            <div className="flex justify-center pt-3">
              <div className="w-32 h-4 bg-gray-300 rounded animate-pulse" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!reservation) {
    return (
      <div className="bg-white border rounded-lg">
        <div className="flex justify-center items-center py-4 md:min-h-24">
          <h4 className="text-center font-bold text-gray-gt">Aucune réservation en cours</h4>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white border rounded-lg">
      <div className="flex items-center justify-between py-4 px-5">
        <div className="flex items-center gap-x-2">
          <div className="w-11 h-11 min-w-11 min-h-11 rounded-full bg-gray-200 overflow-hidden">
            <img
              src={reservation?.user?.avatar ? BASE_IMAGE_URL + reservation?.user?.avatar : ProfileImg}
              alt="avatar"
              className="object-cover w-full h-full"
            />
          </div>
          <div className="flex flex-col justify-between">
            <h3 className="font-bold text-gray-gt">
              {reservation?.user?.first_name ? `${reservation?.user?.first_name} ${reservation?.user?.last_name}` : reservation?.guest?.name}
            </h3>
            <p className="text-sm text-gray-pt">
              {reservation?.user?.email || reservation?.guest?.email}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-x-3">
          <div className="rounded-full uppercase py-1 px-2 sm:py-2 sm:px-4 bg-pink-extralight text-orange-primary text-sm sm:text-base">
            {reservation?.availability?.date && getBookingStatus(
              reservation?.availability?.date,
              reservation?.availability?.start_time,
              reservation?.availability?.end_time,
              reservation?.status
            )?.type}
          </div>
          <button
            onClick={() =>
              navigate(`/reservations/${reservation.id}`, {
                state: { prevPath: location?.pathname },
              })
            }
          >
            <i className="bi bi-arrow-right text-xl sm:text-2xl"></i>
          </button>
        </div>
      </div>

      <div className="py-4 px-5 space-y-4 border-t">
        {services?.map((service, i) => (
          <div key={i} className="flex items-center gap-x-3">
            <div className="w-10 h-10 min-w-10 min-h-10 bg-gray-800 rounded-full flex justify-center items-center">
              <div className="bg-white w-2 h-2 rounded-full"></div>
            </div>
            <div className="flex items-center justify-between w-full">
              <div className="flex flex-col justify-between">
                <time className="text-sm sm:text-base font-medium text-gray-pt">
                  {service?.startTime} - {service?.endTime}
                </time>
                <p className="text-sm sm:text-base font-medium text-gray-gt capitalize">
                  application {service?.name}
                </p>
              </div>
              <div className="flex flex-col justify-end min-w-fit">
                <time className="text-sm sm:text-base font-medium text-gray-pt w-fit min-w-fit">
                  {FormatDuration(service?.pivot?.duration)}
                </time>
                <p className="text-sm sm:text-base font-medium text-gray-gt capitalize">
                  {service?.pivot?.price} dhs
                </p>
              </div>
            </div>
          </div>
        ))}
        <div className="flex justify-center pt-3">
          <p
            onClick={() =>
              navigate(`/reservations/${reservation.id}`, {
                state: { prevPath: location?.pathname },
              })
            }
            className="text-orange-primary font-bold text-sm sm:text-base cursor-pointer"
          >
            Afficher plus
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReservationInProgress;