import React, { useState } from "react";
import {
  AddBlog,
  ComeBackButton,
} from "../../components";
import { useLocation, useNavigate } from "react-router";
import { addBlog } from "../../actions/cms";
import uploadImage from "../../helpers/uploadImages";
import { useDispatch } from "react-redux";
import CmsLayout from "../cms";

const AjouterBlog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [blogData, setBlogData] = useState();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBlogData({ ...blogData, [name]: value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    try{
      // const newPath = await uploadImage(
      //   "image",
      //   { image: blogData?.image },
      //   { image: "" },
      //   "Blogs"
      // );
      const data = {
        ...blogData,
        // image: newPath,
      };

      dispatch(addBlog(data));
      navigate("/pages/blogs");
    }catch(err){
      console.error(err);
    }
  };

  return (
    <CmsLayout>
        <div className="p-4 min-h-screen">
          <ComeBackButton prev={location?.state?.prevPath} next={"/pages/blogs"} />
          <AddBlog
            blogData={blogData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </div>
    </CmsLayout>
  );
};

export default AjouterBlog;
