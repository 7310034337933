import { useLocation, useNavigate, useParams } from "react-router";
import { ComeBackButton, Layout, ProfileClient, ReservationsClients } from "../../components";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const ClientDeatails = () => {
  const { id } = useParams();
  const clients = useSelector((state) => state?.data?.clients) || [];
  const reservations = useSelector((state) => state?.data?.bookings);
  const [client, setClient] = useState();
  const [costomReservations, setCostomReservations] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isNaN(id)) {
      navigate("/clients");
      return;
    }
    const clt = clients?.find((client) => client?.id === parseInt(id));
    if (clients?.length > 0 && !clt) {
      navigate("/clients");
      return;
    }
    setClient(clt);
  }, [id, clients]);

  useEffect(() => {
    if (client) {
      const filtredReservations = reservations?.filter(
        (res) => res?.user_id === client?.id
      );
      setCostomReservations(filtredReservations);
    }
  }, [reservations, client]);

  return (
    <Layout>
      <div className="">
        <ComeBackButton prev={location?.state?.prevPath} next={"/clients"} />
        <div className="flex flex-col xl:flex-row gap-6 px-4 pb-4">
          <div className="w-full xl:basis-4/6">
            <ProfileClient client={client} />
          </div>
          <div className="w-full xl:basis-2/6">
            <ReservationsClients reservations={costomReservations} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ClientDeatails;
