import React, { useState, useEffect, useCallback } from "react";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  PlusCircle,
  RefreshCcw,
  Trash2,
} from "lucide-react";
import TextEditor from "../globalcomponents/TextEditor";
import { BASE_IMAGE_URL, GALERIE } from "../../constants/actions";
import { useSelector, useDispatch } from "react-redux";
import uploadMultipleImage from "../../helpers/uploadMultipleImages";
import { apiClient } from "../../actions/api";
import { FetchData } from "../../actions/data";
import { toast } from "react-toastify";

const Galerie = ({
  formData,
  handleChange,
  edit,
  setIsUploading,
  SubmitHandler,
  ResetHandler,
}) => {
  const dispatch = useDispatch();
  const galerie = useSelector((state) => state?.data?.galerie) || [];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setImages(
      galerie?.map((item) => ({
        id: item.id,
        src:
          typeof item?.image === "string"
            ? BASE_IMAGE_URL + item?.image
            : URL.createObjectURL(item?.image),
        image: item.image,
      }))
    );
  }, [galerie]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getImagesPerSlide = useCallback(() => {
    if (windowWidth >= 1280) return 4;
    if (windowWidth >= 1024) return 3;
    if (windowWidth >= 768) return 2;
    return 1;
  }, [windowWidth]);

  useEffect(() => {
    const imagesPerSlide = getImagesPerSlide();
    const maxIndex = Math.max(0, Math.ceil(images?.length / imagesPerSlide) - 1);
    setCurrentIndex(prevIndex => Math.min(prevIndex, maxIndex));
  }, [images, getImagesPerSlide]);

  const nextSlide = () => {
    const imagesPerSlide = getImagesPerSlide();
    const maxIndex = Math.ceil(images.length / imagesPerSlide) - 1;
    if (currentIndex < maxIndex) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    setIsUploading(true);

    for (const file of files) {
      if (file.size > 2500 * 1024) {
        toast.warning(
          `Le fichier ${file.name} est trop volumineux pour être téléchargé.`
        );
        continue;
      }

      try {
        const uploadedImage = await uploadMultipleImage(
          "image",
          { image: file },
          { image: "" },
          "galerie"
        );
        const uploadedImg = await apiClient().post("/galerie", {
          image: uploadedImage?.path,
        });
        setImages((prevImages) => [
          ...prevImages,
          {
            id: uploadedImg?.id,
            src: BASE_IMAGE_URL + uploadedImage.path,
            image: uploadedImage.path,
          },
        ]);
      } catch (error) {
        console.error("Failed to upload image:", error);
      }
    }
    setIsUploading(false);
    dispatch(FetchData("galerie", GALERIE));
  };

  const handleDeleteImage = async (image) => {
    try {
      await apiClient().delete(`/galerie/${image?.id}`);
      await apiClient().post("/remove", { path: image?.image });
      setImages((prevImages) =>
        prevImages?.filter((img) => img.id !== image.id)
      );
      toast.success("Image supprimée avec succès");
      dispatch(FetchData("galerie", GALERIE));
    } catch (error) {
      console.error("Failed to delete image:", error);
      toast.error("Échec de la suppression de l'image");
    }
  };

  const fields = ["galleryTitle", "galleryDescription"];

  return (
    <form onSubmit={(e) => SubmitHandler(e, fields)} className="w-full">
      <div className="flex items-center justify-between">
        <h1 className="text-lg md:text-xl lg:text-2xl font-semibold my-2">
          Section de Galerie
        </h1>
      </div>
      <div className="space-y-4 p-2 border rounded-lg bg-white">
        {edit && (
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              onClick={() => ResetHandler(fields)}
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-gray-500 hover:bg-gray-600"
            >
              <RefreshCcw className="w-4 h-4 md:h-6 md:w-6" />
            </button>
            <button
              type="submit"
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-blue-900 hover:bg-blue-950"
            >
              <Check className="w-4 h-4 md:h-6 md:w-6" />
            </button>
          </div>
        )}
        <div className="text-xs md:text-sm lg:text-base">
          <div className="p-2 space-y-2">
            <div className="flex flex-col gap-2">
              <label htmlFor="galleryTitle">Titre</label>
              <input
                type="text"
                name="galleryTitle"
                id="galleryTitle"
                placeholder="Titre"
                value={formData?.galleryTitle || ""}
                onChange={handleChange}
                className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                  !edit && "opacity-70"
                }`}
                readOnly={!edit}
                required={true}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="galleryDescription">Description</label>
              <div className="min-h-fit h-fit bg-white overflow-hidden border-b">
                <TextEditor
                  texteditor={formData?.galleryDescription}
                  setTexteditor={(value) =>
                    handleChange({
                      target: { name: "galleryDescription", value },
                    })
                  }
                  disabled={!edit}
                  style={!edit && "opacity-70"}
                />
              </div>
            </div>
            <div className="space-y-4">
              <div className="overflow-hidden">
                <div
                  className="flex transition-transform duration-300 ease-in-out"
                  style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                  {Array(Math.ceil(images?.length / getImagesPerSlide()))
                    .fill()
                    .map((_, slideIndex) => (
                      <div
                        key={slideIndex}
                        className="w-full flex-shrink-0 flex gap-2"
                      >
                        {images
                          ?.slice(
                            slideIndex * getImagesPerSlide(),
                            (slideIndex + 1) * getImagesPerSlide()
                          )
                          ?.map((image) => (
                            <div
                              key={image.id}
                              className={`px-1 relative ${
                                getImagesPerSlide() === 1
                                  ? "w-full"
                                  : getImagesPerSlide() === 2
                                  ? "w-1/2"
                                  : getImagesPerSlide() === 3
                                  ? "w-1/3"
                                  : "w-1/4"
                              }`}
                            >
                              <div className="h-72 w-full mx-auto mb-2 overflow-hidden relative group">
                                <img
                                  src={image.src}
                                  className="object-cover w-full h-full rounded-md"
                                  alt={`galerie ${image.id}`}
                                />
                                {edit && (
                                  <button
                                    onClick={() => handleDeleteImage(image)}
                                    type="button"
                                    className="absolute top-2 right-2 p-2 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                                  >
                                    <Trash2 className="w-4 h-4" />
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              </div>
              {images?.length > 0 && (
                <div className="flex items-center justify-end gap-4">
                  <div className="font-bold">
                    Slide {currentIndex + 1} /{" "}
                    {Math.max(
                      1,
                      Math.ceil(images?.length / getImagesPerSlide())
                    )}
                  </div>
                  <div className="flex gap-3">
                    <button
                      type="button"
                      onClick={prevSlide}
                      className={`p-3 rounded-full overflow-hidden ${
                        currentIndex === 0
                          ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                          : "bg-gray-300 text-gray-800 hover:bg-gray-800 hover:text-white"
                      }`}
                      disabled={currentIndex === 0}
                    >
                      <ChevronLeft className="w-5 h-5 md:h-7 md:w-7" />
                    </button>
                    <button
                      type="button"
                      onClick={nextSlide}
                      className={`p-3 rounded-full overflow-hidden ${
                        currentIndex ===
                        Math.ceil(images.length / getImagesPerSlide()) - 1
                          ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                          : "bg-gray-300 text-gray-800 hover:bg-gray-800 hover:text-white"
                      }`}
                      disabled={
                        currentIndex ===
                        Math.ceil(images.length / getImagesPerSlide()) - 1
                      }
                    >
                      <ChevronRight className="w-5 h-5 md:h-7 md:w-7" />
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-4 relative">
              <input
                type="file"
                id="addImages"
                multiple
                accept="image/*"
                className="sr-only"
                onChange={handleFileChange}
                disabled={!edit}
              />
              <label
                htmlFor="addImages"
                className={`flex items-center justify-center p-2 border border-dashed rounded-md h-12 ${
                  edit
                    ? "bg-slate-200 hover:bg-gray-300 cursor-pointer"
                    : "cursor-not-allowed bg-gray-100"
                }`}
              >
                <PlusCircle className="w-5 h-5 mr-2" />
                Ajouter images
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Galerie;
