import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import formatDate2 from "../../../helpers/formatDate2";
import { ChevronLeft, ChevronRight, CoinsIcon } from "lucide-react";
import { CreditCardIcon } from '@heroicons/react/24/solid';


const PaymentMethodCell = (paymentMethod) => {
  const getIconAndText = () => {
    switch (paymentMethod) {
      case 'CASH':
        return { 
          Icon: CoinsIcon, 
          text: 'Espèces' 
        };
        case 'cash':
          return { 
            Icon: CoinsIcon, 
            text: 'Espèces' 
          };
      case 'PAYPAL':
        return { 
          Icon: (props) => (
            <svg {...props} viewBox="0 0 24 24" fill="currentColor">
              <path d="M7.076 21.337H2.47a.641.641 0 0 1-.633-.74L4.944.901C5.026.382 5.474 0 5.998 0h7.46c2.57 0 4.578.543 5.69 1.81 1.01 1.15 1.304 2.42 1.012 4.287-.023.143-.047.288-.077.437-.983 5.05-4.349 6.797-8.647 6.797h-2.19c-.524 0-.968.382-1.05.9l-1.12 7.106zm14.146-14.42a3.35 3.35 0 0 0-.607-.541c-.013.076-.026.175-.041.254-.59 3.025-2.596 4.692-6.001 4.692h-2.19a.563.563 0 0 0-.556.476l-1.187 7.517h4.282c.47 0 .876-.339.947-.806l.039-.23.74-4.693.047-.245c.072-.467.478-.806.948-.806h.597c3.863 0 6.889-1.57 7.772-6.11.37-1.904.18-3.501-.8-4.508z"/>
            </svg>
          ), 
          text: 'PayPal' 
        };
        case 'paypal':
          return { 
            Icon: (props) => (
              <svg {...props} viewBox="0 0 24 24" fill="currentColor">
                <path d="M7.076 21.337H2.47a.641.641 0 0 1-.633-.74L4.944.901C5.026.382 5.474 0 5.998 0h7.46c2.57 0 4.578.543 5.69 1.81 1.01 1.15 1.304 2.42 1.012 4.287-.023.143-.047.288-.077.437-.983 5.05-4.349 6.797-8.647 6.797h-2.19c-.524 0-.968.382-1.05.9l-1.12 7.106zm14.146-14.42a3.35 3.35 0 0 0-.607-.541c-.013.076-.026.175-.041.254-.59 3.025-2.596 4.692-6.001 4.692h-2.19a.563.563 0 0 0-.556.476l-1.187 7.517h4.282c.47 0 .876-.339.947-.806l.039-.23.74-4.693.047-.245c.072-.467.478-.806.948-.806h.597c3.863 0 6.889-1.57 7.772-6.11.37-1.904.18-3.501-.8-4.508z"/>
              </svg>
            ), 
            text: 'PayPal' 
          };
      case 'GATEWAY':
        return { 
          Icon: CreditCardIcon, 
          text: 'Gateway' 
        };
        case 'gateway':
          return { 
            Icon: CreditCardIcon, 
            text: 'Gateway' 
          };
      default:
        return { 
          Icon: null, 
          text: paymentMethod 
        };
    }
  };

  const { Icon, text } = getIconAndText();

  return (
    <td className="py-3 px-2 text-sm text-left">
      <div className="flex items-center">
        {Icon && <Icon className="h-5 w-5 mr-2 text-orange-600" />}
        <span>{text}</span>
      </div>
    </td>
  );
};

const ListePaiements = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(props?.paiements?.length / props?.itemsPerPage);
  const navigate = useNavigate();
  const location = useLocation();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * props?.itemsPerPage;
  const visibleRows = props?.paiements?.slice(
    startIndex,
    startIndex + props?.itemsPerPage
  );

  const getPaginationButtons = () => {
    const maxButtons = 6;
    const buttons = [];
    let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxButtons - 1);

    if (currentPage <= Math.floor(maxButtons / 2)) {
      endPage = Math.min(totalPages, maxButtons);
    }
    if (currentPage > totalPages - Math.floor(maxButtons / 2)) {
      startPage = Math.max(1, totalPages - maxButtons + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => props?.onPageChange(i)}
          className={`mx-1 w-8 h-8 ${
            currentPage === i
            ? "bg-orange-500 text-white"
            : "text-gray-700 bg-gray-200"
          } rounded-full flex items-center justify-center`}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  

  return (
    <div className="pt-6">
      <div>
        <div className="overflow-x-auto">
          <table className="w-full text-sm">
            <thead>
              <tr className="bg-stone-500">
                <th className="py-3 px-4 text-white text-left whitespace-nowrap">#ID</th>
                <th className="py-3 px-4 text-white text-left whitespace-nowrap">Client</th>
                <th className="py-3 px-4 text-white text-left whitespace-nowrap">Salon</th>
                <th className="py-3 px-4 text-white text-left whitespace-nowrap">Montant</th>
                <th className="py-3 px-4 text-white text-left whitespace-nowrap">Status</th>
                <th className="py-3 px-4 text-white text-left whitespace-nowrap">Méthode</th>
                <th className="py-3 px-4 text-white text-left whitespace-nowrap">ID Transaction</th>
                <th className="py-3 px-4 text-white text-left whitespace-nowrap">Date Paiement</th>
                <th className="py-3 px-4 text-white text-left whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              {props?.paiements?.length ? visibleRows?.map((paiement) => (
                <tr
                  key={paiement?.id}
                  className="text-gray-gt border-b text-[10px] sm:text-xs md:text-sm lg:text-base"
                >
                  <td className="py-3 px-2 text-sm text-left">#{paiement?.id}</td>
                  <td className="py-3 px-2 text-sm text-left capitalize">
                    {paiement?.booking?.user?.first_name ? `${paiement?.booking?.user?.first_name} ${paiement?.booking?.user?.last_name}` : paiement?.booking?.guest?.name}{" "}
                  </td>
                    
                  <td className="py-3 px-2 text-sm text-left" >{paiement?.booking?.salon?.name}</td>
                  <td className="py-3 px-2 text-sm text-left">{paiement?.amount} Dhs</td>
                  <td className="py-3 px-2 text-sm text-left">
                    {paiement?.payment_status ? "Payé" : "En Attente"}
                  </td>
                  {PaymentMethodCell(paiement?.payment_method)}
                  <td className="py-3 px-2 text-sm text-left">{paiement?.transaction_id}</td>
                  <td className="py-3 px-2 text-sm text-left">{paiement?.created_at && formatDate2(paiement?.created_at)}</td>
                  <td className="py-3 px-2 text-sm text-center">
                    <button
                      onClick={() =>
                        navigate(`/paiements/${paiement?.id}`, {
                          state: { prevPath: location?.pathname },
                        })
                      }
                      className="text-gray-pt text-base sm:text-lg md:text-xl lg:text-2xl"
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan={8} className="py-2 text-center text-gray-500">Aucun Paiement</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {props?.paiements?.length > props?.itemsPerPage && (
        // <div className="flex items-center justify-end text-xs sm:text-sm md:text-base px-7 py-5 my-4">
        //   <nav className="flex items-center space-x-1 md:space-x-2 font-medium">
        //     <button
        //       onClick={() => handlePageChange(currentPage - 1)}
        //       disabled={currentPage === 1}
        //       className="w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 text-gray-950 bg-gray-200 disabled:opacity-50"
        //     >
        //       <i className="bi bi-chevron-left"></i>
        //     </button>
        //     {getPaginationButtons()}
        //     <button
        //       onClick={() => handlePageChange(currentPage + 1)}
        //       disabled={currentPage === totalPages}
        //       className="w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 text-gray-950 bg-gray-200 disabled:opacity-50"
        //     >
        //       <i className="bi bi-chevron-right"></i>
        //     </button>
        //   </nav>
        // </div>
        <div className="flex justify-center items-center p-4 pb-12">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="mr-2 p-2 rounded-full bg-gray-200 disabled:opacity-50"
          >
            <ChevronLeft size={20} />
          </button>
          {getPaginationButtons()}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="ml-2 p-2 rounded-full bg-gray-200 disabled:opacity-50"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ListePaiements;
