// import {
//   AVAILABILITIES,
//   BLOGS,
//   BOOKINGS,
//   CALENDARS,
//   CHATS,
//   CLIENTS,
//   COMPLAINTS,
//   EMPLOYEES,
//   NOTES,
//   PAYMENTS,
//   PROPOS,
//   REVIEWS,
//   SALON,
//   SALONS,
//   SERVICES,
//   USERS,
// } from "../constants/actions";
import { apiClient } from "./api";

// const actionTypes = {
//   "/users": USERS,
//   "/clients": CLIENTS,
//   "/salons": SALONS,
//   "/services": SERVICES,
//   "/availabilities": AVAILABILITIES,
//   "/bookings": BOOKINGS,
//   "/payments": PAYMENTS,
//   "/reviews": REVIEWS,
//   "/complaints": COMPLAINTS,
//   "/chats": CHATS,
//   "/calendars": CALENDARS,
//   "/notes": NOTES,
//   "/employees": EMPLOYEES,
//   "/blogs": BLOGS,
//   "/propos": PROPOS,
// };

// export const getData = (uri) => async (dispatch) => {
//   try {
//     const res = await apiClient().get(uri);
//     const actionType = actionTypes[uri];

//     if (actionType) {
//       dispatch({ type: actionType, payload: res.data });
//     } else {
//       console.warn("Unhandled URI:", uri);
//     }
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   }
// };

// export const getSalonData = (uri) => async (dispatch) => {
//   try {
//     const res = await apiClient().get(uri);

//     if (res) {
//       dispatch({ type: SALON, payload: res.data });
//     } else {
//       console.warn("Unhandled URI:", uri);
//     }
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   }
// };

// export const getSpecifiqueData = (uri) => async (dispatch) => {
//   try {
//     const res = await apiClient().get(uri);

//     if (res) {
//       dispatch({ type: REVIEWS, payload: res.data });
//     } else {
//       console.warn("Unhandled URI:", uri);
//     }
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   }
// };

export const FetchData = (uri, action) => async (dispatch) => {
  try {
    const res = await apiClient().get(uri);

    if (res) {
      dispatch({ type: action, payload: res.data });
    } else {
      console.warn("Unhandled URI:", uri);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
