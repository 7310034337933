import React, { useEffect, useState, useCallback } from "react";
import { Layout, ReservationTable } from "../../components";
import { useSelector } from "react-redux";
import getBookingStatus from "../../helpers/getBookingStatus";

const sortOptions = ["Date", "Nom"];
const filterOptions = ["Tout", "En Attente", "En Cours", "Terminé", "Annulé"];

const Reservations = () => {
  const reservations = useSelector((state) => state?.data?.bookings) || [];
  const [filteredReservations, setFilteredReservations] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortOption, setSortOption] = useState("Date");
  const [filterOption, setFilterOption] = useState("Tout");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const createDateTime = useCallback((date, start_time) => new Date(`${date}T${start_time}`), []);

  const filterReservations = useCallback((reservations, filter, search) => {
    let filtered = reservations.filter((res) => {
      if (filter === "Tout") return true;
      return (
        res?.availability?.date && 
        getBookingStatus(
          res?.availability?.date,
          res?.availability?.start_time,
          res?.availability?.end_time,
          res?.status
        )?.type === filter
      );
    });

    if (search) {
      const searchLowerCase = search.trim().toLowerCase();
      filtered = filtered?.filter((res) => {
        const fullName = `${res?.user?.first_name ? `${res?.user?.first_name} ${res?.user?.last_name}` : res?.guest?.name}`.toLowerCase();
        const reversedFullName = `${res?.user?.last_name} ${res?.user?.first_name}`.toLowerCase();
        const firstName = res?.user?.first_name?.toLowerCase();
        const lastName = res?.user?.last_name?.toLowerCase();

        return (
          firstName?.includes(searchLowerCase) ||
          lastName?.includes(searchLowerCase) ||
          fullName?.includes(searchLowerCase) ||
          reversedFullName?.includes(searchLowerCase)
        );
      });
    }

    return filtered;
  }, []);

  const sortReservations = useCallback((reservations, sort) => {
    const reservationsWithDateTime = reservations?.map((reservation) => ({
      ...reservation,
      reservationDateTime: createDateTime(
        reservation?.availability?.date,
        reservation?.availability?.start_time
      ),
    }));

    if (sort === "Date") {
      return reservationsWithDateTime.sort((a, b) => b?.reservationDateTime - a?.reservationDateTime);
    } else if (sort === "Nom") {
      return reservationsWithDateTime.sort((a, b) => {
        const firstNameComparison = (a?.user?.first_name || '').localeCompare(b?.user?.first_name || '');
        if (firstNameComparison === 0) {
          return (a?.user?.last_name || '').localeCompare(b?.user?.last_name || '');
        }
        return firstNameComparison;
      });
    }
    return reservationsWithDateTime;
  }, [createDateTime]);

  const updateReservationsList = useCallback(() => {
    let result = filterReservations(reservations, filterOption, searchInput);
    result = sortReservations(result, sortOption);
    setFilteredReservations(result);
  }, [reservations, filterOption, searchInput, sortOption, filterReservations, sortReservations]);

  useEffect(() => {
    updateReservationsList();
  }, [updateReservationsList]);

  const handleSearchChange = (newSearchInput) => {
    setSearchInput(newSearchInput);
    setCurrentPage(1);
  };

  const handleSortChange = (newSortOption) => {
    setSortOption(newSortOption);
    setCurrentPage(1);
  };

  const handleFilterChange = (newFilterOption) => {
    setFilterOption(newFilterOption);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Layout>
      <ReservationTable
        reservations={filteredReservations}
        itemsPerPage={itemsPerPage}
        searchInput={searchInput}
        setSearchInput={handleSearchChange}
        sortOptions={sortOptions}
        sortOption={sortOption}
        setSortOption={handleSortChange}
        filterOptions={filterOptions}
        filterOption={filterOption}
        setFilterOption={handleFilterChange}
        currentPage={currentPage}
        setCurrentPage={handlePageChange}
      />
    </Layout>
  );
};

export default Reservations;