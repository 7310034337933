import ProfileIcon from '../globalcomponents/ProfileIcon';
import { Avatar1 } from '../../assets/images'

const Header = ({ toggleSidebar }) => {

  return (
    <div className='h-16 sm:h-[72px] md:h-20 border-b flex bg-gray-bg'>
      <div className="w-full flex items-center justify-between px-1">
        <div className="flex items-center">
          <button 
            onClick={toggleSidebar}
            aria-controls="default-sidebar" 
            type="button" 
            className="text-2xl px-2 py-1 text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          >
            <i className="bi bi-list"></i>
          </button>
          {/* <div className="pl-3">
            <h1 className='text-lg md:text-xl lg:text-2xl font-bold text-gray-gt'>
              {title}
            </h1>
          </div> */}
        </div>
        <div className="flex items-center justify-end gap-x-2 h-full basis-1/3 min-w-fit px-2">
          {/* {btn && (
            <button className='rounded-full h-8 sm:h-10 px-2 sm:px-3 text-xs sm:text-sm md:text-base bg-orange-primary text-white hidden sm:flex justify-center items-center'>
              <span>{btn}</span><span><i className="bi bi-plus"></i></span>
            </button>
          )}
          <SearchBtn/> */}
          <ProfileIcon />
        </div>
      </div>
    </div>
  );
}

export default Header;
