import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../context/authContext";
import { apiClient } from "../../actions/api";
import uploadImage from "../../helpers/uploadImages";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { FetchData } from "../../actions/data";
import { BASE_IMAGE_URL, SALON } from "../../constants/actions";
import { ChevronLeftIcon, ChevronRightIcon, PlusIcon, TrashIcon, PhotoIcon } from '@heroicons/react/24/outline';

const ImageGallery = ({ salon, albumInputRef, setLoadingUpload }) => {
  const dispatch = useDispatch();
  const { isSuperAdmin } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setImages(
      salon?.album?.map((item) => ({
        id: item.id,
        src: BASE_IMAGE_URL + item?.image,
        image: item.image,
      })) || []
    );
  }, [salon]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getImagesPerSlide = useCallback(() => {
    if (windowWidth >= 1536) return 5; // 2xl
    if (windowWidth >= 1280) return 4; // xl
    if (windowWidth >= 1024) return 3; // lg
    if (windowWidth >= 768) return 2; // md
    return 1; // sm and xs
  }, [windowWidth]);

  useEffect(() => {
    const imagesPerSlide = getImagesPerSlide();
    const maxIndex = Math.max(0, Math.ceil(images?.length / imagesPerSlide) - 1);
    setCurrentIndex(prevIndex => Math.min(prevIndex, maxIndex));
  }, [images, getImagesPerSlide]);

  const nextSlide = () => {
    const imagesPerSlide = getImagesPerSlide();
    const maxIndex = Math.ceil(images.length / imagesPerSlide) - 1;
    if (currentIndex < maxIndex) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    setLoadingUpload(true);

    for (const file of files) {
      if (file.size > 2500 * 1024) {
        toast.warning(
          `Le fichier ${file.name} est trop volumineux pour être téléchargé.`
        );
        continue;
      }

      try {
        const uploadedImage = await uploadImage(
          "image",
          { image: file },
          { image: "" },
          "album"
        );
        const uploadedImg = await apiClient().post(`/album`, {
          image: uploadedImage,
          salon_id: salon?.id,
        });
        setImages((prevImages) => [
          ...prevImages,
          {
            id: uploadedImg?.id,
            src: BASE_IMAGE_URL + uploadedImage,
            image: uploadedImage,
          },
        ]);
      } catch (error) {
        console.error("Failed to upload image:", error);
      }
    }
    setLoadingUpload(false);
    dispatch(FetchData(`/salonById/${salon?.user?.id}`, SALON));
  };

  const handleDeleteImage = async (image) => {
    try {
      await apiClient().delete(`/album/${image?.id}`);
      await apiClient().post("/remove", { path: image?.image });
      setImages((prevImages) =>
        prevImages?.filter((img) => img.id !== image.id)
      );
      toast.success("Image supprimée avec succès");
      dispatch(FetchData(`/salonById/${salon?.user?.id}`, SALON));
    } catch (error) {
      console.error("Failed to delete image:", error);
      toast.error("Échec de la suppression de l'image");
    }
  };

  return (
    <div className="space-y-8 bg-gradient-to-br from-gray-50 to-gray-100 p-6 rounded-xl shadow-lg">
      {!isSuperAdmin && (
        <div className="relative">
          <input
            type="file"
            id="addImages"
            multiple
            accept="image/*"
            className="sr-only"
            onChange={handleFileChange}
            ref={albumInputRef}
          />
          <label
            htmlFor="addImages"
            className="flex items-center justify-center p-4 border-2 border-dashed border-indigo-300 rounded-lg bg-white hover:bg-indigo-50 cursor-pointer transition duration-300 ease-in-out group"
          >
            <PlusIcon className="w-6 h-6 mr-2 text-indigo-500 group-hover:text-indigo-600" />
            <span className="text-indigo-700 font-medium group-hover:text-indigo-800">Ajouter des images</span>
          </label>
        </div>
      )}
      <div className="space-y-6">
  <div className="overflow-hidden rounded-xl bg-white shadow-inner">
    <div
      className="flex transition-transform duration-500 ease-in-out"
      style={{ transform: `translateX(-${currentIndex * 100}%)` }}
    >
      {Array(Math.ceil(images?.length / getImagesPerSlide()))
        .fill()
        .map((_, slideIndex) => (
          <div
            key={slideIndex}
            className="w-full flex-shrink-0 flex gap-4 p-4"
          >
            {images
              ?.slice(
                slideIndex * getImagesPerSlide(),
                (slideIndex + 1) * getImagesPerSlide()
              )
              ?.map((image) => (
                <div
                  key={image.id}
                  className={`relative group ${
                    getImagesPerSlide() === 1
                      ? "w-full"
                      : getImagesPerSlide() === 2
                      ? "w-1/2"
                      : getImagesPerSlide() === 3
                      ? "w-1/3"
                      : getImagesPerSlide() === 4
                      ? "w-1/4"
                      : "w-1/5"
                  }`}
                >
                  <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-md transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
                    <img
                      src={image.src}
                      className="object-cover w-full h-full"
                      alt={`galerie ${image.id}`}
                    />
                    {!isSuperAdmin && (
                      <button
                        onClick={() => handleDeleteImage(image)}
                        type="button"
                        className="absolute top-2 right-2 p-2 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:bg-red-600"
                      >
                        <TrashIcon className="w-5 h-5" />
                      </button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ))}
    </div>
  </div>
  {images?.length > getImagesPerSlide() && (
    <div className="flex items-center justify-between">
      <div className="font-bold text-gray-700">
        Image {currentIndex * getImagesPerSlide() + 1} - {Math.min((currentIndex + 1) * getImagesPerSlide(), images.length)} sur {images.length}
      </div>
      <div className="flex gap-3">
        <button
          type="button"
          onClick={prevSlide}
          className={`p-2 rounded-full ${
            currentIndex === 0
              ? "bg-gray-200 text-gray-400 cursor-not-allowed"
              : "bg-white text-indigo-600 hover:bg-indigo-600 hover:text-white shadow-md"
          } transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          disabled={currentIndex === 0}
        >
          <ChevronLeftIcon className="w-6 h-6" />
        </button>
        <button
          type="button"
          onClick={nextSlide}
          className={`p-2 rounded-full ${
            currentIndex === Math.ceil(images.length / getImagesPerSlide()) - 1
              ? "bg-gray-200 text-gray-400 cursor-not-allowed"
              : "bg-white text-indigo-600 hover:bg-indigo-600 hover:text-white shadow-md"
          } transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          disabled={currentIndex === Math.ceil(images.length / getImagesPerSlide()) - 1}
        >
          <ChevronRightIcon className="w-6 h-6" />
        </button>
      </div>
    </div>
  )}
  {images?.length === 0 && (
    <div className="flex flex-col items-center justify-center py-12 text-center text-gray-400">
      <PhotoIcon className="w-12 h-12 mb-4" />
      <p className="text-lg">Aucune image n'a été téléchargée.</p>
    </div>
  )}
</div>

    </div>
  );
};

export default ImageGallery;