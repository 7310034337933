import { Layout } from '../../components'

const Notifications = () => {
  return (
    <Layout>
      <div className="p-1">
        Notifications
      </div>
    </Layout>
  )
}

export default Notifications