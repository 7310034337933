const ServiceTimes = (startTime, services) => {
    let currentStartTime = startTime;
  
    return services?.map((service) => {
      const [startHours, startMinutes] = currentStartTime.split(':')?.map(Number);
      const startDate = new Date();
      startDate.setHours(startHours, startMinutes, 0, 0);
  
      const endDate = new Date(startDate.getTime() + service.pivot.duration * 60000);
  
      const formattedStartTime = startDate.toLocaleTimeString([], { 
        hour: 'numeric', 
        minute: '2-digit', 
        hour12: true 
      });
      const formattedEndTime = endDate.toLocaleTimeString([], { 
        hour: 'numeric', 
        minute: '2-digit', 
        hour12: true 
      });
  
      currentStartTime = `${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}`;
  
      return {
        ...service,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
      };
    });
  };
  
  export default ServiceTimes;