

const NotFound = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <h1 className="text-5xl text-gray-gt font-bold">Not Found</h1>
    </div>
  )
}

export default NotFound