import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/es/integration/react';
import Navigation from './navigation/Navigation';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <Navigation />
        </I18nextProvider>
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);
