import { REMOVE_USER_TOKEN, STROE_USER_TOKEN } from "../constants/actions";

const initialState = {
    user_token: null,
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch(type) {
        case STROE_USER_TOKEN:
            return {
                ...state,
                user_token: payload,
            };
        case REMOVE_USER_TOKEN:
            return {
                ...state,
                user_token: null,
            };
        default:
            return state;
    }
}
