import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CmsLayout from "../cms";
import { FOOTER } from "../../constants/actions";
import { apiClient } from "../../actions/api";
import { FetchData } from "../../actions/data";
import { Check, RefreshCcw } from "lucide-react";
import { TextEditor } from "../../components";
import { PencilIcon, XCircleIcon } from "@heroicons/react/24/solid";

const Footer = () => {
  const footer = useSelector((state) => state?.data?.footer);
  const [formData, setFormData] = useState(null);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (footer) {
      setFormData(footer);
    }
  }, [footer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const ToggleEdit = () => {
    setEdit(!edit);
    setFormData(footer);
  };

  const ResetHandler = () => {
    setFormData(footer);
  };

  const SubmitHandler = async (e) => {
    e.preventDefault();
    try {
      if (JSON.stringify(formData) !== JSON.stringify(footer)) {
        await apiClient().put(`/footer/1`, formData);
        toast.success(
          "Les informations d'footer ont été modifiées avec succès."
        );
        setEdit(false);
        dispatch(FetchData(`/footer`, FOOTER));
      } else {
        toast.info("Aucune modification détectée.");
      }
    } catch (err) {
      toast.error("Échec de la modification des informations d'footer.");
      console.error(err);
    }
  };

  return (
    <CmsLayout>
      <div className="p-5 min-h-screen mx-auto">
        <form onSubmit={SubmitHandler} className="">
        <div className=" my-4 flex items-center justify-start gap-3">
          <button
            type="button"
            onClick={ToggleEdit}
            className={`flex items-center justify-center gap-2 min-w-20 h-8 px-4 font-semibold rounded-md shadow-md transition-colors duration-200 ${
              edit
                ? "bg-gray-400 text-white hover:bg-gray-500"
                : "bg-orange-primary text-white hover:bg-orange-600"
            }`}
          >
            {edit ? (
              <>
                <XCircleIcon className="w-4 h-4" />
                Annuler
              </>
            ) : (
              <>
                <PencilIcon className="w-4 h-4" />
                Modifier
              </>
            )}
          </button>
          </div>
          <div className="space-y-4 p-2 border rounded-lg bg-white">
            {edit && (
              <div className="flex items-center justify-end">
                <div className="flex items-center gap-3">
                  <button
                    type="button"
                    onClick={ResetHandler}
                    className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-gray-500 hover:bg-gray-600"
                  >
                    <RefreshCcw className="w-4 h-4 md:h-6 md:w-6" />
                  </button>
                  <button
                    type="submit"
                    className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-blue-900 hover:bg-blue-950"
                  >
                    <Check className="w-4 h-4 md:h-6 md:w-6" />
                  </button>
                </div>
              </div>
            )}
            <div className="text-xs md:text-sm lg:text-base">
              <div className="p-2 space-y-4">
                <div className="flex flex-col gap-2">
                  <label htmlFor="descriptionLogo">Description</label>
                  <div className="min-h-fit h-fit bg-white overflow-hidden border-b">
                    <TextEditor
                      texteditor={formData?.descriptionLogo}
                      setTexteditor={(value) =>
                        handleChange({
                          target: { name: "descriptionLogo", value },
                        })
                      }
                      disabled={!edit}
                      style={!edit && "opacity-70"}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  <div className="flex flex-col gap-2">
                    <label htmlFor="titlePhone">Titre Téléphone</label>
                    <input
                      type="text"
                      name="titlePhone"
                      id="titlePhone"
                      placeholder="Titre section 1"
                      value={formData?.titlePhone || ""}
                      onChange={handleChange}
                      className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                        !edit && "opacity-70"
                      }`}
                      readOnly={!edit}
                      required={true}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor="phone">Téléphone</label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Titre section 2"
                      value={formData?.phone || ""}
                      onChange={handleChange}
                      className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                        !edit && "opacity-70"
                      }`}
                      readOnly={!edit}
                      required={true}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor="titleEmail">Titre email</label>
                    <input
                      type="text"
                      name="titleEmail"
                      id="titleEmail"
                      placeholder="Titre section 3"
                      value={formData?.titleEmail || ""}
                      onChange={handleChange}
                      className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                        !edit && "opacity-70"
                      }`}
                      readOnly={!edit}
                      required={true}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Titre section 4"
                      value={formData?.email || ""}
                      onChange={handleChange}
                      className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                        !edit && "opacity-70"
                      }`}
                      readOnly={!edit}
                      required={true}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="titleReduction">Titre</label>
                  <input
                    type="text"
                    name="titleReduction"
                    id="titleReduction"
                    placeholder="Titre section 5"
                    value={formData?.titleReduction || ""}
                    onChange={handleChange}
                    className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                      !edit && "opacity-70"
                    }`}
                    readOnly={!edit}
                    required={true}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="descriptionReduction">Description</label>
                  <div className="min-h-fit h-fit bg-white overflow-hidden border-b">
                    <TextEditor
                      texteditor={formData?.descriptionReduction}
                      setTexteditor={(value) =>
                        handleChange({
                          target: { name: "descriptionReduction", value },
                        })
                      }
                      disabled={!edit}
                      style={!edit && "opacity-70"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </CmsLayout>
  );
};

export default Footer;
