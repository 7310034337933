import React, { useEffect, useState } from "react";
import { ComeBackButton, ParametresLayout, ServiceDetailsCpt } from "../../components";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";

const ServiceDetails = () => {
  const { id } = useParams();
  const services = useSelector((state) => state?.data?.services) || [];
  const [service, setService] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isNaN(id)) {
      navigate("/services");
      return;
    }
    const clt = services?.find((client) => client?.id === parseInt(id));
    if (services?.length > 0 && !clt) {
      navigate("/services");
      return;
    }
    setService(clt);
  }, [id, services]);

  return (
    <ParametresLayout>
      <div className="px-1 py-5 min-h-screen">
        <ComeBackButton prev={location?.state?.prevPath} next={"/parametres/services"} />
        <ServiceDetailsCpt service={service} />
      </div>
    </ParametresLayout>
  );
};

export default ServiceDetails;
