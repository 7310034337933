import { TOGGLE_LOADING, BASE_URL, BASE_URL_PROD } from "../constants/actions";
import {store} from "../store";
import axios from "axios";

export const apiClient = () => {
    const options = {
        baseURL: BASE_URL,
        responseType: 'json',
        headers: {
            Accept: "application/json",
            'Access-Control-Allow-Origin':"*/*"
        },
    }
    const instance = axios.create(options)

    instance.interceptors.request.use((request) => {
        // store.dispatch({
        //     type: TOGGLE_LOADING,
        //     payload: true
        // })
        return request
    })

    instance.interceptors.response.use(
        (response) => {
            // store.dispatch({
            //     type: TOGGLE_LOADING,
            //     payload: false
            // })
            return response
        },
        (error) => {
            console.error("error", error.response?.data)
            // store.dispatch({
            //     type: TOGGLE_LOADING,
            //     payload: false
            // })
            // alert(error.response?.data?.message)
            return Promise.reject(error);
        },
    )

    return instance
}