import { Check, ChevronLeft, ChevronRight, RefreshCcw } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextEditor from "../globalcomponents/TextEditor";
import { BASE_IMAGE_URL, FEEDBACKS } from "../../constants/actions";
import { apiClient } from "../../actions/api";
import { FetchData } from "../../actions/data";
import uploadMultipleImage from '../../helpers/uploadMultipleImages';

const Feedbacks = ({
  formData,
  handleChange,
  SubmitHandler,
  ResetHandler,
  edit,
}) => {
  const feedbacks = useSelector((state) => state?.data?.feedbacks) || [];
  const [feedbacksData, setFeedbackData] = useState(feedbacks);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const [selectedImages, setSelectedImages] = useState({});

  useEffect(() => {
    if (feedbacks) {
      setFeedbackData(feedbacks);
    }
  }, [feedbacks]);

  useEffect(() => {
    const images = {};
    feedbacks?.forEach((item) => {
      images[item?.id] = item?.avatar
        ? BASE_IMAGE_URL + item?.avatar
        : URL.createObjectURL(item?.avatar);
    });
    setSelectedImages(images);
  }, [feedbacks]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getFeedbackSlide = useCallback(() => {
    // if (windowWidth >= 1280) return 4;
    // if (windowWidth >= 1024) return 3;
    if (windowWidth >= 768) return 2;
    return 1;
  }, [windowWidth]);

  const SubmitForm = async (e) => {
    try {
      let isChanged = false;
      const changedFeedbacks = feedbacksData?.filter((feedback) => {
        if (
          JSON.stringify(feedback) !==
          JSON.stringify(feedbacks?.find((feed) => feed?.id === feedback?.id))
        ) {
          isChanged = true;
          return feedback;
        }
      });

      SubmitHandler(e, ["titleFeedbacks"], isChanged);

      if(isChanged){
        for (const feedback of changedFeedbacks) {
          const oldFeedback = feedbacks?.find(feed=>feed?.id === feedback?.id);
          let result = null;
          if(oldFeedback?.avatar != feedback?.avatar){
            result = await uploadMultipleImage(
              "avatar", {"avatar": feedback?.avatar}, {"avatar": oldFeedback?.avatar}, "feddbacksAvatars"
            )
          }
          await apiClient().put(`/feedbacks/${feedback?.id}`, {
            avatar: result?.path || feedback?.avatar,
            name: feedback?.name,
            description: feedback?.description,
          });
        }
  
        dispatch(FetchData("/feedbacks", FEEDBACKS));
      }

    } catch (err) {
      console.error(err);
    }
  };

  const ResetFunc = () => {
    ResetHandler(["titleFeedbacks"]);
    setFeedbackData(feedbacks);
  };

  const handleFeedbackChange = useCallback(
    (id, field, value) => {
      setFeedbackData((prevFeedbackData) =>
        prevFeedbackData?.map((item) =>
          item.id === id ? { ...item, [field]: value } : item
        )
      );
    },
    [setFeedbackData]
  );

  useEffect(() => {
    setFeedbackData(feedbacks);
  }, [edit]);

  const nextSlide = () => {
    const imagesPerSlide = getFeedbackSlide();
    const maxIndex = Math.ceil(feedbacks?.length / imagesPerSlide) - 1;
    if (currentIndex < maxIndex) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleFileChange = (e, id) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImages((prev) => ({ ...prev, [id]: imageUrl }));
      setFeedbackData((prevData) =>
        prevData?.map((item) =>
          item.id === id ? { ...item, avatar: file } : item
        )
      );
    }
  };

  return (
    <form onSubmit={SubmitForm}>
      <div className="flex items-center justify-between">
        <h1 className="text-lg md:text-xl lg:text-2xl font-semibold my-2">
          Section 3
        </h1>
      </div>
      <div className="space-y-4 p-2 border rounded-lg bg-white">
        {edit && (
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              onClick={ResetFunc}
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-gray-500 hover:bg-gray-600"
            >
              <RefreshCcw className="w-4 h-4 md:h-6 md:w-6" />
            </button>
            <button
              type="submit"
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-blue-900 hover:bg-blue-950"
            >
              <Check className="w-4 h-4 md:h-6 md:w-6" />
            </button>
          </div>
        )}
        <div className="">
          <div className="space-y-2">
            <div className="flex flex-col gap-2">
              <label htmlFor="titleFeedbacks">Titre section 3</label>
              <input
                type="text"
                name="titleFeedbacks"
                id="titleFeedbacks"
                placeholder="Titre section 2"
                value={formData?.titleFeedbacks || ""}
                onChange={handleChange}
                className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                  !edit && "opacity-70"
                }`}
                readOnly={!edit}
                required={true}
              />
            </div>
            <div className="overflow-hidden">
              <div
                className="flex transition-transform duration-300 ease-in-out"
                style={{
                  transform: `translateX(-${currentIndex * 100}%)`,
                }}
              >
                {Array.from({
                  length: Math.ceil(feedbacks?.length / getFeedbackSlide()),
                }).map((_, slideIndex) => (
                  <div
                    key={`slide-${slideIndex}`}
                    className="w-full flex-shrink-0 flex gap-2"
                  >
                    {feedbacksData
                      ?.slice(
                        slideIndex * getFeedbackSlide(),
                        (slideIndex + 1) * getFeedbackSlide()
                      )
                      ?.map((item) => (
                        <div
                          key={item.id}
                          className={`px-1 relative space-y-2 ${
                            getFeedbackSlide() === 1
                              ? "w-full"
                              : getFeedbackSlide() === 2
                              ? "w-1/2"
                              : getFeedbackSlide() === 3
                              ? "w-1/3"
                              : "w-1/4"
                          }`}
                        >
                          <div className="flex flex-col gap-1">
                            <div className="relative">
                              <div className="overflow-hidden w-44 h-44 rounded-full mx-auto my-3">
                                <img
                                  src={selectedImages[item?.id]}
                                  alt={item?.name}
                                  className="w-full h-full object-cover"
                                />
                              </div>
                              {edit && (
                                <label htmlFor={`file${item?.id}`}>
                                  <div className="flex items-center justify-center h-10 bg-slate-200 rounded-md cursor-pointer">
                                    Change
                                  </div>
                                </label>
                              )}
                              <input
                                type="file"
                                id={`file${item?.id}`}
                                name={`file${item?.id}`}
                                accept="image/*"
                                className="sr-only"
                                onChange={(e) => handleFileChange(e, item?.id)}
                                disabled={!edit}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col gap-1">
                            <label htmlFor={`name${item?.id}`}>
                              Nom d'invité
                            </label>
                            <input
                              type="text"
                              name={`name${item?.id}`}
                              id={`name${item?.id}`}
                              placeholder="Nom"
                              value={item?.name || ""}
                              onChange={(e) =>
                                handleFeedbackChange(
                                  item?.id,
                                  "name",
                                  e.target.value
                                )
                              }
                              className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                                !edit && "opacity-70"
                              }`}
                              readOnly={!edit}
                              required={true}
                            />
                          </div>
                          <div className="flex flex-col gap-1">
                            <label htmlFor={`desc${item?.id}`}>
                              Description
                            </label>
                            <div className="min-h-fit h-fit bg-white overflow-hidden border-b">
                              <TextEditor
                                texteditor={item?.description || ""}
                                setTexteditor={(value) =>
                                  handleFeedbackChange(
                                    item?.id,
                                    `description`,
                                    value
                                  )
                                }
                                disabled={!edit}
                                style={!edit ? "opacity-70" : ""}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
            {feedbacks?.length > 0 && (
              <div className="flex items-center justify-end gap-4">
                <div className="font-bold">
                  Slide {currentIndex + 1} /{" "}
                  {Math.max(
                    1,
                    Math.ceil(feedbacks?.length / getFeedbackSlide())
                  )}
                </div>
                <div className="flex gap-3">
                  <button
                    type="button"
                    onClick={prevSlide}
                    className={`p-3 rounded-full overflow-hidden ${
                      currentIndex === 0
                        ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                        : "bg-gray-300 text-gray-800 hover:bg-gray-800 hover:text-white"
                    }`}
                    disabled={currentIndex === 0}
                  >
                    <ChevronLeft className="w-5 h-5 md:h-7 md:w-7" />
                  </button>
                  <button
                    type="button"
                    onClick={nextSlide}
                    className={`p-3 rounded-full overflow-hidden ${
                      currentIndex ===
                      Math.ceil(feedbacks?.length / getFeedbackSlide()) - 1
                        ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                        : "bg-gray-300 text-gray-800 hover:bg-gray-800 hover:text-white"
                    }`}
                    disabled={
                      currentIndex ===
                      Math.ceil(feedbacks?.length / getFeedbackSlide()) - 1
                    }
                  >
                    <ChevronRight className="w-5 h-5 md:h-7 md:w-7" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Feedbacks;
