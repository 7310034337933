import React from 'react';
import { ProfileImg } from "../../assets/images";
import { BASE_IMAGE_URL } from "../../constants/actions";
import formatDate2 from "../../helpers/formatDate2";
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

const InfoItem = ({ label, value }) => (
  <div className="space-y-1">
    <p className="text-gray-500 text-xs sm:text-sm">{label}</p>
    <h4 className="text-gray-800 font-semibold text-sm sm:text-base">
      {value || <span className="text-gray-400 italic">Non spécifié</span>}
    </h4>
  </div>
);

const SalonInfoItem = ({ label, value }) => (
  <div className="space-y-1">
    <p className="text-gray-500 text-xs sm:text-sm">{label}</p>
    <h4 className="text-gray-800 font-semibold text-sm sm:text-base">
      {value || <span className="text-gray-400 italic">Non spécifié</span>}
    </h4>
  </div>
);

const calculateAge = (birthDate) => {
  if (!birthDate) return null;
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
    age--;
  }
  return age;
};

const ActionButton = ({ onClick, color, hoverColor, icon: Icon, label }) => (
  <button 
    onClick={onClick}
    className={`group flex items-center space-x-2 py-2 px-4 ${color} text-white font-medium rounded-lg hover:${hoverColor} transition-all duration-200 transform hover:scale-105`}
  >
    <Icon className="w-5 h-5 group-hover:animate-pulse" />
    <span>{label}</span>
  </button>
);

const DemandeDetailsInfos = ({ demande, handleDecision }) => {
  const age = calculateAge(demande?.birthday);
  return (
    <div className="space-y-4">
      <div className="p-6 rounded-lg bg-white shadow-md border border-gray-200">
        <h3 className="text-lg font-semibold text-gray-700 mb-4">Actions sur la demande</h3>
        <div className="flex flex-col sm:flex-row justify-end items-stretch sm:items-center gap-3">
          <ActionButton
            onClick={() => handleDecision(true)}
            color="bg-green-500"
            hoverColor="bg-green-600"
            icon={CheckCircleIcon}
            label="Accepter la demande"
          />
          <ActionButton
            onClick={() => handleDecision(false)}
            color="bg-red-500"
            hoverColor="bg-red-600"
            icon={XCircleIcon}
            label="Refuser la demande"
          />
        </div>
      </div>
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-bold text-gray-800 mb-4">Informations du Client</h2>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <InfoItem label="Prénom" value={demande?.first_name} />
          <InfoItem label="Nom" value={demande?.last_name} />
          <InfoItem label="Âge" value={age ? `${age} ans` : null} />
          <InfoItem label="Email" value={demande?.email} />
          <InfoItem label="Téléphone" value={demande?.phone} />
          <InfoItem label="Status" value={demande?.status ? 'Accepté' : 'En cours de traitement'} />
          <InfoItem label="Date de création" value={demande?.created_at && formatDate2(demande.created_at)} />
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-lg sm:text-xl text-gray-800 font-bold mb-4">
          Informations du Salon
        </h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <SalonInfoItem label="Nom" value={demande?.salon?.name} />
          <SalonInfoItem label="Email" value={demande?.salon?.email} />
          <SalonInfoItem label="Téléphone" value={demande?.salon?.phone_number} />
          {/* <SalonInfoItem label="Status" value={demande?.salon?.active ? 'En service' : 'Hors service'} /> */}
          <SalonInfoItem label="Adresse" value={demande?.salon?.address} />
          <SalonInfoItem label="Ville" value={demande?.salon?.city} />
        </div>
      </div>
    </div>
  );
};

export default DemandeDetailsInfos;