import { ParametresLayout } from "../../components";
import { useEffect, useState } from "react";
import FilterBtn from "../../components/globalcomponents/FilterBtn";
import SortByBtn from "../../components/globalcomponents/SortByBtn";
import { useDispatch, useSelector } from "react-redux";
import { ProfileImg } from "../../assets/images";
import formatDate2 from "../../helpers/formatDate2";
import { useLocation, useNavigate } from "react-router";
import { BASE_IMAGE_URL, EMPLOYEES } from "../../constants/actions";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../context/authContext";
import {
  MagnifyingGlassIcon,
  UserPlusIcon,
  PencilSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { apiClient } from "../../actions/api";
import { FetchData } from "../../actions/data";

const sortOptions = ["Date création", "Nom"];

const itemsPerPage = 10;

const Employes = () => {
  const [filterOptions, setFilterOptions] = useState(["Tout"]);
  const employees = useSelector((state) => state?.data?.employees) || [];
  const [filtredEmployes, setFiltredEmployes] = useState(employees || []);
  const [searchInput, setSearchInput] = useState("");
  const [sortOption, setSortOption] = useState("Date création");
  const [filterOption, setFilterOption] = useState("Tout");
  const navigate = useNavigate();
  const location = useLocation();
  const { isSuperAdmin } = useAuth();
  const user = useSelector((state) => state?.auth?.user);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);

  useEffect(() => {
    if (!searchInput) {
      setFiltredEmployes(employees);
      return;
    }
    const searchLowerCase = searchInput?.toLowerCase();

    const filtered = employees?.filter((employee) => {
      const fullName =
        `${employee?.first_name} ${employee?.last_name}`.toLowerCase();
      const reversedFullName =
        `${employee?.last_name} ${employee?.first_name}`.toLowerCase();
      const firstName = employee?.first_name.toLowerCase();
      const lastName = employee?.last_name.toLowerCase();
      const email = employee?.email.toLowerCase();

      return (
        firstName.includes(searchLowerCase) ||
        lastName.includes(searchLowerCase) ||
        fullName.includes(searchLowerCase) ||
        reversedFullName.includes(searchLowerCase) ||
        email.includes(searchLowerCase)
      );
    });
    setFiltredEmployes(filtered);
  }, [searchInput, employees]);

  useEffect(() => {
    let filter = [];
    if (filterOption === "Tout") {
      filter = employees;
    }
    if (sortOption === "Date création") {
      const sortedByDate = [...filter]?.sort(
        (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
      );
      filter = sortedByDate;
    } else if (sortOption === "Nom") {
      const sortedByName = [...filter]?.sort((a, b) => {
        const firstNameComparison = a.first_name.localeCompare(b.first_name);

        if (firstNameComparison === 0) {
          return a.last_name.localeCompare(b.last_name);
        }
        return firstNameComparison;
      });
      filter = sortedByName;
    } else {
      return false;
    }
    setFiltredEmployes(filter);
  }, [filterOption, sortOption, employees]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filtredEmployes]);

  const totalPages = Math.ceil(filtredEmployes?.length / itemsPerPage);
  const maxVisibleButtons = 6;

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    setVisiblePages(
      Array.from({ length: end - start + 1 }, (_, i) => start + i)
    );
  };

  useEffect(() => {
    updateVisiblePages(currentPage);
  }, [currentPage, totalPages]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentEmployees = filtredEmployes?.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  useEffect(() => {
    // Extract unique services from the employees' data
    const services = employees.reduce((acc, employee) => {
      if (employee?.services) {
        employee.services.forEach((service) => {
          if (!acc.includes(service.name)) {
            acc.push(service.name);
          }
        });
      }
      return acc;
    }, []);

    // Update filter options to include services and "Tout" option
    setFilterOptions(["Tout", ...services]);
  }, [employees]);

  useEffect(() => {
    let filter = employees;

    // Apply service filtering
    if (filterOption !== "Tout") {
      filter = filter.filter((employee) =>
        employee?.services?.some((service) => service.name === filterOption)
      );
    }

    // Apply sorting logic
    if (sortOption === "Date création") {
      filter = filter.sort(
        (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
      );
    } else if (sortOption === "Nom") {
      filter = filter.sort((a, b) => {
        const firstNameComparison = a.first_name.localeCompare(b.first_name);
        return firstNameComparison === 0
          ? a.last_name.localeCompare(b.last_name)
          : firstNameComparison;
      });
    }

    setFiltredEmployes(filter);
  }, [filterOption, sortOption, employees]);

  const handleDelete = (employeId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce employé ?")) {
      apiClient()
        .delete(`/employees/${employeId}`)
        .then((res) => {
          toast.success("Employé supprimé avec succès !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            toastId: "delete-success",
          });
          dispatch(FetchData(`/salonEmployees/${user?.id}`, EMPLOYEES));
        })
        .catch((error) => {
          toast.error("Erreur lors de la suppression du service.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            toastId: "delete-error",
          });
        });
    }
  };

  return (
    <ParametresLayout>
      <div className="p-4 sm:p-6 min-h-screen space-y-6 sm:space-y-8 bg-gray-50">
        <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center sm:justify-between">
          <div className="w-full sm:w-1/3">
            <div className="relative">
              <input
                type="text"
                placeholder="Rechercher par nom ou email"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="w-full py-2 pl-10 pr-4 border border-gray-300 rounded-full focus:ring-2 focus:ring-orange-500 focus:border-transparent"
              />
              <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
          </div>
          <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4">
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="py-2 px-4 border border-gray-300 rounded-full focus:ring-2 focus:ring-orange-500 focus:border-transparent"
            >
              {sortOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={filterOption}
              onChange={(e) => setFilterOption(e.target.value)}
              className="py-2 px-4 border border-gray-300 rounded-full focus:ring-2 focus:ring-orange-500 focus:border-transparent"
            >
              {filterOptions?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            {!isSuperAdmin && (
              <button
                onClick={() =>
                  navigate("/parametres/addEmployee", {
                    state: { prevPath: location?.pathname },
                  })
                }
                className="flex items-center justify-center py-2 px-4 bg-orange-600 text-white rounded-full hover:bg-orange-700 transition duration-300"
              >
                <UserPlusIcon className="h-5 w-5 mr-2" />
                Ajouter
              </button>
            )}
          </div>
        </div>

        <div className="bg-white shadow-md rounded-lg overflow-x-auto">
          <table className="w-full min-w-max">
            <thead>
              <tr className="bg-gray-100 text-left text-gray-600 text-sm">
                <th className="py-3 px-4">Avatar</th>
                <th className="py-3 px-4">Nom</th>
                <th className="py-3 px-4">Email</th>
                <th className="py-3 px-4">Services</th>
                <th className="py-3 px-4">Date de création</th>
                <th className="py-3 px-4 text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {employees?.length ? (
                currentEmployees.map((employe) => (
                  <tr
                    key={employe?.id}
                    className="border-b border-gray-200 hover:bg-gray-50"
                  >
                    <td className="py-3 px-4">
                      {employe?.avatar ? (
                        <img
                          src={BASE_IMAGE_URL + employe?.avatar}
                          alt="avatar"
                          className="w-10 h-10 rounded-full object-cover"
                        />
                      ) : (
                        <UserIcon className="w-8 h-8 pt-1 text-black-400 rounded-full object-cover border border-black" />
                      )}
                    </td>
                    <td className="py-3 px-4 font-medium">{`${employe?.first_name} ${employe?.last_name}`}</td>
                    <td className="py-3 px-4 font-medium">{`${employe?.email}`}</td>
                    <td className="py-3 px-4">
                      {employe?.services?.length > 0 ? (
                        employe?.services
                          ?.map((service) => service.name)
                          .join(", ")
                      ) : (
                        <span className="text-orange-700 text-sm italic">
                          "Aucun service"
                        </span>
                      )}
                    </td>
                    <td className="py-3 px-4">
                      {employe?.created_at && formatDate2(employe?.created_at)}
                    </td>
                    <td className="py-3 px-4 text-center flex justify-center gap-4">
                      <button
                        onClick={() =>
                          navigate(`/parametres/employees/${employe?.id}`)
                        }
                        className="text-green-500 hover:text-green-700 text-lg"
                      >
                        <i className="bi bi-pencil-square"></i>
                      </button>
                      <button
                        onClick={() => handleDelete(employe?.id)} // Function to handle the delete
                        className="text-red-500 hover:text-red-700 text-lg"
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="py-4 text-center text-gray-500">
                    Aucun employé trouvé
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {employees?.length > itemsPerPage && (
          <div className="flex flex-col space-y-4 flex-row space-y-0 items-center justify-between text-sm">
            <nav className="flex items-center justify-center sm:justify-end space-x-2">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="p-2 rounded-full bg-gray-200 text-gray-600 disabled:opacity-50"
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
              {visiblePages.map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  className={`w-8 h-8 rounded-full ${
                    currentPage === page
                      ? "bg-orange-600 text-white"
                      : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                  }`}
                >
                  {page}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="p-2 rounded-full bg-gray-200 text-gray-600 disabled:opacity-50"
              >
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            </nav>
          </div>
        )}
      </div>
      <ToastContainer />
    </ParametresLayout>
  );
};

export default Employes;
