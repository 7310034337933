import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_IMAGE_URL } from "../../../constants/actions";
import { ProfileImg } from "../../../assets/images";
import formatDate2 from "../../../helpers/formatDate2";

// Heroicons imports
import { ArrowRightIcon, UserIcon } from "@heroicons/react/24/solid";
import { CoinsIcon } from "lucide-react";

const ReservationsDashboard = ({ reservations }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="border rounded-lg bg-white py-3 space-y-6 min-h-[350px]">
      <div className="flex items-center justify-between px-5">
        <h2 className="text-lg sm:text-xl text-gray-gt font-bold">
          Nouvelles réservations
        </h2>
        <button
          onClick={() => navigate('/reservations')}
          className="flex items-center text-orange-primary font-medium text-sm sm:text-base"
        >
          Voir toutes <ArrowRightIcon className="w-5 h-5 ml-1" />
        </button>
      </div>
      <div className="space-y-0">
        {/* items */}
        {reservations?.length
          ? reservations?.slice(0, 8)?.map((res) => (
              <div
                key={res.id}
                onClick={() =>
                  navigate(`/reservations/${res.id}`, {
                    state: { prevPath: location?.pathname },
                  })
                }
                className="flex items-center gap-x-3 cursor-pointer hover:bg-blue-50 px-5 py-1"
              >
                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full bg-gray-200 overflow-hidden">
                  {res?.user?.avatar ? (
                    <img
                      src={BASE_IMAGE_URL + res?.user?.avatar}
                      alt="img"
                      className="object-cover w-full h-full"
                    />
                  ) : (
                    <UserIcon className="w-10 h-10 mt-2 text-gray-400" />
                  )}
                </div>
                <div className="flex items-center justify-between w-full">
                  <div>
                    <h3 className="text-sm sm:text-base font-bold text-gray-gt">
                      {res?.user?.first_name
                        ? `${res?.user?.first_name} ${res?.user?.last_name}`
                        : res?.guest?.name}
                    </h3>
                    <p className="text-xs sm:text-sm text-gray-pt">
                      {res?.services?.map((service) => service.name).join(", ")}
                    </p>
                  </div>
                  <div className="flex flex-col items-end gap-1">
                    <h3 className="flex items-center text-sm sm:text-base font-bold text-gray-gt">
                      <CoinsIcon className="w-5 h-5 mr-1" />
                      {res?.payment?.amount} Dhs
                    </h3>
                    <time className="text-xs sm:text-sm text-gray-pt">
                      {res?.availability?.date &&
                        formatDate2(
                          `${res?.availability?.date}T${res?.availability?.start_time}`
                        )}
                    </time>
                  </div>
                </div>
              </div>
            ))
          : [0, 1, 2, 3, 4]?.map((_, index) => (
              <div
                key={index}
                className="flex items-center gap-x-3 cursor-pointer hover:bg-blue-50 px-5 py-1 animate-pulse"
              >
                <div className="w-11 h-11 min-w-11 min-h-11 rounded-full bg-gray-300" />
                <div className="flex items-center justify-between w-full">
                  <div className="flex flex-col gap-y-2">
                    <div className="w-24 h-4 bg-gray-300 rounded" />
                    <div className="w-20 h-3 bg-gray-300 rounded" />
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <div className="w-16 h-4 bg-gray-300 rounded" />
                    <div className="w-24 h-3 bg-gray-300 rounded" />
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default ReservationsDashboard;
