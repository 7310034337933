import ParametresLayout from '../../components/parametresComponents/ParametresLayout'
import { useSelector } from 'react-redux';
import { ListeRemarques } from '../../components';
import { useEffect, useState } from 'react';

const Remarques = () => {
  const notes = useSelector(state => state?.data?.notes);
  const [filtredRemarques, setFiltredRemarques] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (!searchInput) {
      setFiltredRemarques(notes);
      return;
    }

    const searchLowerCase = searchInput.trim().toLowerCase();

    const filtered = notes?.filter((client) => {
      const fullName = `${client?.user?.first_name} ${client?.user?.last_name} ${client?.guest?.name}`.toLowerCase();
      const reversedFullName = `${client?.user?.last_name} ${client?.user?.first_name} ${client?.guest?.name}`.toLowerCase();
      const email = client?.user?.email.toLowerCase();

      return (
        fullName?.includes(searchLowerCase) ||
        reversedFullName?.includes(searchLowerCase) ||
        email?.includes(searchLowerCase)
      );
    });

    setFiltredRemarques(filtered);
  }, [searchInput, notes]);

  return (
    <ParametresLayout>
        <div className="px-1 py-5 min-h-screen space-y-5">
          <div className="flex justify-center">
            <div className="min-w-72">
              <input
                type="text"
                name="nom"
                id="nom"
                placeholder="Nom, Prénom, Email"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="w-full py-2 px-3 border rounded-full outline-none focus:outline-none"
              />
            </div>
          </div>
          <ListeRemarques notes={filtredRemarques}/>
        </div>
    </ParametresLayout>
  )
}

export default Remarques