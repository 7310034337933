import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { Loader } from "lucide-react";
import {
  PencilIcon,
  CheckIcon,
  XMarkIcon,
  ArrowUpOnSquareIcon,
  TrashIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/solid";
import { useAuth } from "../../context/authContext";
import { FetchData } from "../../actions/data";
import { BASE_IMAGE_URL, SALON, SALONS } from "../../constants/actions";
import { apiClient } from "../../actions/api";
import FormattingDate from "../../helpers/FormattingDate";
import uploadImage from "../../helpers/uploadImages";
import ImageGallery from "./ImageGallery";
import "react-toastify/dist/ReactToastify.css";
import TextEditor from "../globalcomponents/TextEditor";
import "./style.css"

const SalonProfile = ({ salon }) => {
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState(salon);
  const dispatch = useDispatch();
  const { isSuperAdmin } = useAuth();
  const albumInputRef = useRef(null);
  const [LoadingUpload, setLoadingUpload] = useState(false);
  const [selectedImage, setSelectedImage] = useState(
    formData?.image ? BASE_IMAGE_URL + formData?.image : null
  );
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (salon) {
      setFormData(salon);
      setSelectedImage(salon?.image ? BASE_IMAGE_URL + salon?.image : null);
    }
  }, [salon]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const ToggleEdit = () => {
    setEdit(!edit);
    setFormData(salon);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingUpload(true);
    try {
      let newPath = salon?.image;
      if (formData?.image !== salon?.image) {
        newPath = await uploadImage(
          "image",
          { image: formData?.image },
          { image: salon?.image },
          "salons"
        );
      }
      const data = { ...formData, image: newPath };
      const response = await apiClient().put(`/salons/${salon?.id}`, data);
      if (response?.status === 200) {
        toast.success("Salon information updated successfully.");
        if (isSuperAdmin) {
          dispatch(FetchData(`/salons`, SALONS));
        } else {
          dispatch(FetchData(`/salons/${salon?.id}`, SALON));
        }
        setEdit(false);
        if (albumInputRef.current) {
          albumInputRef.current.value = "";
        }
      }
    } catch (err) {
      toast.error("Échec de la mise à jour des informations du salon")
      console.error(err);
    } finally {
      setLoadingUpload(false);
    }
  };

  const handleChangeAvatar = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      handleChange({ target: { name: "image", value: file } });
    }
  };

  const handleTimeChange = (time, name) => {
    const formattedTime = time ? dayjs(time).format("HH:mm") : null;
    setFormData((prevState) => ({ ...prevState, [name]: formattedTime }));
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    handleChange({ target: { name: "image", value: null } });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className="space-y-8 p-6 bg-gray-50 rounded-lg shadow-md">
      {LoadingUpload && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Loader className="w-12 h-12 animate-spin text-white" />
        </div>
      )}
      <div className="flex items-center justify-between">
        <div className="text-gray-600 font-medium">
          {formData?.created_at &&
            `Created on ${FormattingDate(formData?.created_at)}`}
        </div>
        {!isSuperAdmin && (
          <button
            onClick={ToggleEdit}
            className={`min-w-24 h-10 flex items-center justify-center gap-2 font-semibold text-sm ${
              edit
                ? "bg-gray-400 hover:bg-gray-500"
                : "bg-orange-500 hover:bg-orange-600"
            } text-white px-4 py-2 rounded-md shadow-md transition duration-300 ease-in-out`}
          >
            {edit ? (
              <XMarkIcon className="w-5 h-5" />
            ) : (
              <PencilIcon className="w-5 h-5" />
            )}
            {edit ? "Annuler" : "Modifier"}
          </button>
        )}
      </div>
      <form
        className="grid grid-cols-1 md:grid-cols-2 gap-6"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-4">
          <label htmlFor="img" className="text-lg font-semibold text-gray-700">
            Image du salon
          </label>
          <div className="bg-white h-80 rounded-lg overflow-hidden shadow-md relative group">
            <input
              type="file"
              id="img"
              onChange={handleChangeAvatar}
              className="sr-only"
              ref={fileInputRef}
              disabled={!edit}
            />
            <label
              htmlFor="img"
              className={`${edit && "cursor-pointer"} block h-full`}
            >
              {selectedImage ? (
                <>
                  <img
                    src={selectedImage}
                    alt="Salon"
                    className="object-cover w-full h-full transition-opacity duration-300 group-hover:opacity-75"
                  />
                  {edit && (
                    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <div className="bg-black bg-opacity-50 p-2 rounded-full">
                        <ArrowUpOnSquareIcon className="w-8 h-8 text-white" />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="border-2 border-gray-300 border-dashed rounded-lg h-full flex flex-col justify-center items-center bg-gray-50">
                  <ArrowUpOnSquareIcon className="w-12 h-12 text-gray-400" />
                  <p className="mt-2 text-sm text-gray-500">
                    Cliquez pour télécharger une image
                  </p>
                </div>
              )}
            </label>
            {selectedImage && edit && (
              <button
                type="button"
                onClick={handleRemoveImage}
                className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition duration-300 ease-in-out"
              >
                <TrashIcon className="w-5 h-5" />
              </button>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <label htmlFor="name" className="text-sm font-medium text-gray-700">
              Nom du Salon
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData?.name || ""}
              onChange={handleChange}
              className={`w-full p-3 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${
                !edit && "bg-gray-100"
              }`}
              readOnly={!edit}
              required={true}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              htmlFor="email"
              className="text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData?.email || ""}
              onChange={handleChange}
              className={`w-full p-3 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${
                !edit && "bg-gray-100"
              }`}
              readOnly={!edit}
              required={true}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              htmlFor="phone_number"
              className="text-sm font-medium text-gray-700"
            >
              Numéro de Téléphone
            </label>
            <input
              type="text"
              name="phone_number"
              id="phone_number"
              value={formData?.phone_number || ""}
              onChange={handleChange}
              className={`w-full p-3 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${
                !edit && "bg-gray-100"
              }`}
              readOnly={!edit}
            />
          </div>
          
          <div className="flex flex-col gap-4">
            <label className="text-sm font-medium text-gray-700">
              Service Status
            </label>
            <div className="flex items-center gap-6">
              <div className="flex items-center gap-3">
                <input
                  type="radio"
                  name="active"
                  id="en"
                  value={1}
                  checked={parseInt(formData?.active) === 1}
                  disabled={!edit}
                  onChange={handleChange}
                  className="w-4 h-4 text-orange-500 focus:ring-orange-500"
                />
                <label htmlFor="en" className="text-sm text-gray-700">
                  Active
                </label>
              </div>
              <div className="flex items-center gap-3">
                <input
                  type="radio"
                  name="active"
                  id="hors"
                  value={0}
                  checked={parseInt(formData?.active) === 0}
                  disabled={!edit}
                  onChange={handleChange}
                  className="w-4 h-4 text-orange-500 focus:ring-orange-500"
                />
                <label htmlFor="hors" className="text-sm text-gray-700">
                  Inactive
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="description-salon  flex flex-col gap-2 md:col-span-2">
          <label htmlFor="description" className="text-sm font-medium text-gray-700">Description</label>
          <TextEditor
            texteditor={formData?.description}
            setTexteditor={(value) =>
              handleChange({
                target: { name: "description", value },
              })
            }
            disabled={!edit}
          /> 
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="city" className="text-sm font-medium text-gray-700">
            Ville
          </label>
          <input
            type="text"
            name="city"
            id="city"
            value={formData?.city || ""}
            onChange={handleChange}
            className={`w-full p-3 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${
              !edit && "bg-gray-100"
            }`}
            readOnly={!edit}
            required={true}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="address"
            className="text-sm font-medium text-gray-700"
          >
            Adresse
          </label>
          <input
            type="text"
            name="address"
            id="address"
            value={formData?.address || ""}
            onChange={handleChange}
            className={`w-full p-3 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${
              !edit && "bg-gray-100"
            }`}
            readOnly={!edit}
            required={true}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="latitude"
            className="text-sm font-medium text-gray-700"
          >
            Latitude
          </label>
          <input
            type="text"
            name="latitude"
            id="latitude"
            value={formData?.latitude || ""}
            onChange={handleChange}
            className={`w-full p-3 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${
              !edit && "bg-gray-100"
            }`}
            readOnly={!edit}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="longitude"
            className="text-sm font-medium text-gray-700"
          >
            Longitude
          </label>
          <input
            type="text"
            name="longitude"
            id="longitude"
            value={formData?.longitude || ""}
            onChange={handleChange}
            className={`w-full p-3 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${
              !edit && "bg-gray-100"
            }`}
            readOnly={!edit}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="startTime"
            className="text-sm font-medium text-gray-700"
          >
            Heures d'ouverture
          </label>
          <TimePicker
            value={
              formData?.startTime ? dayjs(formData.startTime, "HH:mm") : null
            }
            onChange={(e) => handleTimeChange(e, "startTime")}
            format="HH:mm"
            className={`w-full p-3 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${
              !edit && "bg-gray-100"
            }`}
            use12Hours={false}
            showNow={false}
            inputReadOnly={true}
            hideDisabledOptions={true}
            minuteStep={30}
            disabled={!edit}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor="endTime"
            className="text-sm font-medium text-gray-700"
          >
            Heure de fermeture
          </label>
          <TimePicker
            value={formData?.endTime ? dayjs(formData.endTime, "HH:mm") : null}
            onChange={(e) => handleTimeChange(e, "endTime")}
            format="HH:mm"
            className={`w-full p-3 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-orange-500 transition duration-300 ${
              !edit && "bg-gray-100"
            }`}
            use12Hours={false}
            showNow={false}
            inputReadOnly={true}
            hideDisabledOptions={true}
            minuteStep={30}
            disabled={!edit}
          />
        </div>
        {/* <div className="flex flex-col gap-2 col-span-1 md:col-span-2">
          <label htmlFor="services" className={`w-full p-2 border rounded-md outline-none focus:outline-none font-semibold`}
            use12Hours={false}
            showNow={false}
            inputReadOnly={true}
            hideDisabledOptions={true}
            minuteStep={30}
            disabled={!edit}
          />
        </div> */}
        {/* <div className="flex flex-col gap-2 col-span-1 md:col-span-2">
          <label
            className="text-sm font-medium text-gray-700"
            htmlFor="services"
          >
            Services
          </label>
          <div
            id="services"
            className="w-full p-2 border border-gray-500 bg-white rounded-lg min-h-fit h-fit opacity-90 flex flex-wrap gap-2"
          >
            {salon?.services
              ? salon?.services.map((service, index) => (
                  <span
                    key={index}
                    className="inline-block px-2 py-1 border border-orange-700 bg-orange-200 text-orange-800 rounded-full"
                  >
                    {service.name}
                  </span>
                ))
              : ""}
          </div>
        </div> */}

        {edit && (
          <div className="flex justify-end gap-2 col-span-1 md:col-span-2">
            <button
              type="submit"
              // className="bg-orange-primary text-white border-lightOrange rounded-md px-4 py-1 hover:border-lightOrange hover:text-orange-primary hover:bg-white"
              className={`min-w-24 h-10 flex items-center justify-center gap-2 font-semibold text-sm bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded-md shadow-md transition duration-300 ease-in-out`}
              style={{ border: "1px solid" }}
            >
              <CheckBadgeIcon className="w-5 h-5" />
              Valider
            </button>
          </div>
        )}
      </form>
      <ImageGallery
        salon={salon}
        albumInputRef={albumInputRef}
        edit={edit}
        setLoadingUpload={setLoadingUpload}
      />
      <ToastContainer />
    </div>
  );
};

export default SalonProfile;
