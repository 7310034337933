import React, { useEffect, useState, useCallback, useRef } from "react";
import { Layout, ListePaiements } from "../../components";
import { useSelector } from "react-redux";
import SortByBtn from "../../components/globalcomponents/SortByBtn";
import FilterBtn from "../../components/globalcomponents/FilterBtn";
import { ChevronDown, Filter } from "lucide-react";
import { useAuth } from "../../context/authContext";

const sortOptions = ["Date", "Nom"];
const filterOptions = ["Tout", "Payé", "En Attente"];

const Paiements = () => {
  const paiements = useSelector((state) => state?.data?.payments) || [];
  const [filteredPaiements, setFilteredPaiements] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortOption, setSortOption] = useState("Date");
  const [filterOption, setFilterOption] = useState("Tout");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [selectedSalon, setSelectedSalon] = useState('');
  const [isFilterSliderOpen, setIsFilterSliderOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef1 = useRef(null);
  const uniqueSalons = Array.from(new Set(paiements?.map(py => py?.booking?.salon?.name) || []));
  const { isSuperAdmin } = useAuth();

  const handleClickOutside1 = (event) => {
    if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside1);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside1);
    };
  }, []);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(prev => !prev);
  };

  const handleSalonSelect = (salon) => {
    setSelectedSalon(salon);
    setCurrentPage(1);
    setIsDropdownOpen(false);
  };

  const filterPaiements = useCallback((paiements, filter, search) => {
    let filtered = paiements;
  
    // Filter by salon
    if (selectedSalon) {
      filtered = filtered.filter((paiement) =>
        paiement?.booking?.salon?.name === selectedSalon
      );
    }
  
    // Filter by payment status
    if (filter !== "Tout") {
      filtered = filtered.filter((paiement) =>
        filter === "Payé" ? paiement?.payment_status : !paiement?.payment_status
      );
    }
  
    // Filter by search input (name)
    if (search) {
      const searchLowerCase = search.trim().toLowerCase();
      filtered = filtered.filter((payment) => {
        const fullName = `${payment?.booking?.user?.first_name || ''} ${payment?.booking?.user?.last_name || ''} ${payment?.booking?.guest?.name || ''}`.toLowerCase().trim();
        const reversedFullName = `${payment?.booking?.user?.last_name || ''} ${payment?.booking?.user?.first_name || ''}`.toLowerCase().trim();
        return fullName.includes(searchLowerCase) || reversedFullName.includes(searchLowerCase);
      });
    }
  
    return filtered;
  }, [selectedSalon]);
  

  const sortPaiements = useCallback((paiements, sort) => {
    if (sort === "Date") {
      return [...paiements].sort((a, b) => new Date(b?.booking?.user?.created_at) - new Date(a?.booking?.user?.created_at));
    } else if (sort === "Nom") {
      return [...paiements].sort((a, b) => {
        const aName = `${a.booking?.user?.first_name || ''} ${a.booking?.user?.last_name || ''}`.trim();
        const bName = `${b.booking?.user?.first_name || ''} ${b.booking?.user?.last_name || ''}`.trim();
        return aName.localeCompare(bName);
      });
    }
    return paiements;
  }, []);

  const updatePaiementsList = useCallback(() => {
    let result = filterPaiements(paiements, filterOption, searchInput);
    result = sortPaiements(result, sortOption);
    setFilteredPaiements(result);
    setCurrentPage(1); // Reset to first page when filters change
  }, [paiements, filterOption, searchInput, sortOption, filterPaiements, sortPaiements]);


  useEffect(() => {
    updatePaiementsList();
  }, [updatePaiementsList]);

  const handleSearchChange = (newSearchInput) => {
    setSearchInput(newSearchInput);
    setCurrentPage(1);
  };

  const handleSortChange = (newSortOption) => {
    setSortOption(newSortOption);
    setCurrentPage(1);
  };

  const handleFilterChange = (newFilterOption) => {
    setFilterOption(newFilterOption);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const toggleFilterSlider = () => {
    setIsFilterSliderOpen(prev => !prev);
  };

  const totalPages = Math.ceil(filteredPaiements.length / itemsPerPage);

  return (
    <Layout>
      <div className="rounded-lg shadow-md p-4">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <div className="w-full md:w-1/3">
            <input
              type="text"
              name="nom"
              id="nom"
              placeholder="Nom, Prénom"
              value={searchInput}
              onChange={(e) => handleSearchChange(e.target.value)}
              className="w-full py-2 px-3 border rounded-full outline-none focus:outline-none"
            />
          </div>
          <div className="md:hidden">
            <button
              onClick={toggleFilterSlider}
              className="w-full bg-orange-500 text-white py-2 px-4 rounded-full flex items-center justify-center"
            >
              <Filter size={18} className="mr-2" /> Filtres
            </button>
          </div>
          <div className={`flex flex-col md:flex-row gap-2 ${isFilterSliderOpen ? 'block' : 'hidden md:flex'}`}>
          {isSuperAdmin && <div className="relative">
              <button 
                className="w-full md:w-auto bg-white border rounded-full py-2 px-4 flex items-center justify-between"
                onClick={handleDropdownToggle}
              >
                <span>{selectedSalon || 'Tous les salons'}</span>
                <ChevronDown size={18} />
              </button>
              {isDropdownOpen && (
                <div className="absolute z-10 w-full mt-2 bg-white border rounded-lg shadow-lg" ref={dropdownRef1}>
                  <div
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleSalonSelect('')}
                  >
                    Tous les salons
                  </div>
                  {uniqueSalons.map((salon, index) => (
                    <div
                      key={index}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleSalonSelect(salon)}
                    >
                      {salon}
                    </div>
                  ))}
                </div>
              )}
            </div>}
            <div className="relative">
              <SortByBtn
                sortOptions={sortOptions}
                sortOption={sortOption}
                setSortOption={handleSortChange}
              />
            </div>
           
            <div className="relative">
            <FilterBtn
              filterOptions={filterOptions}
              filterOption={filterOption}
              setFilterOption={handleFilterChange}
            />
            </div>
          </div>
        </div>
        <ListePaiements
          paiements={filteredPaiements}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default Paiements;