import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { BASE_IMAGE_URL } from "../../constants/actions";
import { ProfileImg } from "../../assets/images";
import formatDate2 from "../../helpers/formatDate2";
import { EyeIcon } from '@heroicons/react/24/outline';

const DemandeListe = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const location = useLocation();

  const totalPages = Math.ceil(props?.demandes?.length / props?.itemsPerPage);
  const maxVisibleButtons = 6;

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [props?.demandes]);

  const startIndex = (currentPage - 1) * props?.itemsPerPage;
  const visiblerows = props?.demandes?.slice(
    startIndex,
    startIndex + props?.itemsPerPage
  );
  const navigate = useNavigate();

  return (
    <div>
      <div className="p-3 overflow-x-auto">
        <table className="w-full text-sm">
          <thead>
            <tr className="bg-stone-500">
              <th className="py-2 pl-3 text-white text-left whitespace-nowrap">
                ID
              </th>
              <th className="py-3 px-4 text-white text-center whitespace-nowrap">
                Nom et Prénom
              </th>
              <th className="py-3 px-4 text-white text-left whitespace-nowrap">
                Email
              </th>
              <th className="py-3 px-4 text-white text-left whitespace-nowrap">
                Téléphone
              </th>
              <th className="py-3 px-4 text-white text-left whitespace-nowrap">
                Date Création
              </th>
              <th className="py-3 px-4 text-white text-center whitespace-nowrap">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {props?.demandes?.length ? (
              visiblerows?.map((demande) => (
                <tr
                  key={demande?.id}
                  className="text-gray-gt border-b text-[10px] sm:text-xs md:text-sm lg:text-base"
                >
                  <td className="py-3 px-2 text-sm text-left">{demande?.id}</td>
                  <td className="text-gray-gt border-b text-center text-sm capitalize">
                    {demande?.first_name} {demande?.last_name}
                  </td>
                  <td className="text-gray-gt border-b text-[10px] text-sm">
                    {demande?.email}
                  </td>
                  <td className="text-gray-gt border-b text-[10px] text-sm">
                    {demande?.phone}
                  </td>
                  <td className="text-gray-gt border-b text-[10px] text-sm">
                    {demande?.created_at && formatDate2(demande?.created_at)}
                  </td>
                  <td className="border-b p-2 text-center">
                    <button
                      onClick={() =>
                        navigate(`/demandes/${demande?.id}`, {
                          state: { prevPath: location.pathname },
                        })
                      }
                      className="group p-1 rounded-full transition-all duration-300 ease-in-out hover:bg-orange-100 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      aria-label="Voir les détails"
                    >
                      <EyeIcon className="w-5 h-5 text-orange-400 group-hover:text-orange-600 transition-all duration-300 ease-in-out transform group-hover:scale-110" />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="py-4 text-center text-sm">
                  Aucune demande pour l'instant !
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {props?.demandes?.length > props?.itemsPerPage && (
        <div className="flex items-center justify-between text-xs sm:text-sm md:text-base px-7 py-5 my-4">
          <div>
            <p className="font-medium text-gray-pt">
              Affichage des données {startIndex + 1} à{" "}
              {Math.min(
                startIndex + props?.itemsPerPage,
                props?.demandes?.length
              )}{" "}
              sur {props?.demandes?.length} entrées
            </p>
          </div>
          <nav className="flex items-center space-x-1 md:space-x-2 font-medium">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 text-gray-950 bg-gray-200 disabled:opacity-50"
            >
              <i className="bi bi-chevron-left"></i>
            </button>
            {visiblePages?.map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 ${
                  currentPage === page
                    ? "bg-orange-primary text-white"
                    : "text-gray-950 bg-gray-200"
                }`}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 text-gray-950 bg-gray-200 disabled:opacity-50"
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          </nav>
        </div>
      )}
    </div>
  );
};

export default DemandeListe;
