import React, { useEffect, useState } from "react";
import {
  ListeAvis,
  ParametresLayout,
} from "../../components";
import { useSelector } from "react-redux";

const Avis = () => {
  const reviews = useSelector((state) => state?.data?.reviews);
  const [filtredAvis, setFiltredAvis] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (!searchInput) {
      setFiltredAvis(reviews);
      return;
    }

    const searchLowerCase = searchInput?.trim().toLowerCase();

    const filtered = reviews?.filter((review) => {
      const fullName =
        `${review?.user?.first_name ? `${review?.user?.first_name} ${review?.user?.last_name}` : review?.guest?.name}`.toLowerCase();
      const reversedFullName =
        `${review?.user?.last_name} ${review?.user?.first_name}`.toLowerCase();
      const firstName = review?.user?.first_name.toLowerCase();
      const lastName = review?.user?.last_name.toLowerCase();
      const email = review?.user?.email.toLowerCase();

      return (
        firstName?.includes(searchLowerCase) ||
        lastName?.includes(searchLowerCase) ||
        fullName?.includes(searchLowerCase) ||
        reversedFullName?.includes(searchLowerCase) ||
        email?.includes(searchLowerCase)
      );
    });

    setFiltredAvis(filtered);
  }, [searchInput, reviews]);

  return (
    <ParametresLayout>
        <div className="px-1 py-5 min-h-screen space-y-5">
          <div className="flex justify-center">
            <div className="min-w-72">
              <input
                type="text"
                name="nom"
                id="nom"
                placeholder="Nom, Prénom, Email"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="w-full py-2 px-3 border rounded-full outline-none focus:outline-none"
              />
            </div>
          </div>
          <ListeAvis reviews={filtredAvis} />
        </div>
    </ParametresLayout>
  );
};

export default Avis;
