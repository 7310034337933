import { useSelector } from "react-redux";
import { FullCalendarCpt, Layout } from "../../components";
import { useEffect, useRef, useState } from "react";
import { ChevronDown, Filter } from "lucide-react";
import { useAuth } from "../../context/authContext";

const Calendarier = () => {
  const bookings = useSelector((state) => state?.data?.bookings);
  const uniqueSalons = Array.from(new Set(bookings?.map(reservation => reservation?.salon?.name) || []));
  const [selectedSalon, setSelectedSalon] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef1 = useRef(null);

  const handleClickOutside1 = (event) => {
    if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside1);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside1);
    };
  }, []);

  // Filter bookings based on selected salon
  const filteredBookings = selectedSalon
    ? bookings?.filter((booking) => booking?.salon?.name === selectedSalon)
    : bookings;

  const events = filteredBookings?.map((booking) => {
    const startTime = `${booking?.availability?.date}T${booking?.availability?.start_time}`;
    const endTime = `${booking?.availability?.date}T${booking?.availability?.end_time}`;

    return { 
      id: booking?.id,
      title: `${booking?.user?.first_name ? `${booking?.user?.first_name} ${booking?.user?.last_name}` : booking?.guest?.name}`,
      start: startTime,
      end: endTime,
      description: booking?.services?.map((service) => service?.name).join(", "),
    };
  });

  const handleDropdownToggle = () => {
    setIsDropdownOpen(prev => !prev);
  };

  const handleSalonSelect = (salon) => {
    setSelectedSalon(salon);
    setIsDropdownOpen(false);
  };

  const { isSuperAdmin } = useAuth();


  return (
    <Layout>
      <div className="p-1 min-h-screen bg-white capitalize">
        {isSuperAdmin && <div className={`flex flex-col items-center md:flex-row gap-2 ml-2`}>
          <span>Filtrer par salon : </span>
          <div className="relative">
            <button 
              className="w-full md:w-auto bg-white border border-gray-300 rounded-full py-2 px-4 flex items-center justify-between shadow-sm transition duration-300 ease-in-out hover:bg-gray-100 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-400"
              onClick={handleDropdownToggle}
            >
              <span>{selectedSalon || 'Tous les salons'}</span>
              <ChevronDown size={18} className="text-gray-600" />
            </button>
            {isDropdownOpen && (
              <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-lg shadow-lg transition duration-300 ease-in-out">
                <div
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer transition-colors duration-200 ease-in-out"
                  onClick={() => handleSalonSelect('')}
                >
                  Tous les salons
                </div>
                {uniqueSalons.map((salon, index) => (
                  <div
                    key={index}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer transition-colors duration-200 ease-in-out"
                    onClick={() => handleSalonSelect(salon)}
                  >
                    {salon}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>}

        <FullCalendarCpt events={events} />
      </div>
    </Layout>
  );
};

export default Calendarier;