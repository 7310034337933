import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import formatDate2 from "../../helpers/formatDate2";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { apiClient } from "../../actions/api";
import { useDispatch } from "react-redux";
import { BLOGS } from "../../constants/actions";
import { FetchData } from "../../actions/data";

const ListeBlogs = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const location = useLocation();

  const totalPages = Math.ceil(props?.blogs?.length / props?.itemsPerPage);
  const maxVisibleButtons = 6;

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [props?.blogs]);

  const startIndex = (currentPage - 1) * props?.itemsPerPage;
  const visiblerows = props?.blogs?.slice(
    startIndex,
    startIndex + props?.itemsPerPage
  );
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    apiClient().delete(`/blogs/${id}`).then((res) => {
      dispatch(FetchData("/blogs", BLOGS));
    })
  }

  const confirmDelete = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce blog ?")) {
      handleDelete(id);
    }
  };

  return (
    <div className="overflow-x-auto pt-2">
      <table className="w-full text-sm">
        <thead>
          <tr className="bg-stone-500">
            {/* <th className="py-2">ID</th> */}
            <th className="py-3 px-4 text-white text-left whitespace-nowrap">
              Titre
            </th>
            <th className="py-3 px-4 text-white text-left whitespace-nowrap">
              Objet
            </th>
            <th className="py-3 px-4 text-white text-left whitespace-nowrap">
              Auteur
            </th>
            {/* <th className="py-3 px-4 text-white text-left whitespace-nowrap">Date de création</th> */}
            <th className="py-3 px-4 text-white text-left whitespace-nowrap">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {props?.blogs?.length ? (
            visiblerows?.map((blog) => (
              <tr key={blog?.id} className="border-b hover:bg-gray-50">
                {/* <td className="py-2 capitalize">
                  #{blog?.id}
                </td> */}
                <td className="py-3 px-4 whitespace-nowrap">{blog?.title}</td>
                <td className="py-3 px-4 whitespace-nowrap">{blog?.subject}</td>
                <td className="py-3 px-4 whitespace-nowrap capitalize">
                  {blog?.author}
                </td>
                {/* <td className="py-2 text-start capitalize">{blog?.created_at && formatDate2(blog?.created_at)}</td> */}
                <td className="py-2 flex gap-1 items-center">
                  <button
                    onClick={() =>
                      navigate(`/pages/blogs/${blog?.id}`, {
                        state: { prevPath: location?.pathname },
                      })
                    }
                    className="bg-orange-500 text-white px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm rounded-full hover:bg-orange-600 transition duration-300"
                  >
                    Détails
                  </button>
                
                <button
                  onClick={() => confirmDelete(blog?.id)}
                  className="bg-red-500 text-white px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm rounded-full hover:bg-red-600 transition duration-300 ml-2 flex items-center gap-1"
                >
                  supprimer
                </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="py-2 text-center text-gray-500">
                Aucun Blog trouvé
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {props?.blogs?.length > props?.itemsPerPage && (
        <div className="flex justify-center items-center p-4 pb-12">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`mr-2 p-2 rounded-full bg-gray-200 ${
              currentPage === 1
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-300"
            }`}
          >
            <ChevronLeft size={20} />
          </button>

          {visiblePages?.map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              disabled={currentPage === page}
              className={`mx-1 w-8 h-8 ${
                currentPage === page
                  ? "bg-orange-500 text-white"
                  : "text-gray-700 bg-gray-200"
              } rounded-full flex items-center justify-center`}
            >
              {page}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`ml-2 p-2 rounded-full bg-gray-200 ${
              currentPage === totalPages
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-300"
            }`}
          >
            <ChevronRight size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ListeBlogs;
