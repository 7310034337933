import { VIEW_MODE } from "../constants/actions"

const defaultState = {
    viewMode: 2,
}

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case VIEW_MODE:
            return {...state, viewMode: payload}
        default:
            return state
    }
}
