import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ChartBarIcon, UserGroupIcon, CalendarIcon } from "@heroicons/react/24/outline";
import {
  Layout,
  ListeClients,
  RendezVousDashboard,
  ReservationInProgress,
  ReservationsDashboard,
} from "../../components";

const Dashboard = () => {
  const data = useSelector((state) => state?.data);
  const [nextReservation, setNextReservation] = useState();
  const [reservationInProgress, setReservationInProgress] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data?.bookings?.length) {
      const now = new Date();

      const upcomingReservations = data.bookings
        .filter((res) => {
          const resDateTime = new Date(`${res.availability.date}T${res.availability.start_time}`);
          return resDateTime > now;
        })
        .sort((a, b) => {
          const dateA = new Date(`${a.availability.date}T${a.availability.start_time}`);
          const dateB = new Date(`${b.availability.date}T${b.availability.start_time}`);
          return dateA - dateB;
        });

      setNextReservation(upcomingReservations[0] || null);

      const currentReservation = data.bookings.find((res) => {
        const startTime = new Date(`${res.availability.date}T${res.availability.start_time}`);
        const endTime = new Date(`${res.availability.date}T${res.availability.end_time}`);
        return now >= startTime && now <= endTime;
      });

      setReservationInProgress(currentReservation || null);
      setIsLoading(false);
    }
  }, [data?.clients, data?.bookings, data?.users, data?.guests]);

  return (
    <Layout>
      <div className="flex flex-col xl:flex-row min-h-screen bg-gray-100">
        <div className="xl:w-9/12 p-6 flex flex-col md:flex-row gap-6">
          <div className="w-full md:w-1/3 flex flex-col gap-6">
            <RendezVousDashboard rendezvous={nextReservation} isLoading={isLoading} />
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-6">
              <StatCard
                title="Clients"
                value={data?.clients?.length + data?.guests?.length}
                icon={<UserGroupIcon className="w-10 h-10 text-green-600" />}
                isLoading={isLoading}
                gradientColors="from-green-500 to-green-300"
              />
              <StatCard
                title="Réservations"
                value={data?.bookings?.filter((res) => res?.status)?.length}
                icon={<CalendarIcon className="w-10 h-10 text-pink-600" />}
                isLoading={isLoading}
                gradientColors="from-pink-500 to-pink-300"
              />
            </div>
          </div>
          <div className="w-full md:w-2/3 flex flex-col gap-6">
            <ReservationsDashboard reservations={data?.bookings} />
            <ReservationInProgress reservation={reservationInProgress} isLoading={isLoading} />
          </div>
        </div>
        <div className="xl:w-3/12 py-6 p-6">
          <ListeClients data={data} isLoading={isLoading} />
        </div>
      </div>
    </Layout>
  );
};

const StatCard = ({ title, value, icon, isLoading, gradientColors }) => (
  <div className={`rounded-lg shadow-lg bg-gradient-to-r ${gradientColors} p-6 flex items-center justify-between`}>
    <div className="flex flex-col">
      <h4 className="text-lg font-semibold text-gray-100 mb-2">{title}</h4>
      {isLoading ? (
        <div className="w-16 h-8 bg-gray-300 rounded animate-pulse" />
      ) : (
        <span className="text-4xl font-extrabold text-white">{value}</span>
      )}
    </div>
    <div className="bg-white p-3 rounded-full shadow-inner">
      {icon}
    </div>
  </div>
);

export default Dashboard;