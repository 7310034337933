import { useSelector } from "react-redux";
import { ParametresLayout, SalonProfile } from "../../components";

const Salon = () => {
  const salon = useSelector((state) => state?.data?.salon);
  return (
    <ParametresLayout>
      <div className="p-3 min-h-screen">
        <SalonProfile salon={salon} />
      </div>
    </ParametresLayout>
  );
};

export default Salon;