import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProfileImg } from "../../../assets/images";
import ListeMessagerie from "../liste_messagerie";
import { BASE_IMAGE_URL } from "../../../constants/actions";

// Heroicons imports
import { PencilIcon, UserIcon, ArrowRightIcon } from "@heroicons/react/24/solid";

const ListeClients = ({ data, isLoading }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dataArray = [
    ...(Array.isArray(data?.clients) ? data.clients : []),
    ...(Array.isArray(data?.guests) ? data.guests : []),
  ];

  // Loading skeleton item
  const LoadingItem = () => (
    <div className="flex items-center gap-x-3">
      <div className="w-11 h-11 min-w-11 min-h-11 rounded-full bg-gray-300 animate-pulse" />
      <div className="flex flex-col w-full">
        <div className="w-24 h-4 bg-gray-300 rounded animate-pulse mb-2" />
        <div className="w-32 h-3 bg-gray-300 rounded animate-pulse" />
      </div>
    </div>
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-1 gap-x-3 gap-y-6">
      {/* Clients List Section */}
      <div className="px-3 border rounded-lg bg-white py-3 space-y-6 md:min-h-100">
        <div className="flex items-center justify-between">
          <h2 className="text-lg sm:text-xl text-gray-gt font-bold">
            Liste des clients
          </h2>
          <button
            onClick={() =>
              navigate("/clients", { state: { prevPath: location?.pathname } })
            }
            className="flex items-center text-orange-primary font-medium text-sm sm:text-base"
          >
            <ArrowRightIcon className="w-5 h-5 ml-1" />
          </button>
        </div>

        <div className="space-y-2 min-w-fit">
          {/* Show Loading Skeletons when data is still loading */}
          {isLoading ? (
            <>
              <LoadingItem />
              <LoadingItem />
              <LoadingItem />
            </>
          ) : (
            dataArray?.slice(0, 5)?.map((client) => (
              <div
                key={client?.id}
                className="flex items-center gap-x-3 cursor-pointer px-1 py-2 rounded-lg"
              >
                {/* Client Avatar */}
                <div className="w-10 h-10 min-w-10 min-h-10 rounded-full bg-gray-200 overflow-hidden">
                    {client?.avatar ? (
                    <img
                      src={BASE_IMAGE_URL + client?.avatar}
                      alt="img"
                      className="object-cover w-full h-full"
                    />
                  ) : (
                    <UserIcon className="w-10 h-10 mt-2 text-gray-400" />
                  )}
                </div>

                {/* Client Details */}
                <div className="flex items-center justify-between w-full">
                  <div>
                    <h3 className="text-sm sm:text-base font-bold text-gray-gt">
                      {client?.first_name
                        ? `${client?.first_name} ${client?.last_name}`
                        : client?.name}
                    </h3>
                    <p className="text-xs sm:text-sm text-gray-pt">{client?.email}</p>
                  </div>

                  {/* Edit Button with Heroicons Pencil */}
                  {client?.first_name && <div>
                    <button
                      onClick={() =>
                        navigate(`/clients/${client?.id}`, {
                          state: { prevPath: location?.pathname },
                        })
                      }
                      className="text-gray-pt hover:text-orange-700 transition"
                    >
                      <PencilIcon className="w-5 h-5" />
                    </button>
                  </div>}
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Messaging List */}
      {/* <ListeMessagerie chats={data?.chats} isLoading={isLoading} /> */}
    </div>
  );
};

export default ListeClients;