import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { ClientsTable, Layout } from "../../components";

const sortOptions = ["Date Création", "Nom"];

const Clients = () => {
  const data = useSelector((state) => state?.data) || [];
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortOption, setSortOption] = useState("Date Création");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const dataArray = useMemo(() => [
    ...(Array.isArray(data?.clients) ? data?.clients : []),
    ...(Array.isArray(data?.guests) ? data?.guests : [])
  ], [data?.clients, data?.guests]);

  const sortClients = useCallback((clients, option) => {
    if (option === "Date Création") {
      return [...clients].sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));
    } else if (option === "Nom") {
      return [...clients].sort((a, b) => {
        const getComparableNameF = (client) => client?.first_name || client?.name || '';
        const getComparableNameL = (client) => client?.last_name || client?.name || '';
        const aFirstName = getComparableNameF(a)?.toLowerCase();
        const bFirstName = getComparableNameF(b)?.toLowerCase();
        const aLastName = getComparableNameL(a)?.toLowerCase();
        const bLastName = getComparableNameL(b)?.toLowerCase();
        
        const firstNameComparison = aFirstName?.localeCompare(bFirstName);
        return firstNameComparison === 0 ? aLastName?.localeCompare(bLastName) : firstNameComparison;
      });
    }
    return clients;
  }, []);

  const filterClients = useCallback((clients, search) => {
    if (!search) return clients;
    
    const searchLowerCase = search.trim().toLowerCase();
    return clients.filter((client) => {
      const fullName = `${client?.first_name || ''} ${client?.last_name || ''} ${client?.name || ''}`.toLowerCase().trim();
      const reversedfullName = `${client?.last_name || ''} ${client?.first_name || ''} ${client?.name || ''}`.toLowerCase().trim();
      const email = (client?.email || '').toLowerCase();
      return fullName?.includes(searchLowerCase) || reversedfullName?.includes(searchLowerCase) || email?.includes(searchLowerCase);
    });
  }, []);

  useEffect(() => {
    let result = dataArray;
    result = filterClients(result, searchInput);
    result = sortClients(result, sortOption);
    setFilteredClients(result);
  }, [dataArray, searchInput, sortOption, filterClients, sortClients]);

  const handleSearchChange = (newSearchInput) => {
    setSearchInput(newSearchInput);
    setCurrentPage(1);
  };

  const handleSortChange = (newSortOption) => {
    setSortOption(newSortOption);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  
  return (
    <Layout>
      <ClientsTable
        sortOptions={sortOptions}
        sortOption={sortOption}
        setSortOption={handleSortChange}
        clients={filteredClients}
        itemsPerPage={itemsPerPage}
        searchInput={searchInput}
        setSearchInput={handleSearchChange}
        currentPage={currentPage}
        setCurrentPage={handlePageChange}
      />
    </Layout>
  );
};

export default Clients;