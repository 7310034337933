import React, { useState } from 'react'
import Sidebar from '../sidebar'
import Header from '../header'

const Layout = ({ children }) => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className='bg-gray-bg min-h-screen font-inter'>
      <div className={`fixed inset-0 z-40 bg-black bg-opacity-55 ${isSidebarOpen ? 'block' : 'hidden'} lg:hidden`} onClick={toggleSidebar}></div>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>
      <div className="ml-0 lg:ml-[200px]">
        <Header toggleSidebar={toggleSidebar}/>
        <div className="">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Layout