import React, { useEffect, useState } from "react";
import { ComeBackButton, ParametresLayout } from "../../components";
import { useLocation, useNavigate } from "react-router";
import { apiClient } from "../../actions/api";
import dayjs from 'dayjs';
import { TimePicker } from "antd";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux"; // Import useSelector
import { FetchData } from "../../actions/data";
import { SERVICES } from "../../constants/actions";

function AddServiceSalon() {
  const [allServices, setAllServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [formData, setFormData] = useState([]);
  const location = useLocation();
  const services = useSelector((state) => state?.data?.services); // Get already selected services from state
  const salon = useSelector((state) => state?.data?.salon); // Get already selected services from state
  const user = useSelector((state) => state?.auth?.user); // Get already selected services from state
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    apiClient()
      .get(`/services`)
      .then((res) => {
        setAllServices(res?.data);
      });
  }, []);

  const handleServiceSelect = (e) => {
    const selectedServiceId = e.target.value;
  
    // Check if the selected service already exists in the services array by comparing the id
    const isServiceAlreadySelected = services?.some(service => service.id === parseInt(selectedServiceId));
    
    if (isServiceAlreadySelected) {
      toast.error('Ce service est déjà sélectionné.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        toastId: 'service-selected-toast',
        lang: 'fr',
      });
      return;
    }
    
    const selected = allServices.find((s) => s.id === parseInt(selectedServiceId));
    
    if (selected) {
      setSelectedService(selected);
      setFormData({
        service: selected.id, // Use service ID for formData
        price: selected.price || '',
        duration: selected.duration || '',
        description: selected.description || '',
      });
    }
  };
  
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleTimeChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      duration: value,
    }));
  };

  const handleSave = () => {
    const duration = formData?.duration
        ? dayjs(formData?.duration, "HHmm").isValid()
          ? dayjs(formData?.duration, "HHmm").format("HH:mm")
          : dayjs("00:30", "HH:mm").format("HH:mm")
        : dayjs("00:30", "HH:mm").format("HH:mm");

    let datapi = {
      "salon_id": salon?.id,
      "pivot": {
        "price": formData?.price,
        duration,
        "description": formData?.description
      }
    }
    
    
    // Save the service

    apiClient().post(`/services/${formData?.service}/create-pivot`, datapi)
    .then((response) => {
      if(response?.status === 200) {
        toast.success(
          "Services enregistrés avec succès !"
        );
        // dispatch(FetchData(`/services`, SERVICES));
        dispatch(FetchData(`/salonServices/${user?.id}`, SERVICES));
        navigate(location?.state?.prevPath)
      }
    })
  
  };

  return (
    <ParametresLayout>
      <div className="px-1 py-5 min-h-screen">
        <ComeBackButton prev={location?.state?.prevPath} next={"/parametres/addservices"} />
        <div className="services-section">
          <div className="mb-4">
            <select
              className="w-md p-2 border border-orange-400 rounded-lg outline-none focus:outline-none"
              onChange={handleServiceSelect}
              defaultValue=""
            >
              <option value="" disabled>
                Sélectionnez un service
              </option>
              {allServices?.map((service) => (
                <option
                  key={service.id}
                  value={service.id}
                  className={`${services?.some(s => s.id === service.id) && `bg-gray-600 text-white`} text-sm`}
                >
                  {service.name}
                </option>
              ))}
            </select>
          </div>
          {selectedService && (
            <div className="mt-6">
              <div className="flex flex-col gap-2">
                <h3 className="font-bold mx-auto text-lg text-orange-500">
                  {selectedService.name}
                </h3>
                <div className="flex flex-col gap-2">
                  <label htmlFor="price">Prix</label>
                  <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md outline-none focus:outline-none"
                    step="0.01"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="duration">Durée</label>
                  <TimePicker
                    value={
                      formData.duration ? dayjs(formData.duration, "HH:mm") : null
                    }
                    onChange={handleTimeChange}
                    format="HH:mm"
                    className="w-full p-2 border rounded-md outline-none focus:outline-none font-semibold"
                    use12Hours={false}
                    showNow={false}
                    inputReadOnly={true}
                    hideDisabledOptions={true}
                    minuteStep={5}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="description">Description</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    className="w-full p-2 min-h-24 border rounded-md outline-none focus:outline-none"
                  ></textarea>
                </div>
                <button
                  className="bg-orange-500 text-white px-4 py-2 rounded-md mt-4"
                  onClick={handleSave}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </ParametresLayout>
  );
}

export default AddServiceSalon;