import { useEffect, useState, useCallback } from "react";
import ParametresLayout from "../../components/parametresComponents/ParametresLayout";
import { useDispatch, useSelector } from "react-redux";
import { apiClient } from "../../actions/api";
import { toast, ToastContainer } from "react-toastify";
import { TextEditor } from "../../components";
import { FetchData } from "../../actions/data";
import { EMAILS } from "../../constants/actions";

const Emails = () => {
  const [emailsData, setEmailsData] = useState([]);
  const emails = useSelector((state) => state?.data?.emails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (emails?.length) {
      setEmailsData(emails);
    }
  }, [emails]);

  const handleTextChange = useCallback((id, text) => {
    setEmailsData((prevState) =>
      prevState?.map((mail) =>
        mail.id === id ? { ...mail, content: text } : mail
      )
    );
  }, []);

  const handleSubjectChange = useCallback((e, id) => {
    const newSubject = e.target.value;
    setEmailsData((prevState) =>
      prevState?.map((mail) =>
        mail.id === id ? { ...mail, subject: newSubject } : mail
      )
    );
  }, []);

  const handleSubmit = useCallback(
    async (id, e) => {
      e.preventDefault();
      const mail = emailsData?.find((mail) => mail.id === id);
      if (mail) {
        try {
          await apiClient().put(`/emails/${id}`, mail);
          toast.success(
            `Email ${
              mail.type === "register" ? "d'inscription" : "de réservation"
            } mis à jour avec succès`
          );
          dispatch(FetchData('/emails', EMAILS));
        } catch (err) {
          toast.error(
            `Échec de la mise à jour de l'email ${
              mail.type === "register" ? "d'inscription" : "de réservation"
            }`
          );
          console.error(
            `Failed to update email ${
              mail.type === "register"
                ? "d'inscription"
                : "de réservation"
            }:`,
            err
          );
        }
      }
    },
    [emailsData, dispatch]
  );

  return (
    <ParametresLayout>
      <div className="p-5 min-h-screen">
        <div className="space-y-8">
          <ToastContainer />
          {emailsData?.map(({ id, titre, subject, content }) => (
            <form
              key={id}
              onSubmit={(e) => handleSubmit(id, e)}
              className="space-y-6 bg-white p-6 rounded-lg shadow-md border border-gray-200"
            >
              <h3 className="text-2xl font-bold text-gray-800">
                {titre} :
              </h3>
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor={`subject-${id}`}
                  className="font-medium text-gray-700"
                >
                  Sujet :
                </label>
                <input
                  type="text"
                  id={`subject-${id}`}
                  name={`subject-${id}`}
                  value={subject}
                  onChange={(e) => handleSubjectChange(e, id)}
                  className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-orange-500 transition"
                  placeholder="Sujet"
                  required
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor={`content-${id}`}
                  className="font-medium text-gray-700"
                >
                  Message :
                </label>
                <div className="min-h-[200px] h-[300px] bg-white border border-gray-300 rounded-lg overflow-hidden">
                  <TextEditor
                    texteditor={content}
                    setTexteditor={(text) => handleTextChange(id, text)}
                    className=""
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-orange-500 text-white px-6 py-2 rounded-lg hover:bg-orange-600 transition"
                >
                  Valider
                </button>
              </div>
            </form>
          ))}
        </div>
      </div>
    </ParametresLayout>
  );
};

export default Emails;