import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import SortBy from "../globalcomponents/SortByBtn";
// import { BASE_IMAGE_URL } from "../../constants/actions";
import { ProfileImg } from "../../assets/images";
import { BASE_IMAGE_URL } from "../../constants/actions";
import FilterBtn from "../globalcomponents/FilterBtn";

const UsersListe = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  
  const totalPages = Math.ceil(props?.users?.length / props?.itemsPerPage);
  const maxVisibleButtons = 6;

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [props?.users]);

  const startIndex = (currentPage - 1) * props?.itemsPerPage;
  const visiblerows = props?.users?.slice(startIndex, startIndex + props?.itemsPerPage);

  return (
    <div>
      <div className="flex items-center justify-between flex-wrap md:flex-nowrap gap-2 p-4 mt-5 text-xs sm:text-sm">
        <div className="min-w-72">
          <input
            type="text"
            name="nom"
            id="nom"
            placeholder="Nom, Prénom, Email"
            value={props?.searchInput}
            onChange={(e) => props?.setSearchInput(e.target.value)}
            className="w-full py-2 px-3 border rounded-full outline-none focus:outline-none"
          />
        </div>
        <div className="flex items-center justify-end gap-x-2 w-full lg:w-fit h-8 sm:h-9 md:h-10 max-h-10">
          <div className="min-w-32 h-full">
            <SortBy
              sortOptions={props?.sortOptions}
              sortOption={props?.sortOption}
              setSortOption={props?.setSortOption}
              />
          </div>
          <div className="min-w-28 h-full">
            <FilterBtn
              filterOptions={props?.filterOptions}
              filterOption={props?.filterOption}
              setFilterOption={props?.setFilterOption}
              />
          </div>
        </div>
      </div>

      <div className="p-3 overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-gray-pt text-center border-b text-[10px] sm:text-xs md:text-sm lg:text-base">
              <th className="py-2"><i className="bi bi-person-fill text-base sm:text-lg md:text-xl lg:text-2xl"></i></th>
              <th className="py-2">Nom</th>
              <th className="py-2">Email</th>
              <th className="py-2">Téléphone</th>
              <th className="py-2">Rôle</th>
              <th className="py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {props?.users?.length ? visiblerows?.map((user) => (
              <tr key={user?.id} className="text-center text-gray-gt border-b text-[10px] sm:text-xs md:text-sm lg:text-base">
                <td className="py-1">
                  <img
                    src={user?.avatar ? BASE_IMAGE_URL + user?.avatar : ProfileImg}
                    alt="avatar"
                    className="object-cover w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 min-w-8 min-h-8 rounded-full mx-auto"
                  />
                </td>
                <td className="py-2 capitalize">{user?.first_name} {user?.last_name}</td>
                <td className="py-2">{user?.email}</td>
                <td className="py-2">{user?.phone}</td>
                <td className="py-2 capitalize">{user?.role === 'admin' ? 'Admin' : 'Client'}</td>
                <td className="py-2">
                  <button
                    onClick={() => navigate(`/parametres/utilisateurs/${user?.id}`, {state:{prevPath: location?.pathname}})}
                    className="text-base sm:text-lg md:text-xl lg:text-2xl text-gray-pt"
                  >
                    <i className="bi bi-pencil-square"></i>
                  </button>
                </td>
              </tr>
            )) : (
              <tr>
                <td colSpan={6} className="py-2 text-center text-gray-500">Aucun Utilisateur</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {props?.users?.length > props?.itemsPerPage && (
        <div className="flex items-center justify-between text-xs sm:text-sm md:text-base px-7 py-5 my-4">
          <div>
            <p className="font-medium text-gray-pt">
              Affichage des données {startIndex + 1} à {Math.min(startIndex + props?.itemsPerPage, props?.users?.length)} sur {props?.users?.length} entrées
            </p>
          </div>
          <nav className="flex items-center space-x-1 md:space-x-2 font-medium">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 text-gray-950 bg-gray-200 disabled:opacity-50"
            >
              <i className="bi bi-chevron-left"></i>
            </button>
            {visiblePages?.map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 ${
                  currentPage === page
                    ? "bg-orange-primary text-white"
                    : "text-gray-950 bg-gray-200"
                }`}
              >
                {page}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 text-gray-950 bg-gray-200 disabled:opacity-50"
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          </nav>
        </div>
      )}
    </div>
  );
};

export default UsersListe;
