import React, { useEffect, useState, useCallback } from "react";
import { Layout, MessagerieCpt } from "../../components";
import { useSelector } from "react-redux";
import FilterBtn from "../../components/globalcomponents/FilterBtn";
import SortBy from "../../components/globalcomponents/SortByBtn";

const sortOptions = ["Plus Récent", "Plus Ancien", "Nom"];
const filterOptions = ["Tout", "Non lu", "Lu"];

const Messagerie = () => {
  const chats = useSelector((state) => state?.data?.chats) || [];
  const [filteredChats, setFilteredChats] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortOption, setSortOption] = useState("Plus Récent");
  const [filterOption, setFilterOption] = useState("Tout");

  const sortChats = useCallback((chatsToSort, option) => {
    return [...chatsToSort]?.sort((a, b) => {
      if (option === "Plus Récent") {
        return new Date(b?.created_at) - new Date(a?.created_at);
      } else if (option === "Plus Ancien") {
        return new Date(a?.created_at) - new Date(b?.created_at);
      } else if (option === "Nom") {
        const aName = a?.user ? `${a?.user?.first_name} ${a?.user?.last_name}` : a?.guest?.name;
        const bName = b?.user ? `${b?.user?.first_name} ${b?.user?.last_name}` : b?.guest?.name;
        return aName?.localeCompare(bName);
      }
      return 0;
    });
  }, []);

  const filterChats = useCallback((chatsToFilter, search, filter) => {
    let result = chatsToFilter;

    if (search) {
      const searchLowerCase = search?.trim().toLowerCase();
      result = result?.filter((chat) => {
        const name = chat?.user
          ? `${chat?.user?.first_name} ${chat?.user?.last_name}`
          : chat?.guest?.name;
        const email = chat?.user?.email || chat?.guest?.email;
        return (
          name?.toLowerCase()?.includes(searchLowerCase) ||
          email?.toLowerCase()?.includes(searchLowerCase)
        );
      });
    }

    if (filter === "Non lu") {
      result = result?.filter((chat) => chat?.seen === 0);
    } else if (filter === "Lu") {
      result = result?.filter((chat) => chat?.seen === 1);
    }

    return result;
  }, []);

  const updateChatsList = useCallback(() => {
    let result = chats;
    result = filterChats(result, searchInput, filterOption);
    result = sortChats(result, sortOption);
    setFilteredChats(result);
  }, [chats, searchInput, filterOption, sortOption, filterChats, sortChats]);

  useEffect(() => {
    updateChatsList();
  }, [updateChatsList]);

  const handleSearchChange = (e) => {
    setSearchInput(e?.target?.value);
  };

  const handleSortChange = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const handleFilterChange = (newFilterOption) => {
    setFilterOption(newFilterOption);
  };

  return (
    <Layout>
      <div className="p-1">
        <div className="flex items-center justify-between flex-wrap md:flex-nowrap gap-2 p-4 mt-5 text-xs sm:text-sm">
          <div className="min-w-72">
            <input
              type="text"
              name="nom"
              id="nom"
              placeholder="Nom, Prénom, Email"
              value={searchInput}
              onChange={handleSearchChange}
              className="w-full py-2 px-3 border rounded-full outline-none focus:outline-none"
            />
          </div>
          <div className="flex items-center justify-end gap-x-2 w-full lg:w-fit h-8 sm:h-9 md:h-10 max-h-10">
            <div className="min-w-32 h-full">
              <SortBy
                sortOptions={sortOptions}
                sortOption={sortOption}
                setSortOption={handleSortChange}
              />
            </div>
            <div className="min-w-28 h-full">
              <FilterBtn
                filterOptions={filterOptions}
                filterOption={filterOption}
                setFilterOption={handleFilterChange}
              />
            </div>
          </div>
        </div>
        <MessagerieCpt chats={filteredChats} />
      </div>
    </Layout>
  );
};

export default Messagerie;