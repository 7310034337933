import React, { useRef, useState } from "react";
import { BASE_IMAGE_URL } from "../../constants/actions";
// import { IconImg } from "../../assets/images";
import TextEditor from "../globalcomponents/TextEditor";
import {
  CheckIcon,
  PencilIcon,
  UserIcon,
  DocumentTextIcon,
  ArrowUpTrayIcon,
} from "@heroicons/react/24/outline";
import uploadImage from "../../helpers/uploadImages";

const AddBlog = ({ blogData, handleChange, handleSubmit }) => {
  const [selectedImage, setSelectedImage] = useState(
    blogData?.image ? BASE_IMAGE_URL + blogData?.image : null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // Track upload state
  const fileInputRef = useRef(null);

  const handleChangeAvatar = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file type and size
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSize = 2 * 1024 * 1024; // 2MB

      if (!validTypes.includes(file.type)) {
        alert("Veuillez télécharger une image valide (JPEG, PNG, GIF).");
        return;
      }

      if (file.size > maxSize) {
        alert("L'image ne doit pas dépasser 2 Mo.");
        return;
      }

      setIsUploading(true); // Start uploading state
      const formData = new FormData();
      formData.append("image", file);

      try {
        const newPath = await uploadImage(
          "image",
          { image: file },
          { image: "" },
          "Blogs"
        );

        // Set the uploaded image URL
        const imageUrl = newPath; // Adjust based on your response
        setSelectedImage(imageUrl);
        handleChange({
          target: {
            name: "image",
            value: imageUrl,
          },
        });
      } catch (error) {
        console.error("Error uploading image:", error);
        // Handle the error accordingly
      } finally {
        setIsUploading(false); // End uploading state
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start loading state
    await handleSubmit(e);
    setIsSubmitting(false); // Stop loading state
  };

  const imageUrl =
    selectedImage && !selectedImage.startsWith(BASE_IMAGE_URL)
      ? BASE_IMAGE_URL + selectedImage
      : selectedImage;

  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Ajouter un Blog</h2>
      <form
        className="grid grid-cols-1 md:grid-cols-2 gap-6"
        onSubmit={handleFormSubmit}
      >
        <div className="flex flex-col gap-3">
          <label htmlFor="title" className="flex items-center text-gray-600">
            <PencilIcon className="w-5 h-5 mr-2" />
            Titre
          </label>
          <input
            type="text"
            name="title"
            id="title"
            value={blogData?.title}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="Entrez le titre du blog"
          />
        </div>
        <div className="flex flex-col gap-3">
          <label htmlFor="author" className="flex items-center text-gray-600">
            <UserIcon className="w-5 h-5 mr-2" />
            Auteur
          </label>
          <input
            type="text"
            name="author"
            id="author"
            value={blogData?.author}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="Entrez le nom de l'auteur"
          />
        </div>
        <div className="flex flex-col gap-3">
          <label htmlFor="subject" className="flex items-center text-gray-600">
            <DocumentTextIcon className="w-5 h-5 mr-2" />
            Objet
          </label>
          <input
            type="text"
            name="subject"
            id="subject"
            value={blogData?.subject}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
            placeholder="Entrez le sujet du blog"
          />
        </div>
        <div className="flex flex-col gap-3">
          <label htmlFor="img" className="flex items-center text-gray-600">
            <ArrowUpTrayIcon className="w-5 h-5 mr-2" />
            Image
          </label>
          <div className="bg-white h-56 max-h-56 border border-gray-300 rounded-lg flex justify-center items-center relative">
            <input
              type="file"
              id="img"
              onChange={handleChangeAvatar}
              className="sr-only"
              ref={fileInputRef}
            />
            <label
              htmlFor="img"
              className="cursor-pointer w-full h-full flex justify-center items-center"
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  className="object-cover w-full h-full rounded-lg"
                />
              ) : (
                <div className="border-2 border-dashed border-gray-400 rounded-lg w-full h-full flex justify-center items-center">
                  <span className="text-gray-400">
                    Cliquez pour télécharger une image
                  </span>
                </div>
              )}
            </label>
            {isUploading && (
              <div className="absolute inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50 rounded-lg">
                <svg
                  className="animate-spin h-8 w-8 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="none"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v3.6A4.4 4.4 0 002 12a4.4 4.4 0 001 2.8V12z"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2 col-span-1 md:col-span-2">
          <label htmlFor="contenue">Contenu</label>
          <div className="min-h-96 h-96 bg-white overflow-hidden border border-gray-300 rounded-md shadow-sm">
            <TextEditor
              texteditor={blogData?.contenue}
              setTexteditor={(value) =>
                handleChange({ target: { name: "contenue", value } })
              }
            />
          </div>
        </div>
        <div className="flex justify-end gap-4 col-span-1 md:col-span-2">
          <button
            type="submit"
            disabled={isSubmitting} // Disable button when submitting
            className={`bg-orange-500 text-white rounded-md px-6 py-2 flex items-center justify-center transition duration-300 ${
              isSubmitting
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-orange-600"
            }`}
          >
            {isSubmitting ? (
              <svg
                className="animate-spin h-5 w-5 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                  fill="none"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v3.6A4.4 4.4 0 002 12a4.4 4.4 0 001 2.8V12z"
                />
              </svg>
            ) : (
              <CheckIcon className="w-5 h-5 mr-2" />
            )}
            Valider
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddBlog;
