import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/authContext";
import { BASE_IMAGE_URL } from "../../../constants/actions";
import FormattingDate from "../../../helpers/FormattingDate";
import { UserCircleIcon, PencilIcon, CalendarIcon, EnvelopeIcon, PhoneIcon, MapPinIcon, IdentificationIcon } from '@heroicons/react/24/solid';

const ProfileClient = ({ client }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSuperAdmin } = useAuth();

  const InputField = ({ label, value, icon: Icon, type = "text" }) => (
    <div className="flex flex-col gap-1">
      <label className="text-sm font-medium text-gray-700 flex items-center">
        <Icon className="w-5 h-5 mr-2 text-gray-500" />
        {label}
      </label>
      <input
        type={type}
        value={value || "Non spécifié"}
        className="w-full p-2 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200 bg-gray-50"
        readOnly
      />
    </div>
  );

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="relative h-36 bg-gradient-to-r from-yellow-500 to-orange-600">
        <div className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-t from-white to-transparent"></div>
        <div className="absolute -bottom-10 left-6 w-24 h-24 rounded-full border-4 border-white overflow-hidden bg-gray-100 flex items-center justify-center">
          {client?.avatar ? (
            <img
              src={BASE_IMAGE_URL + client.avatar}
              alt="avatar"
              className="object-cover w-full h-full"
            />
          ) : (
            <UserCircleIcon className="w-32 h-32 text-gray-400" />
          )}
        </div>
        {isSuperAdmin && (
          <button 
            className="absolute top-4 right-4 bg-white text-gray-700 px-4 py-2 rounded-full shadow-md hover:bg-gray-100 transition-colors duration-200 flex items-center"
            onClick={() => navigate(`/parametres/utilisateurs/${client?.id}`, {state:{prevPath: location?.pathname}})}
          >
            <PencilIcon className="w-4 h-4 mr-2" />
            Modifier
          </button>
        )}
      </div>
      <div className="pt-16 px-6 pb-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-1">
          {client?.first_name || "Non spécifié"} {client?.last_name || "Non spécifié"}
        </h2>
        <p className="text-gray-600 mb-6">{client?.email || "Non spécifié"}</p>
        <form className="grid grid-cols-1 md:grid-cols-2 gap-6" onSubmit={(e) => e.preventDefault()}>
          <InputField label="Prénom" value={client?.first_name} icon={UserCircleIcon} />
          <InputField label="Nom" value={client?.last_name} icon={UserCircleIcon} />
          <InputField label="Email" value={client?.email} icon={EnvelopeIcon} />
          <InputField label="Téléphone" value={client?.phone} icon={PhoneIcon} />
          <InputField label="Genre" value={client?.gender} icon={IdentificationIcon} />
          <InputField label="Date Naissance" value={client?.birthday} icon={CalendarIcon} type="date" />
          <InputField label="Date Création" value={client?.created_at ? FormattingDate(client?.created_at) : ""} icon={CalendarIcon} />
          <InputField label="Code Postal" value={client?.code_postal} icon={MapPinIcon} />
          <div className="col-span-full">
            <InputField label="Adresse" value={client?.adresse} icon={MapPinIcon} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileClient;