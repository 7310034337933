import { Check, RefreshCcw } from "lucide-react";
import TextEditor from "../globalcomponents/TextEditor";

const About = ({
  formData,
  handleChange,
  edit,
  SubmitHandler,
  ResetHandler,
}) => {

  const fields = ["aboutTitle", "aboutDescription"];

  return (
    <form onSubmit={(e)=>SubmitHandler(e, fields)} className="">
      <div className="flex items-center justify-between">
        <h1 className="text-lg md:text-xl lg:text-2xl font-semibold my-2">
          Section À propos
        </h1>
      </div>
      <div className="space-y-4 p-2 border rounded-lg bg-white">
        {edit && (
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              onClick={() => ResetHandler(fields)}
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-gray-500 hover:bg-gray-600"
            >
              <RefreshCcw className="w-4 h-4 md:h-6 md:w-6" />
            </button>
            <button
              type="submit"
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-blue-900 hover:bg-blue-950"
            >
              <Check className="w-4 h-4 md:h-6 md:w-6" />
            </button>
          </div>
        )}
        <div className="text-xs md:text-sm lg:text-base">
          <div className="p-2 space-y-4">
            <div className="flex flex-col gap-2">
              <label htmlFor="aboutTitle">Titre</label>
              <input
                type="text"
                name="aboutTitle"
                id="aboutTitle"
                placeholder="Titre"
                value={formData?.aboutTitle || ""}
                onChange={handleChange}
                className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                  !edit && "opacity-70"
                }`}
                readOnly={!edit}
                required={true}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="aboutDescription">Description</label>
              <div className="min-h-fit h-fit bg-white overflow-hidden border-b">
                <TextEditor
                  texteditor={formData?.aboutDescription}
                  setTexteditor={(value) =>
                    handleChange({ target: { name: "aboutDescription", value } })
                  }
                  disabled={!edit}
                  style={!edit && "opacity-70"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default About;
