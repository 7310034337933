import { useEffect, useRef, useState } from "react";
import {
  CameraIcon,
  PencilIcon,
  TrashIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { BgClientDetails, ProfileImg } from "../../assets/images";
import { BASE_IMAGE_URL, EMPLOYEES } from "../../constants/actions";
import uploadImage from "../../helpers/uploadImages";
import { apiClient } from "../../actions/api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { FetchData } from "../../actions/data";
import { useAuth } from "../../context/authContext";
import { useLocation, useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

const EmployeeProfile = ({ employee }) => {
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState(employee);
  const [selectedImage, setSelectedImage] = useState(
    employee?.avatar ? BASE_IMAGE_URL + employee?.avatar : ProfileImg
  );
  const [selectedServices, setSelectedServices] = useState([]);

  const [availabilities, setAvailabilities] = useState([]);

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { isSuperAdmin } = useAuth();
  const user = useSelector((state) => state?.auth?.user);
  const data = useSelector((state) => state?.data);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (employee) {
      setFormData(employee);
      setSelectedImage(
        employee?.avatar ? BASE_IMAGE_URL + employee?.avatar : ProfileImg
      );
      if (employee?.services) {
        setSelectedServices(employee.services.map((service) => service.id));
      }
      if (employee?.emp_availabilities) {
        setAvailabilities(employee.emp_availabilities);
      }
    }
  }, [employee]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const ToggleEdit = () => {
    setEdit(!edit);
    setFormData(employee);
    setSelectedImage(
      employee?.avatar ? BASE_IMAGE_URL + employee?.avatar : ProfileImg
    );
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChangeAvatar = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setFormData({ ...formData, avatar: file });
    }
  };

  const handleServiceChange = (e) => {
    const serviceId = parseInt(e.target.value);
    setSelectedServices((prev) =>
      e.target.checked
        ? [...prev, serviceId]
        : prev.filter((id) => id !== serviceId)
    );
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      services: selectedServices,
    }));
  }, [selectedServices]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let newPath = false;
      if (formData?.avatar && formData?.avatar !== employee?.avatar) {
        newPath = await uploadImage(
          "avatar",
          { avatar: formData?.avatar },
          { avatar: employee?.avatar },
          "employees"
        );
      }

      const data = {
        ...formData,
        avatar: newPath ? newPath : formData?.avatar,
      };

      const response = await apiClient().put(
        `/employees/${employee?.id}`,
        data
      );
      if (response?.status === 200) {
        toast.success(
          "Les informations de l'employé ont été modifiées avec succès."
        );
        isSuperAdmin
          ? await dispatch(FetchData("/employees", EMPLOYEES))
          : await dispatch(FetchData(`/salonEmployees/${user?.id}`, EMPLOYEES));
        setEdit(false);
        setSelectedImage(BASE_IMAGE_URL + response?.data?.avatar);
      }
    } catch (err) {
      toast.error("Échec de la modification des informations de l'employé.");
      console.error(err);
    }
  };

  const DeleteEmployee = async (id) => {
    const confirmDelete = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cet employé ?"
    );
    if (confirmDelete) {
      try {
        await apiClient().delete(`/employees/${id}`);
        isSuperAdmin
          ? await dispatch(FetchData("/employees", EMPLOYEES))
          : await dispatch(FetchData(`/salonEmployees/${user?.id}`, EMPLOYEES));
        navigate("/parametres/employees");
        toast.success("L'employé a été supprimé avec succès.");
      } catch (err) {
        toast.error("Échec de la suppression de l'employé.");
        console.error(err);
      }
    }
  };

  const formatTimeToString = (date) => {
    if (!date) return "";
    return date instanceof Date
      ? `${String(date.getHours()).padStart(2, "0")}:${String(
          date.getMinutes()
        ).padStart(2, "0")}`
      : date;
  };

  const handleAddAvailability = () => {
    if (availabilities.length >= 7) {
      toast.warning(
        "Vous ne pouvez pas ajouter plus de 7 jours de disponibilité"
      );
      return;
    }

    // Check if current availabilities have empty fields
    const hasEmptyFields = availabilities.some(
      (av) => !av.day_of_week || !av.start_time || !av.end_time
    );

    if (hasEmptyFields) {
      toast.warning(
        "Veuillez remplir tous les champs de disponibilité actuels avant d'en ajouter un nouveau"
      );
      return;
    }

    setAvailabilities([
      ...availabilities,
      { day_of_week: "", start_time: "", end_time: "" },
    ]);
  };

  const validateAvailabilities = () => {
    // Check for duplicated days
    const selectedDays = availabilities
      .map((a) => a.day_of_week)
      .filter((day) => day !== "");
    const uniqueDays = new Set(selectedDays);

    if (selectedDays.length !== uniqueDays.size) {
      toast.warning("Vous avez sélectionné le même jour plusieurs fois");
      return false;
    }

    // Check for empty fields in availabilities
    for (const availability of availabilities) {
      if (
        !availability.day_of_week ||
        !availability.start_time ||
        !availability.end_time
      ) {
        toast.warning("Veuillez remplir tous les champs de disponibilité");
        return false;
      }
    }

    return true;
  };

  const handleAvailabilityChange = (index, field, value) => {
    const updatedAvailabilities = [...availabilities];

    if (field === "start_time" || field === "end_time") {
      // Store time as HH:mm string
      updatedAvailabilities[index][field] = formatTimeToString(value);
    } else {
      updatedAvailabilities[index][field] = value;
    }

    setAvailabilities(updatedAvailabilities);
  };

  // Helper function to parse time string to Date object for DatePicker
  const parseTimeString = (timeString) => {
    if (!timeString) return "";
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  };

  return (
    <div className="mx-auto p-6 space-y-8 bg-white shadow-lg rounded-xl">
      <div className="relative h-48 bg-gradient-to-r from-yellow-500 to-orange-600 rounded-xl overflow-hidden">
        <div className="absolute top-12 left-4 flex items-end">
          <div className="relative w-28 h-28 bg-white rounded-full p-1">
            <img
              src={selectedImage}
              alt="Avatar"
              className="w-full h-full object-cover rounded-full"
            />
            {edit && (
              <label
                htmlFor="avatar-upload"
                className="absolute bottom-0 right-0 bg-orange-500 text-white p-2 rounded-full cursor-pointer hover:bg-orange-600 transition-colors"
              >
                <CameraIcon className="w-5 h-5" />
              </label>
            )}
            <input
              id="avatar-upload"
              type="file"
              ref={fileInputRef}
              onChange={handleChangeAvatar}
              className="hidden"
            />
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <InputField
            label="Prénom"
            name="first_name"
            value={formData?.first_name || ""}
            onChange={handleChange}
            disabled={!edit}
            required
          />
          <InputField
            label="Nom"
            name="last_name"
            value={formData?.last_name || ""}
            onChange={handleChange}
            disabled={!edit}
            required
          />
          <InputField
            label="Email"
            name="email"
            type="email"
            value={formData?.email || ""}
            onChange={handleChange}
            disabled={!edit}
            required
          />
          <InputField
            label="Téléphone"
            name="phone"
            type="tel"
            value={formData?.phone || ""}
            onChange={handleChange}
            disabled={!edit}
          />
          <InputField
            label="Date de Naissance"
            name="birthday"
            type="date"
            value={formData?.birthday || ""}
            onChange={handleChange}
            disabled={!edit}
          />
          <InputField
            label="Code Postal"
            name="code_postal"
            value={formData?.code_postal || ""}
            onChange={handleChange}
            disabled={!edit}
          />
          <div className="space-y-4">
            <label className="block text-lg font-semibold text-gray-800">
              Services
            </label>
            <div className="flex flex-wrap gap-4">
              {data?.services?.length === 0 && (
                <div className="flex flex-wrap gap-4 md:justify-center lg:justify-between items-center justify-center p-4">
                  <div className="flex items-center space-x-2 text-orange-500">
                    <ExclamationCircleIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                    <span>Aucun service</span>
                  </div>
                  <button
                    onClick={() =>
                      navigate("/parametres/addservices", {
                        state: { prevPath: location?.pathname },
                      })
                    }
                    className="flex items-center px-4 py-2 text-white bg-green-600 rounded-lg hover:bg-green-700 transition duration-200"
                  >
                    <svg
                      className="h-5 w-5 mr-2" // Icon size and spacing
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3a1 1 0 011 1v6h6a1 1 0 110 2h-6v6a1 1 0 11-2 0v-6H3a1 1 0 110-2h6V4a1 1 0 011-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Ajouter un service maintenant
                  </button>
                </div>
              )}
              {data?.services?.map((service) => (
                <label
                  key={service.id}
                  className="flex items-center p-3 border border-orange-400 rounded-lg cursor-pointer bg-white hover:bg-orange-100 transition-colors duration-300 shadow-sm"
                >
                  <input
                    type="checkbox"
                    name="services"
                    value={service.id}
                    onChange={handleServiceChange}
                    checked={selectedServices.find((s) => s === service.id)} // Updated logic here
                    disabled={!edit}
                    className="mr-3 accent-orange-500"
                  />
                  <span className="text-gray-800 font-medium">
                    {service.name}
                  </span>
                </label>
              ))}
            </div>
          </div>
          {/* Availabilities Section */}
          <div className="space-y-4">
            <label className="block text-lg font-semibold text-gray-800">
              Disponibilités
            </label>
            {availabilities.map((availability, index) => (
              <div
                key={index}
                className="grid grid-cols-1 md:grid-cols-3 gap-4"
              >
                <select
                  name="day_of_week"
                  value={availability.day_of_week}
                  onChange={(e) =>
                    handleAvailabilityChange(
                      index,
                      "day_of_week",
                      e.target.value
                    )
                  }
                  className="p-2 border border-gray-300 rounded-md"
                >
                  <option value="">Sélectionnez le jour</option>
                  <option value="lundi">Lundi</option>
                  <option value="mardi">Mardi</option>
                  <option value="mercredi">Mercredi</option>
                  <option value="jeudi">Jeudi</option>
                  <option value="vendredi">Vendredi</option>
                  <option value="samedi">Samedi</option>
                  <option value="dimanche">Dimanche</option>
                </select>

                <DatePicker
                  selected={parseTimeString(availability.start_time)}
                  onChange={(time) =>
                    handleAvailabilityChange(index, "start_time", time)
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Heure de début"
                  dateFormat="HH:mm"
                  className="w-full p-2 border border-gray-300 rounded-md"
                />

                <DatePicker
                  selected={parseTimeString(availability.end_time)}
                  onChange={(time) =>
                    handleAvailabilityChange(index, "end_time", time)
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Heure de fin"
                  dateFormat="HH:mm"
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddAvailability}
              className="mt-4 text-blue-600 hover:underline"
            >
              + Ajouter une autre disponibilité
            </button>
          </div>
        </div>

        {edit && (
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={ToggleEdit}
              className="flex items-center justify-center px-6 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors"
            >
              <XMarkIcon className="w-5 h-5 mr-2" />
              Annuler
            </button>
            <button
              type="submit"
              className="flex items-center justify-center px-6 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition-colors"
            >
              <CheckIcon className="w-5 h-5 mr-2" />
              Enregistrer
            </button>
          </div>
        )}
      </form>

      {!edit && (
        <div className="flex justify-end space-x-4">
          <button
            onClick={ToggleEdit}
            className="flex items-center justify-center px-6 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition-colors"
          >
            <PencilIcon className="w-5 h-5 mr-2" />
            Modifier
          </button>
          {isSuperAdmin && (
            <button
              onClick={() => DeleteEmployee(employee?.id)}
              className="flex items-center justify-center px-6 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
            >
              <TrashIcon className="w-5 h-5 mr-2" />
              Supprimer
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const InputField = ({
  label,
  name,
  value,
  onChange,
  disabled,
  type = "text",
  required,
}) => (
  <div className="space-y-1">
    <label htmlFor={name} className="block text-lg font-semibold text-gray-800">
      {label}
    </label>
    <input
      id={name}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      disabled={disabled}
      required={required}
      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-orange-500 transition-colors"
    />
  </div>
);

export default EmployeeProfile;
