import { useDispatch, useSelector } from "react-redux";
import { TextEditor } from "../../components";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { apiClient } from "../../actions/api";
import { FetchData } from "../../actions/data";
import { RGBD } from "../../constants/actions";
import CmsLayout from "../cms";
import { Check, RefreshCcw } from "lucide-react";
import { PencilIcon, XCircleIcon } from "@heroicons/react/24/solid";

const RgbdPage = () => {
  const rgbd = useSelector((state) => state?.data?.rgbd);
  const [formData, setFormData] = useState();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (rgbd) {
      setFormData(rgbd);
    }
  }, [rgbd]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const ResetForm = () => {
    setFormData(rgbd);
  };

  const ToggleEdit = () => {
    setEdit(!edit);
    ResetForm();
  };

  const valider = async (e) => {
    e.preventDefault();
    if (JSON.stringify(rgbd) === JSON.stringify(formData)) {
      toast.info("Aucune modification détectée.");
      return;
    }
    try {
      await apiClient().put(`/rgbd/1`, formData);
      toast.success("Page Conditions est modifiée avec succès", {
        autoClose: 600,
      });
      dispatch(FetchData("/rgbd", RGBD));
    } catch (err) {
      console.error(
        "Erreur lors de la mise à jour des données de Page rgbd :",
        err
      );
      toast.error("Erreur lors de la mise à jour des données de Page rgbd", {
        autoClose: 600,
      });
    }
  };

  return (
    <CmsLayout>
      <div className="p-5 min-h-screen">
        <div className=" my-4 flex items-center justify-start gap-3">
          <button
            type="button"
            onClick={ToggleEdit}
            className={`flex items-center justify-center gap-2 min-w-20 h-8 px-4 font-semibold rounded-md shadow-md transition-colors duration-200 ${
              edit
                ? "bg-gray-400 text-white hover:bg-gray-500"
                : "bg-orange-primary text-white hover:bg-orange-600"
            }`}
          >
            {edit ? (
              <>
                <XCircleIcon className="w-4 h-4" />
                Annuler
              </>
            ) : (
              <>
                <PencilIcon className="w-4 h-4" />
                Modifier
              </>
            )}
          </button>
        </div>
        <form
          onSubmit={valider}
          className="space-y-4 p-2 border rounded-lg bg-white"
        >
          <div className="flex items-center justify-end ">
            {edit && (
              <div className="flex items-center justify-end">
                <div className="flex items-center gap-3">
                  <button
                    type="button"
                    onClick={ResetForm}
                    className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-gray-500 hover:bg-gray-600"
                  >
                    <RefreshCcw className="w-4 h-4 md:h-6 md:w-6" />
                  </button>
                  <button
                    type="submit"
                    className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-blue-900 hover:bg-blue-950"
                  >
                    <Check className="w-4 h-4 md:h-6 md:w-6" />
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="">
            <div className="h-fit bg-white overflow-hidden border-b">
              <TextEditor
                texteditor={formData?.contenue}
                setTexteditor={(value) =>
                  handleChange({ target: { name: "contenue", value } })
                }
                disabled={!edit}
                style={!edit && "opacity-70"}
              />
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </CmsLayout>
  );
};

export default RgbdPage;
