import React, { useEffect, useState } from "react";
import { ComeBackButton, DemandeDetailsInfos, Layout } from "../../components";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { apiClient } from "../../actions/api";
import { toast, ToastContainer } from "react-toastify";
import { FetchData } from "../../actions/data";
import { COMPLETEDSALONS, DEMANDES, SALONS } from "../../constants/actions";

const DemandesDetails = () => {
  const { id } = useParams();
  const demandes = useSelector((state) => state?.data?.demandes);
  const [demande, setDemande] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNaN(id)) {
      navigate(location?.state?.prevPath || "/demandes");
      return;
    }
    const dmd = demandes?.find((dmd) => dmd?.id === parseInt(id));
    if (demandes?.length > 0 && !dmd) {
      navigate(location?.state?.prevPath || "/demandes");
      return;
    }
    setDemande(dmd);
  }, [id, demandes]);

  const handleDecision = async (decision) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir procéder avec cette décision ?"
    );

    if (!confirmed) {
      return;
    }

    if (decision) {
      try {
        await apiClient().put(`/users/${demande?.id}`, {
          status: decision,
        });
  
        await apiClient().put(`/salons/${demande?.salon?.id}`, {
          status: decision,
        });
        dispatch(FetchData('/disabledUsers', DEMANDES));
        dispatch(FetchData('/salons', SALONS));
        toast.success("La demande est bien acceptée");
        dispatch(FetchData('/completedSalons', COMPLETEDSALONS));
        
        navigate(location?.state?.prevPath || "/demandes");
      } catch (err) {
        console.error("Error while processing the request:", err);
        toast.error("Erreur lors du traitement de la demande");
      }
    }else{
      try {
        await apiClient().delete(`/users/${demande?.id}`, {
          status: decision,
        });
  
        await apiClient().delete(`/salons/${demande?.salon?.id}`);
        dispatch(FetchData('/disabledUsers', DEMANDES));
        toast.success("La demande est bien Refusé");
        navigate(location?.state?.prevPath || "/demandes");
      } catch (err) {
        console.error("Error while processing the request:", err);
        toast.error("Erreur lors du traitement de la demande");
      }
    }
  };

  return (
    <Layout>
      <div className="p-1">
        <ComeBackButton prev={location?.state?.prevPath} next={"/demandes"} />
        <DemandeDetailsInfos
          demande={demande}
          handleDecision={handleDecision}
        />
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default DemandesDetails;
