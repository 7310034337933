import { useLocation, useNavigate, useParams } from "react-router";
import { ComeBackButton, EmployeeProfile, ParametresLayout } from "../../components";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

const EmployesDetails = () => {
  const { id } = useParams();
  const employees = useSelector((state) => state?.data?.employees) || [];
  const [employee, setEmployee] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isNaN(id)) {
      navigate("/parametres/employees");
      return;
    }
    const empl = employees?.find((emp) => emp?.id === parseInt(id));
    if (employees?.length > 0 && !empl) {
      navigate("/parametres/employees");
      return;
    }
    setEmployee(empl);
  }, [id, employees]);

  return (
    <ParametresLayout>
      <div className="p-1">
        <ComeBackButton prev={location?.state?.prevPath} next={"/parametres/employees"} />
        <EmployeeProfile employee={employee} />
      </div>
      <ToastContainer/>
    </ParametresLayout>
  );
};

export default EmployesDetails;
