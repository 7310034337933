import {
  Check,
  ChevronLeft,
  ChevronRight,
  Eye,
  EyeOff,
  RefreshCcw,
  Trash2,
} from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextEditor from "../globalcomponents/TextEditor";
import { FAQS } from "../../constants/actions";
import { apiClient } from "../../actions/api";
import { FetchData } from "../../actions/data";
import { toast } from "react-toastify";

const Faqs = ({
  formData,
  handleChange,
  SubmitHandler,
  ResetHandler,
  edit,
}) => {
  const faqs = useSelector((state) => state?.data?.faqs);
  const [faqsData, setFaqsData] = useState(faqs);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    setFaqsData(faqs);
  }, [faqs]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getAvisSlide = useCallback(() => {
    if (windowWidth >= 768) return 2;
    return 1;
  }, [windowWidth]);

  useEffect(() => {
    const imagesPerSlide = getAvisSlide();
    const maxIndex = Math.max(0, Math.ceil(faqsData?.length / imagesPerSlide) - 1);
    setCurrentIndex(prevIndex => Math.min(prevIndex, maxIndex));
  }, [faqsData, getAvisSlide]);

  const nextSlide = () => {
    const imagesPerSlide = getAvisSlide();
    const maxIndex = Math.ceil(faqsData?.length / imagesPerSlide) - 1;
    if (currentIndex < maxIndex) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    try {
      let isChanged = false;
      const changedFaqs = faqsData?.filter((faq) => {
        const originalFaq = faqs?.find((quest) => quest?.id === faq?.id);
        if (JSON.stringify(faq) !== JSON.stringify(originalFaq)) {
          isChanged = true;
          return faq;
        }
      });

      SubmitHandler(e, ["titleFaqs"], isChanged);

      if (isChanged) {
        for (const faq of changedFaqs) {
          await apiClient().put(`/questions/${faq?.id}`, {
            question: faq?.question,
            reponse: faq?.reponse,
          });
        }
        await dispatch(FetchData("/questions", FAQS));
      }
    } catch (err) {
      console.error(err);
      toast.error("Failed to update FAQs");
    }
  };

  const ResetFunc = () => {
    ResetHandler(["titleFaqs"]);
    setFaqsData(faqs);
  };

  const handleFaqsChange = useCallback(
    (id, field, value) => {
      setFaqsData((prevFaqsData) =>
        prevFaqsData?.map((item) =>
          item.id === id ? { ...item, [field]: value } : item
        )
      );
    },
    []
  );

  const toggleStatus = async (faq) => {
    try {
      await apiClient().put(`/questions/${faq?.id}`, {
        status: !faq?.status,
      });
      toast.success(`Cette question est bien ${faq?.status ? 'cachée' : 'publiée'}`);
      await dispatch(FetchData('/questions', FAQS));
    } catch (err) {
      console.error(err);
      toast.error("Failed to toggle FAQ status");
    }
  };

  const DeleteFaq = async (faq) => {
    if(window.confirm('ets vous sure de vouloir supprimer cette question')){
      try {
        await apiClient().delete(`/questions/${faq?.id}`, {
          status: !faq?.status,
        });
        toast.success(`Cette question est bien supprimé`);
        await dispatch(FetchData('/questions', FAQS));
      } catch (err) {
        console.error(err);
        toast.error("Failed to delete FAQ");
      }
    }
  }

  return (
    <form onSubmit={SubmitForm}>
      <div className="flex items-center justify-between">
        <h1 className="text-lg md:text-xl lg:text-2xl font-semibold my-2">
          Section 5
        </h1>
      </div>
      <div className="space-y-4 p-2 border rounded-lg bg-white">
        {edit && (
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              onClick={ResetFunc}
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-gray-500 hover:bg-gray-600"
            >
              <RefreshCcw className="w-4 h-4 md:h-6 md:w-6" />
            </button>
            <button
              type="submit"
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-blue-900 hover:bg-blue-950"
            >
              <Check className="w-4 h-4 md:h-6 md:w-6" />
            </button>
          </div>
        )}
        <div className="space-y-2">
          <div className="flex flex-col gap-2">
            <label htmlFor="titleFaqs">Titre section 5</label>
            <input
              type="text"
              name="titleFaqs"
              id="titleFaqs"
              placeholder="Titre section 5"
              value={formData?.titleFaqs || ""}
              onChange={handleChange}
              className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                !edit && "opacity-70"
              }`}
              readOnly={!edit}
              required={true}
            />
          </div>
          <div className="space-y-4">
            <div className="overflow-hidden">
              <div
                className="flex transition-transform duration-300 ease-in-out"
                style={{
                  transform: `translateX(-${currentIndex * 100}%)`,
                }}
              >
                {Array.from({
                  length: Math.ceil(faqsData?.length / getAvisSlide()),
                }).map((_, slideIndex) => (
                  <div
                    key={`slide-${slideIndex}`}
                    className="w-full flex-shrink-0 flex gap-2"
                  >
                    {faqsData
                      ?.slice(
                        slideIndex * getAvisSlide(),
                        (slideIndex + 1) * getAvisSlide()
                      )
                      ?.map((item) => (
                        <div
                          key={item.id}
                          className={`relative space-y-2 border rounded-md ${
                            getAvisSlide() === 1
                              ? "w-full"
                              : getAvisSlide() === 2
                              ? "w-1/2"
                              : getAvisSlide() === 3
                              ? "w-1/3"
                              : "w-1/4"
                          }`}
                        >
                          <div className="p-2 border-b flex items-center justify-between">
                            <div className="">
                              <h3 className="font-semibold text-sm md:text-base">
                                {item?.name}
                              </h3>
                              <p className="text-xs md:text-sm">
                                {item?.email}
                              </p>
                            </div>
                            <div className="flex items-center justify-center gap-2">
                              <button
                                type="button"
                                className="p-1 text-blue-500"
                                onClick={() => toggleStatus(item)}
                              >
                                {item?.status ? <Eye /> : <EyeOff />}
                              </button>
                              <button
                                type="button"
                                className="p-1 text-red-400"
                                onClick={() => DeleteFaq(item)}
                              >
                                <Trash2 />
                              </button>
                            </div>
                          </div>
                          <div className="p-2">
                            <div className="flex flex-col gap-1">
                              <label htmlFor={`faq${item?.id}`}>Question</label>
                              <textarea
                                id={`faq${item?.id}`}
                                value={item?.question}
                                onChange={(e) =>
                                  handleFaqsChange(
                                    item?.id,
                                    "question",
                                    e.target.value
                                  )
                                }
                                className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                                  !edit && "opacity-70"
                                }`}
                                rows="3"
                                readOnly={!edit}
                              />
                            </div>
                            <div className="flex flex-col gap-1">
                              <label htmlFor={`reponse${item?.id}`}>
                                Reponse
                              </label>
                              <div className="min-h-fit h-fit bg-white overflow-hidden border-b">
                                <TextEditor
                                  texteditor={item?.reponse || ""}
                                  setTexteditor={(value) =>
                                    handleFaqsChange(item?.id, `reponse`, value)
                                  }
                                  disabled={!edit}
                                  style={!edit ? "opacity-70" : ""}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
            {faqsData?.length > 0 && (
              <div className="flex items-center justify-end gap-4">
                <div className="font-bold">
                  Slide {currentIndex + 1} /{" "}
                  {Math.max(1, Math.ceil(faqsData?.length / getAvisSlide()))}
                </div>
                <div className="flex gap-3">
                  <button
                    type="button"
                    onClick={prevSlide}
                    className={`p-3 rounded-full overflow-hidden ${
                      currentIndex === 0
                        ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                        : "bg-gray-300 text-gray-800 hover:bg-gray-800 hover:text-white"
                    }`}
                    disabled={currentIndex === 0}
                  >
                    <ChevronLeft className="w-5 h-5 md:h-7 md:w-7" />
                  </button>
                  <button
                    type="button"
                    onClick={nextSlide}
                    className={`p-3 rounded-full overflow-hidden ${
                      currentIndex ===
                      Math.ceil(faqsData?.length / getAvisSlide()) - 1
                        ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                        : "bg-gray-300 text-gray-800 hover:bg-gray-800 hover:text-white"
                    }`}
                    disabled={
                      currentIndex ===
                      Math.ceil(faqsData?.length / getAvisSlide()) - 1
                    }
                  >
                    <ChevronRight className="w-5 h-5 md:h-7 md:w-7" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Faqs;