import { useEffect, useState } from "react";
import FormattingDate from "../../helpers/FormattingDate";
import { apiClient } from "../../actions/api";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { FetchData } from "../../actions/data";
import { SERVICES } from "../../constants/actions";
import { useAuth } from "../../context/authContext";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import "./style.css";

const ServiceDetailsCpt = ({ service }) => {
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({});
  const user = useSelector((state) => state?.auth?.user);
  const dispatch = useDispatch();
  const { isSuperAdmin } = useAuth();

  useEffect(() => {
    if (service) {
      const duration = service?.pivot?.duration
        ? dayjs(service?.pivot?.duration, "HHmm").isValid()
          ? dayjs(service?.pivot?.duration, "HHmm").format("HH:mm")
          : dayjs("00:30", "HH:mm").format("HH:mm")
        : dayjs("00:30", "HH:mm").format("HH:mm");

      setFormData({
        id: service?.id,
        name: service?.name || "",
        price: service?.pivot?.price || "",
        duration,
        description: service?.pivot?.description || "",
        salon_id: service?.pivot?.salon_id || "",
        service_id: service?.pivot?.service_id || "",
        created_at: service?.created_at,
      });
    }
  }, [service]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const ToggleEdit = () => {
    setEdit(!edit);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updateData = async () => {
      try {
        const duration = dayjs(formData?.duration, "HH:mm").format("HHmm");
        if (isSuperAdmin) {
          const response = await apiClient().put(`/services/${service?.id}`, {
            name: formData?.name,
          });
          if (response?.status === 200) {
            toast.success(
              "Les informations du service ont été modifiées avec succès."
            );
            dispatch(FetchData(`/services`, SERVICES));
          }
        } else {
          const payload = {
            salon_id: formData?.salon_id,
            pivot: {
              price: formData?.price,
              duration,
              description: formData?.description,
            },
          };

          const response = await apiClient().put(
            `/services/${service?.id}/update-pivot`,
            payload
          );

          if (response?.status === 200) {
            toast.success(
              "Les informations du service ont été modifiées avec succès."
            );
            dispatch(FetchData(`/salonServices/${user?.id}`, SERVICES));
          }
        }
        setEdit(false);
      } catch (err) {
        toast.error("Échec de la modification des informations du service.");
        console.error(err);
      }
    };
    updateData();
  };

  const handleTimeChange = (time) => {
    const formattedTime = time ? dayjs(time).format("HH:mm") : null;
    setFormData((prevState) => ({
      ...prevState,
      duration: formattedTime,
    }));
  };

  return (
    <div className="space-y-8 p-3 text-sm md:text-base">
      <div className="flex items-center justify-between">
        <div className="text-gray-600">
          {service?.created_at && (
            <span>Créé le {FormattingDate(service?.created_at)}</span>
          )}
        </div>
        <button
          onClick={ToggleEdit}
          className={`min-w-20 h-7 font-semibold ${
            edit ? "bg-gray-400 text-white" : "bg-orange-primary text-white"
          }`}
        >
          {edit ? "Annuler" : "Modifier"}
        </button>
      </div>
      <form
        className="grid grid-cols-1 md:grid-cols-3 gap-3"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col gap-2">
          <label htmlFor="name">Nom</label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData?.name}
            onChange={handleChange}
            className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
              !edit && "opacity-70"
            }`}
            readOnly={isSuperAdmin ? !edit : true}
          />
        </div>
        {!isSuperAdmin && (
          <>
            <div className="flex flex-col gap-2">
              <label htmlFor="price">Prix</label>
              <input
                type="number"
                name="price"
                id="price"
                value={formData?.price}
                onChange={handleChange}
                className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                  !edit && "opacity-70"
                }`}
                readOnly={!edit}
                step="0.01"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="duration">Durée</label>
              <TimePicker
                value={
                  formData?.duration ? dayjs(formData.duration, "HH:mm") : null
                }
                onChange={handleTimeChange}
                format="HH:mm"
                className={`w-full p-2 border rounded-md outline-none focus:outline-none font-semibold`}
                use12Hours={false}
                showNow={false}
                inputReadOnly={true}
                hideDisabledOptions={true}
                minuteStep={5}
                disabled={!edit}
                disabledHours={() =>
                  Array.from({ length: 17 }, (_, i) => i + 8)
                }
              />
            </div>
            <div className="flex flex-col gap-2 col-span-1 md:col-span-3">
              <label htmlFor="description">Description</label>
              <textarea
                name="description"
                id="description"
                value={formData?.description}
                onChange={handleChange}
                readOnly={!edit}
                className={`w-full p-2 min-h-24 border rounded-md outline-none focus:outline-none ${
                  !edit && "opacity-70"
                }`}
              ></textarea>
            </div>
          </>
        )}
        {edit && (
          <div className="flex justify-end gap-2 col-span-1 md:col-span-3">
            <button
              type="submit"
              className="bg-orange-primary text-white border-lightOrange rounded-md px-4 py-1 hover:border-lightOrange hover:text-orange-primary hover:bg-white"
              style={{ border: "1px solid" }}
            >
              Valider
            </button>
          </div>
        )}
      </form>
      <ToastContainer />
    </div>
  );
};

export default ServiceDetailsCpt;
