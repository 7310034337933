import { useSelector } from 'react-redux'
import { UsersListe } from '../../components'
import ParametresLayout from '../../components/parametresComponents/ParametresLayout'
import { useEffect, useState } from 'react';

const sortOptions = ["Date Création", "Nom"];
const filterOptions = ["Tout", "Admins", "Clients"];

const Users = () => {
  const users = useSelector(state => state?.data?.users);
  const [filtredUsers, setFiltredUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortOption, setSortOption] = useState("Date Création");
  const [filterOption, setFilterOption] = useState("Tout");

  useEffect(()=>{
    if(users?.length){
      let filter;
      if(filterOption === "Tout"){
        filter = users?.filter(user => user?.role !== 'super_admin');
      }else if(filterOption === "Admins"){
        const filtred = users?.filter((user) => user?.role === 'admin');
        filter = filtred;
      }else{
        const filtred = users?.filter((user) => user?.role === 'client');
        filter = filtred;
      }
      if(sortOption === "Date création"){
        const sortedByDate = [...filter]?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));
        filter = sortedByDate;
      }else{
        const sortedByName = [...filter]?.sort((a, b) => {
          const firstNameComparison = a.first_name.localeCompare(b.first_name);
    
          if (firstNameComparison === 0) {
            return a.last_name.localeCompare(b.last_name);
          }
          return firstNameComparison;
        });
        filter = sortedByName;
      }
      setFiltredUsers(filter);
    }
  },[sortOption, filterOption, users])

  useEffect(() => {
    if (!searchInput) {
      setFiltredUsers(users?.filter(user => user?.role !== 'super_admin'));
      return;
    }

    const searchLowerCase = searchInput.trim().toLowerCase();

    const filtered = users?.filter((user) => {
      const fullName = `${user?.first_name} ${user?.last_name}`.toLowerCase();
      const reversedFullName = `${user?.last_name} ${user?.first_name}`.toLowerCase();
      const firstName = user?.first_name.toLowerCase();
      const lastName = user?.last_name.toLowerCase();
      const email = user?.email.toLowerCase();

      return (
        firstName.includes(searchLowerCase) ||
        lastName.includes(searchLowerCase) ||
        fullName.includes(searchLowerCase) ||
        reversedFullName.includes(searchLowerCase) ||
        email.includes(searchLowerCase)
      );
    });

    setFiltredUsers(filtered?.filter(user => user?.role !== 'super_admin'));
  }, [searchInput, users]);

  return (
    <ParametresLayout>
        <div className="p-1 py-5 min-h-screen">
          <UsersListe
            sortOptions={sortOptions}
            sortOption={sortOption} setSortOption={setSortOption}
            filterOptions={filterOptions}
            filterOption={filterOption} setFilterOption={setFilterOption}
            users={filtredUsers} itemsPerPage={10}
            searchInput={searchInput} setSearchInput={setSearchInput}
          />
        </div>
    </ParametresLayout>
  )
}

export default Users