import { Check, RefreshCcw } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import uploadImage from "../../helpers/uploadImages";
import { BASE_IMAGE_URL } from "../../constants/actions";

const Salons = ({
  formData,
  handleChange,
  SubmitHandler,
  ResetHandler,
  edit,
}) => {
  const completedSalons = useSelector((state) => state?.data?.completedSalons);
  const [salons, setSalons] = useState(completedSalons);
  const [selectedSalons, setSelectedSalons] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [previewImage, setPreviewImage] = useState(BASE_IMAGE_URL + formData?.imageBanner || "");
  const [loading, setLoading] = useState(false); // Loading state for image upload

  useEffect(() => {
    setSalons(completedSalons);
  }, [completedSalons]);

  useEffect(() => {
    if (formData?.topSalons) {
      const topSalonsArray = formData?.topSalons
        ?.split(",")
        ?.map((id) => parseInt(id.trim()));
      setSelectedSalons(topSalonsArray);
    }
  }, [formData?.topSalons]);

  const fields = ["titleSalons", "topSalons", "imageBanner"];

  const SubmitForm = (e) => {
    e.preventDefault();
    SubmitHandler(e, fields);
  };

  const ResetFunc = () => {
    ResetHandler(fields);
  };

  const handleSalonSelection = (salonId) => {
    setSelectedSalons((prevSelected) => {
      if (prevSelected?.includes(salonId)) {
        return prevSelected?.filter((id) => id !== salonId);
      } else if (prevSelected?.length < 4) {
        return [...prevSelected, salonId];
      }
      return prevSelected;
    });
  };

  useEffect(() => {
    const topSalonsString = selectedSalons ? selectedSalons.join(",") : null;
    handleChange({
      target: {
        name: "topSalons",
        value: topSalonsString,
      },
    });
  }, [selectedSalons]);

  const filterSalons = useCallback(() => {
    let result = completedSalons || [];
    if (searchInput) {
      const searchLowerCase = searchInput.trim().toLowerCase();
      result = result?.filter((salon) => {
        const name = salon?.name?.toLowerCase() || "";
        const email = salon?.email?.toLowerCase() || "";
        const ville = salon?.city?.toLowerCase() || "";
        return (
          name?.includes(searchLowerCase) ||
          email?.includes(searchLowerCase) ||
          ville?.includes(searchLowerCase)
        );
      });
    }
    setSalons(result);
  }, [searchInput, completedSalons]);

  useEffect(() => {
    filterSalons();
  }, [searchInput, completedSalons, filterSalons]);

  const SelectAuto = () => {
    setSelectedSalons([]);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl); // Show preview
      setLoading(true); // Show loading spinner

      try {
        const newPath = await uploadImage(
          "image",
          { image: file },
          { image: "" },
          "acceuil"
        );
        handleChange({ target: { name: "imageBanner", value: newPath } }); // Update formData with the new path
      } catch (error) {
        console.error("Image upload failed:", error);
      } finally {
        setLoading(false); // Hide loading spinner
      }
    }
  };

  return (
    <form onSubmit={SubmitForm}>
      <div className="flex items-center justify-between">
        <h1 className="text-lg md:text-xl lg:text-2xl font-semibold my-2">
          Changer l'image d'accueil 
        </h1>
      </div>
      <div className="space-y-4 p-2 border rounded-lg bg-white">
        {edit && (
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              onClick={ResetFunc}
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-gray-500 hover:bg-gray-600 rounded"
            >
              <RefreshCcw className="w-4 h-4 md:h-6 md:w-6" />
            </button>
            <button
              type="submit"
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-blue-900 hover:bg-blue-950 rounded"
            >
              <Check className="w-4 h-4 md:h-6 md:w-6" />
            </button>
          </div>
        )}
        <div className="space-y-5">
          <div className="flex flex-col gap-2">
            {previewImage && (
              <div className="flex items-center justify-center mb-3">
                <img
                  src={previewImage}
                  alt="Preview"
                  className="w-full max-w-xs h-auto rounded-lg border"
                />
              </div>
            )}
            {loading && (
              <div className="flex items-center justify-center mb-3">
                <span className="loader">Loading...</span> {/* Your loader spinner */}
              </div>
            )}
            {edit && (
              <input
                type="file"
                accept="image/*"
                id="imageUpload"
                onChange={handleImageChange}
                className="w-full p-2 border rounded-md outline-none focus:outline-none"
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <h1 className="text-lg md:text-xl lg:text-2xl font-semibold my-2">
          Section 1
        </h1>
      </div>
      <div className="space-y-4 p-2 border rounded-lg bg-white">
        {edit && (
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              onClick={ResetFunc}
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-gray-500 hover:bg-gray-600"
            >
              <RefreshCcw className="w-4 h-4 md:h-6 md:w-6" />
            </button>
            <button
              type="submit"
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-blue-900 hover:bg-blue-950"
            >
              <Check className="w-4 h-4 md:h-6 md:w-6" />
            </button>
          </div>
        )}
        <div className="space-y-5">
          <div className="flex flex-col gap-2">
            <label htmlFor="titleSalons">Titre section 1</label>
            <input
              type="text"
              name="titleSalons"
              id="titleSalons"
              placeholder="Titre section 2"
              value={formData?.titleSalons || ""}
              onChange={handleChange}
              className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                !edit && "opacity-70"
              }`}
              readOnly={!edit}
              required={true}
            />
          </div>
          <div className="space-y-3">
            <div className="flex items-center justify-between">
              <div className="">
                <div className="min-w-72">
                  <input
                    type="text"
                    name="nom"
                    id="nom"
                    placeholder="salon, email, ville"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="w-full py-1 px-3 border rounded-md outline-none focus:outline-none"
                  />
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  disabled={!edit}
                  type="button"
                  className={`flex items-center gap-2 py-2 px-4 bg-orange-500 text-white rounded-md shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-300 ${
                    !edit && "opacity-50 cursor-not-allowed"
                  }`}
                  onClick={SelectAuto}
                >
                  <CheckCircleIcon className="w-5 h-5" />
                  Sélectionner les meilleurs salons
                </button>
              </div>
            </div>
            <div className="h-fit max-h-60 rounded-md overflow-y-auto">
              <table className="w-full text-center rounded-lg border">
                <thead className="bg-gray-100 border-b">
                  <tr>
                    <th className="p-2"></th>
                    <th className="p-2">ID</th>
                    <th className="p-2">Salon</th>
                    <th className="p-2">Ville</th>
                    <th className="p-2">Email</th>
                  </tr>
                </thead>
                <tbody>
                  {salons?.length ? (
                    salons?.map((salon) => (
                      <tr key={salon?.id}>
                        <td className="">
                          <input
                            type="checkbox"
                            className="cursor-pointer"
                            checked={selectedSalons?.includes(salon?.id)}
                            onChange={() => handleSalonSelection(salon?.id)}
                            disabled={
                              (!selectedSalons?.includes(salon?.id) &&
                                selectedSalons?.length >= 4) ||
                              !edit
                            }
                          />
                        </td>
                        <td className="p-1">#{salon?.id}</td>
                        <td className="p-1">{salon?.name}</td>
                        <td className="p-1">{salon?.city}</td>
                        <td className="p-1">{salon?.email}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} className="p-2">
                        Aucun Salon
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Salons;
