import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CmsLayout from "../cms";
import { ACCUEIL } from "../../constants/actions";
import { apiClient } from "../../actions/api";
import { FetchData } from "../../actions/data";
import { CategoriesAccueil, DownloadAppAccueil, FaqsAccueil, FeedbacksAccueil, ReasonsAccueil, SalonsAccueil } from "../../components";
import { Card, CardContent, CardHeader, Button, Tabs, Tab, Box, Typography, ThemeProvider, createTheme, CircularProgress } from "@mui/material";
import { HomeIcon, BuildingStorefrontIcon, ChatBubbleBottomCenterTextIcon, TagIcon, QuestionMarkCircleIcon, DevicePhoneMobileIcon, PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/outline';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(237 146 92)', // This is the color you want for the selected tab
    },
  },
});

const Accueil = () => {
  const accueil = useSelector((state) => state?.data?.accueil);
  const [formData, setFormData] = useState(accueil);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (accueil) {
      setFormData(accueil);
    }
  }, [accueil]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const ToggleEdit = () => {
    setEdit(!edit);
    setFormData(accueil);
  };

  const ResetHandler = (fields) => {
    const resetData = {};
    fields?.forEach((field) => {
      resetData[field] = accueil?.[field] || "";
    });

    setFormData((prevData) => ({
      ...prevData,
      ...resetData,
    }));
  };

  const SubmitHandler = async (e, fields, isChanged = false) => {
    e.preventDefault();
    setLoading(true);
    try {
      let isFieldChanged = false;
      let formatedData = {};
  
      for (const field of fields) {
        if (formData[field] !== accueil[field]) {
          formatedData[field] = formData[field];
          isFieldChanged = true;
        }
      }
  
      if (!isChanged && !isFieldChanged) {
        toast.info("Aucune modification détectée.");
        setLoading(false);
        return;
      }

      if(!isFieldChanged && isChanged){
        toast.success("Les informations d'accueil ont été modifiées avec succès.");
        setLoading(false);
        return;
      }
  
      if (isFieldChanged) {
        await apiClient().put(`/accueil/1`, formatedData);
        toast.success("Les informations d'accueil ont été modifiées avec succès.");
        dispatch(FetchData(`/accueil`, ACCUEIL));
      }
      
    } catch (err) {
      toast.error("Échec de la modification des informations d'accueil.");
      console.error(err);
    }
    setLoading(false);
  };  

  const sections = [
    { name: "Accueil", component: SalonsAccueil, icon: HomeIcon },
    // { name: "Salons", component: SalonsAccueil, icon: BuildingStorefrontIcon },
    { name: "Raisons", component: ReasonsAccueil, icon: ChatBubbleBottomCenterTextIcon },
    { name: "Avis", component: FeedbacksAccueil, icon: TagIcon },
    { name: "Catégories", component: CategoriesAccueil, icon: TagIcon },
    { name: "FAQs", component: FaqsAccueil, icon: QuestionMarkCircleIcon },
    { name: "Téléchargement", component: DownloadAppAccueil, icon: DevicePhoneMobileIcon },
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
<ThemeProvider theme={theme}>
      <CmsLayout>
        <Card sx={{ m: 2 }}>
          <CardHeader 
            title={<Typography variant="h5">Gestion de la page d'accueil</Typography>}
            action={
              <Button
                onClick={ToggleEdit}
                variant={edit ? "contained" : "outlined"}
                color={edit ? "error" : "primary"}
                startIcon={edit ? <XMarkIcon /> : <PencilSquareIcon />}
              >
                {edit ? "Annuler" : "Modifier"}
              </Button>
            }
          />
          <CardContent>
            {loading && (
              <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                <CircularProgress size={24} sx={{ mr: 1 }} />
                <Typography>Chargement...</Typography>
              </Box>
            )}
            <Tabs 
              value={activeTab} 
              onChange={handleTabChange} 
              variant="scrollable" 
              scrollButtons="auto"
              TabIndicatorProps={{
                style: { backgroundColor: 'rgb(237 146 92)' }
              }}
            >
              {sections.map((section, index) => (
                <Tab 
                  key={section.name} 
                  label={
                    <Box display="flex" alignItems="center">
                      <section.icon style={{ 
                        width: 20, 
                        height: 20, 
                        marginRight: 8,
                        color: activeTab === index ? 'rgb(237 146 92)' : 'inherit'
                      }} />
                      <span style={{
                        color: activeTab === index ? 'rgb(237 146 92)' : 'inherit'
                      }}>
                        {section.name}
                      </span>
                    </Box>
                  } 
                  sx={{
                    '&.Mui-selected': {
                      color: 'rgb(237 146 92)',
                    },
                  }}
                />
              ))}
            </Tabs>
            {sections.map((section, index) => (
              <Box key={section.name} hidden={activeTab !== index} mt={2}>
                <Card>
                  <CardContent>
                    <section.component
                      formData={formData}
                      handleChange={handleChange}
                      SubmitHandler={SubmitHandler}
                      ResetHandler={ResetHandler}
                      edit={edit}
                    />
                  </CardContent>
                </Card>
              </Box>
            ))}
          </CardContent>
        </Card>
        <ToastContainer />
      </CmsLayout>
    </ThemeProvider>
  );
};

export default Accueil;