import { Check, RefreshCcw } from "lucide-react";

const Categories = ({
  formData,
  handleChange,
  SubmitHandler,
  ResetHandler,
  edit,
}) => {

  const SubmitForm = (e) =>{
    SubmitHandler(e, ["titleCategories"]);
    //
  }

  const ResetFunc = () =>{
    ResetHandler(["titleCategories"]);
  }

  return (
    <form onSubmit={SubmitForm}>
      <div className="flex items-center justify-between">
        <h1 className="text-lg md:text-xl lg:text-2xl font-semibold my-2">
          Section 4
        </h1>
      </div>
      <div className="space-y-4 p-2 border rounded-lg bg-white">
        {edit && (
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              onClick={ResetFunc}
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-gray-500 hover:bg-gray-600"
            >
              <RefreshCcw className="w-4 h-4 md:h-6 md:w-6" />
            </button>
            <button
              type="submit"
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-blue-900 hover:bg-blue-950"
            >
              <Check className="w-4 h-4 md:h-6 md:w-6" />
            </button>
          </div>
        )}
        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="titleCategories">Titre section 4</label>
            <input
              type="text"
              name="titleCategories"
              id="titleCategories"
              placeholder="Titre section 4"
              value={formData?.titleCategories || ""}
              onChange={handleChange}
              className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                !edit && "opacity-70"
              }`}
              readOnly={!edit}
              required={true}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default Categories;
