import { useState, useEffect } from "react";
import { ProfileImg } from "../../assets/images";
import { BASE_IMAGE_URL } from "../../constants/actions";
import { useLocation, useNavigate } from "react-router";
import FormatChatDate from "../../helpers/FormatChatDate";

const MessagerieCpt = ({ chats }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([]);
  const itemsPerPage = 8;
  const maxVisibleButtons = 6;
  const navigate = useNavigate();
  const location = useLocation();

  const totalPages = Math.ceil(chats?.length / itemsPerPage);

  const updateVisiblePages = (page) => {
    let start = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
    let end = Math.min(totalPages, start + maxVisibleButtons - 1);

    if (end - start < maxVisibleButtons - 1) {
      start = Math.max(1, end - maxVisibleButtons + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    setVisiblePages(pages);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateVisiblePages(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    updateVisiblePages(1);
  }, [chats]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleChats = chats?.slice(startIndex, endIndex);

  const renderPageButtons = () =>
    visiblePages?.map((page) => (
      <button
        key={page}
        onClick={() => handlePageChange(page)}
        className={`w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 ${
          currentPage === page
            ? "bg-orange-primary text-white"
            : "text-gray-950 bg-gray-200"
        }`}
      >
        {page}
      </button>
    ));

  return (
    <div className="m-3 p-3 bg-white rounded-lg border min-h-screen space-y-2">
      {visibleChats?.length ? (
        visibleChats?.map((chat) => (
          <div key={chat?.id}>
            <div
              className="flex items-center gap-3 cursor-pointer border p-2 hover:bg-blue-50"
              onClick={() =>
                navigate(
                  `/messagerie/${
                    chat?.user ? chat?.user?.id : chat?.guest?.id
                  }`,
                  {
                    state: {
                      prevPath: location?.pathname,
                      role: chat?.user ? "user" : "guest",
                    },
                  }
                )
              }
            >
              <div className="w-10 h-10 md:w-12 md:h-12 min-w-10 min-h-10 md:min-w-12 md:min-h-12 overflow-hidden rounded-full">
                <img
                  src={
                    chat?.user?.avatar
                      ? BASE_IMAGE_URL + chat?.user?.avatar
                      : ProfileImg
                  }
                  alt="img"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-full flex items-center justify-between">
                <div className="flex flex-col justify-between gap-1 w-fit">
                  <h3 className="text-sm md:text-base font-bold">
                    {chat?.user
                      ? `${chat?.user?.first_name} ${chat?.user?.last_name}`
                      : chat?.guest?.name}
                  </h3>
                  <h3 className="text-xs md:text-sm text-gray-500">
                    {chat?.user?.email || chat?.guest?.email}
                  </h3>
                </div>
                <div className="text-xs md:text-sm flex flex-col gap-1 min-w-fit">
                  <div
                    className={`font-medium min-w-fit ${
                      chat?.seen === 0 && "text-green-600"
                    }`}
                  >
                    {chat?.created_at && FormatChatDate(chat?.created_at)}
                  </div>
                  <div className="flex justify-end">
                    {chat?.seen === 0 && (
                      <div className="w-5 h-5 min-h-5 min-w-5 flex justify-center items-center rounded-full bg-green-500 text-white font-medium text-xs md:text-sm">
                        1
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="text-gray-500">Aucun chat n'est disponible</p>
      )}

      {chats?.length > itemsPerPage && (
        <div className="flex items-center justify-between text-xs sm:text-sm md:text-base px-7 py-5 my-4">
          <div>
            <p className="font-medium text-gray-pt">
              Affichage des données {startIndex + 1} à{" "}
              {Math.min(startIndex + itemsPerPage, chats?.length)} sur{" "}
              {chats?.length} entrées
            </p>
          </div>
          <nav className="flex items-center space-x-1 md:space-x-2 font-medium">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 text-gray-950 bg-gray-200 disabled:opacity-50"
            >
              <i className="bi bi-chevron-left"></i>
            </button>
            {renderPageButtons()}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 text-gray-950 bg-gray-200 disabled:opacity-50"
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          </nav>
        </div>
      )}
    </div>
  );
};

export default MessagerieCpt;
