import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CmsLayout from "../cms";
import { ENTREPRISE } from "../../constants/actions";
import { apiClient } from "../../actions/api";
import { FetchData } from "../../actions/data";
import {
  EntrepriseAbout,
  EntrepriseHome,
  EntrepriseTemoi,
  Fonct,
  Galerie,
} from "../../components";
import uploadMultipleImage from "../../helpers/uploadMultipleImages";
import { Loader } from "lucide-react";
import { PencilIcon, XCircleIcon } from '@heroicons/react/24/solid';

const Entreprise = () => {
  const entreprise = useSelector((state) => state?.data?.entreprise);
  const [formData, setFormData] = useState(null);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (entreprise) {
      setFormData(entreprise);
    }
  }, [entreprise]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const ToggleEdit = () => {
    setEdit(!edit);
    setFormData(entreprise);
  };

  const ResetHandler = (fields) => {
    const resetData = {};
    fields?.forEach((field) => {
      resetData[field] = entreprise?.[field] || "";
    });

    setFormData((prevData) => ({
      ...prevData,
      ...resetData,
    }));
  };

  const SubmitHandler = async (e, fields) => {
    e.preventDefault();
  
    const images = ["homeImage", "imageAvis1", "imageAvis2", "imageAvis3"];
    let isImageChanged = false;
    let isFieldChanged = false;
    let formatedData = {};
  
    for (const field of fields) {
      if (formData[field] !== entreprise[field]) {
        formatedData[field] = formData[field];
        isFieldChanged = true;
      }
    }
  
    for (const image of images) {
      if (formatedData[image]) {
        isImageChanged = true;
        try {
          const result = await uploadMultipleImage(
            image,
            { [image]: formData?.[image] },
            { [image]: entreprise?.[image] },
            "entreprise"
          );
          formatedData[result?.field] = result?.path;
        } catch (uploadError) {
          console.error(`Failed to upload ${image}:`, uploadError);
        }
      }
    }
  
    if (!isImageChanged && !isFieldChanged) {
      toast.info("Aucune modification détectée.");
    } else {
      try {
        await apiClient().put(`/entreprise/1`, formatedData);
        toast.success(
          "Les informations d'entreprise ont été modifiées avec succès."
        );
        dispatch(FetchData(`/entreprise`, ENTREPRISE));
      } catch (err) {
        toast.error("Échec de la modification des informations d'entreprise.");
        console.error(err);
      }
    }
  };
  

  return (
    <CmsLayout>
      <div className="p-5 min-h-screen mx-auto relative">
        {isUploading && (
          <div className="fixed inset-0 bg-opacity-70 bg-black flex items-center justify-center z-50 h-screen">
            <Loader className="w-12 h-12 animate-spin text-white" />
          </div>
        )}
        <div>
          <div className=" my-4 flex items-center justify-start gap-3">
          <button
            type="button"
            onClick={ToggleEdit}
            className={`flex items-center justify-center gap-2 min-w-20 h-8 px-4 font-semibold rounded-md shadow-md transition-colors duration-200 ${
              edit
                ? "bg-gray-400 text-white hover:bg-gray-500"
                : "bg-orange-primary text-white hover:bg-orange-600"
            }`}
          >
            {edit ? (
              <>
                <XCircleIcon className="w-4 h-4" />
                Annuler
              </>
            ) : (
              <>
                <PencilIcon className="w-4 h-4" />
                Modifier
              </>
            )}
          </button>
          </div>
          <div className="space-y-7">
            <EntrepriseHome
              formData={formData}
              handleChange={handleChange}
              edit={edit}
              SubmitHandler={SubmitHandler}
              ResetHandler={ResetHandler}
            />
            <EntrepriseAbout
              formData={formData}
              handleChange={handleChange}
              edit={edit}
              SubmitHandler={SubmitHandler}
              ResetHandler={ResetHandler}
            />
            <EntrepriseTemoi
              formData={formData}
              handleChange={handleChange}
              edit={edit}
              SubmitHandler={SubmitHandler}
              ResetHandler={ResetHandler}
            />
            <Galerie
              formData={formData}
              handleChange={handleChange}
              edit={edit}
              setIsUploading={setIsUploading}
              SubmitHandler={SubmitHandler}
              ResetHandler={ResetHandler}
            />
            <Fonct
              formData={formData}
              handleChange={handleChange}
              edit={edit}
              SubmitHandler={SubmitHandler}
              ResetHandler={ResetHandler}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </CmsLayout>
  );
};

export default Entreprise;
