import { useSelector } from "react-redux";
import { ListeBlogs } from "../../components";
import SortByBtn from "../../components/globalcomponents/SortByBtn";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import CmsLayout from "../cms";
import { Filter } from "lucide-react";

const sortOptions = ["Date Création", "Titre", "Auteur", "Objet"];

const Blog = () => {
  const blogs = useSelector((state) => state?.data?.blogs) || [];
  const [filtredBlogs, setFiltredBlogs] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortOption, setSortOption] = useState("Date Création");
  const navigate = useNavigate();
  const location = useLocation();
  const [isFilterSliderOpen, setIsFilterSliderOpen] = useState(false);

  useEffect(()=>{
    if(sortOption === "Date Création"){
      const sortedByCreatedDate = [...blogs]?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setFiltredBlogs(sortedByCreatedDate);
    }else if(sortOption === "Titre"){
      const sortedByName = [...blogs]?.sort((a, b) => a.title.localeCompare(b.title));
      setFiltredBlogs(sortedByName);
    }else if(sortOption === "Auteur"){
      const sortByAuthor = [...blogs]?.sort((a, b) => a.author.localeCompare(b.author));
      setFiltredBlogs(sortByAuthor);
    }else if(sortOption === "Objet"){
      const sortBySubject = [...blogs]?.sort((a, b) => a.subject.localeCompare(b.subject));
      setFiltredBlogs(sortBySubject);
    }else{
      setFiltredBlogs(blogs);
    }
  },[sortOption, blogs])

  useEffect(() => {
    if (!searchInput) {
      setFiltredBlogs(blogs);
      return;
    }

    const searchLowerCase = searchInput.trim().toLowerCase();

    const filtered = blogs?.filter((blog) => {
      const title = blog?.title.toLowerCase();
      const object = blog?.subject.toLowerCase();
      const auteur = blog?.author.toLowerCase();

      return (
        title.includes(searchLowerCase) ||
        auteur.includes(searchLowerCase) ||
        object.includes(searchLowerCase)
      );
    });

    setFiltredBlogs(filtered);
  }, [searchInput, blogs]);

  const toggleFilterSlider = () => {
    setIsFilterSliderOpen(prev => !prev);
  };

  return (
    <CmsLayout>
        <div className="p-4 min-h-screen">
          <div className="md:hidden">
            <button
              onClick={toggleFilterSlider}
              className="w-full bg-orange-500 text-white py-2 px-4 mb-2 rounded-full flex items-center justify-center"
            >
              <Filter size={18} className="mr-2" /> Filtres
            </button>
          </div>
          <div className={`flex flex-col md:flex-row gap-2 ${isFilterSliderOpen ? 'block' : 'hidden md:flex'}`}>
          <div className="relative lg:mr-auto">
              <input
                type="text"
                name="nom"
                id="nom"
                placeholder="Titre, Autuer, Objet"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="w-full md:w-auto bg-white border rounded-full py-2 px-4 flex items-center justify-between"
              />
            </div>
            <div className="relative">
              <SortByBtn
                sortOptions={sortOptions}
                sortOption={sortOption}
                setSortOption={setSortOption}
              />
            </div>
            <div className="relative">
            <button onClick={()=>navigate('/pages/addBlog', {state:{prevPath: location?.pathname}})} className="w-full md:w-auto bg-gray-700 text-white border rounded-full py-2 px-4 flex items-center justify-between">
                Créer un Blog
              </button>
            </div>
          </div>
          <div className="">
            <ListeBlogs
              sortOptions={sortOptions}
              sortOption={sortOption} setSortOption={setSortOption}
              blogs={filtredBlogs} itemsPerPage={10}
              searchInput={searchInput} setSearchInput={setSearchInput}
            />
          </div>
        </div>
    </CmsLayout>
  );
};

export default Blog;
