const FormatDuration = (duration) => {
  const durationStr = duration?.toString().padStart(4, "0");
  const hours = parseInt(durationStr?.substring(0, 2), 10);
  const minutes = parseInt(durationStr?.substring(2, 4), 10);

  let result = "";

  if (hours > 0) {
    result += `${hours} h `;
  }

  if (minutes > 0) {
    result += `${minutes} min`;
  }

  return result.trim() || "0 min";
};

export default FormatDuration;
