import React, { useState, useEffect } from "react";
import { Check, ChevronLeft, ChevronRight, RefreshCcw } from "lucide-react";
import TextEditor from "../globalcomponents/TextEditor";
import { BASE_IMAGE_URL } from "../../constants/actions";
import { IconImg } from "../../assets/images";

const Temoi = ({
  formData,
  handleChange,
  edit,
  SubmitHandler,
  ResetHandler,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = 3;
  const [selectedImages, setSelectedImages] = useState({});

  useEffect(() => {
    const images = {};
    [1, 2, 3].forEach((item) => {
      if (formData?.[`imageAvis${item}`]) {
        images[item] =
          typeof formData?.[`imageAvis${item}`] === "string"
            ? BASE_IMAGE_URL + formData?.[`imageAvis${item}`]
            : URL.createObjectURL(formData?.[`imageAvis${item}`]);
      }
    });
    setSelectedImages(images);
  }, [formData?.imageAvis1, formData?.imageAvis2, formData?.imageAvis3]);

  const nextSlide = () => {
    if (currentIndex < totalSlides - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleFileChange = (e, item) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImages((prev) => ({ ...prev, [item]: imageUrl }));
      handleChange({
        target: {
          name: e.target.name,
          value: file,
        },
      });
    }
  };

  const updateSelectedImages = () => {
    const images = {};
    [1, 2, 3].forEach((item) => {
      if (formData?.[`imageAvis${item}`]) {
        images[item] =
          typeof formData?.[`imageAvis${item}`] === "string"
            ? BASE_IMAGE_URL + formData?.[`imageAvis${item}`]
            : URL.createObjectURL(formData?.[`imageAvis${item}`]);
      } else {
        images[item] = null;
      }
    });
    setSelectedImages(images);
  };

  const fields = [
    "temoiTitle",
    "avis1",
    "titleAvis1",
    "subtitleAvis1",
    "imageAvis1",
    "avis2",
    "titleAvis2",
    "subtitleAvis2",
    "imageAvis2",
    "avis3",
    "titleAvis3",
    "subtitleAvis3",
    "imageAvis3",
  ];

  const handleReset = () => {
    ResetHandler(fields);
    updateSelectedImages();
  };

  return (
    <form onSubmit={(e)=>SubmitHandler(e, fields)} className="w-full">
      <div className="flex items-center justify-between">
        <h1 className="text-lg md:text-xl lg:text-2xl font-semibold my-2">
          Section des Avis
        </h1>
      </div>
      <div className="space-y-4 p-2 border rounded-lg bg-white">
        {edit && (
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              onClick={handleReset}
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-gray-500 hover:bg-gray-600"
            >
              <RefreshCcw className="w-4 h-4 md:h-6 md:w-6" />
            </button>
            <button
              type="submit"
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-blue-900 hover:bg-blue-950"
            >
              <Check className="w-4 h-4 md:h-6 md:w-6" />
            </button>
          </div>
        )}
        <div className="text-xs md:text-sm lg:text-base">
          <div className="p-2 space-y-7">
            <div className="flex flex-col gap-2">
              <label htmlFor="temoiTitle">Titre</label>
              <input
                type="text"
                name="temoiTitle"
                id="temoiTitle"
                placeholder="Titre"
                value={formData?.temoiTitle || ""}
                onChange={handleChange}
                className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                  !edit && "opacity-70"
                }`}
                readOnly={!edit}
                required={true}
              />
            </div>
            <div className="space-y-4">
              <div className="overflow-hidden">
                <div
                  className="flex transition-transform duration-300 ease-in-out"
                  style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                  {[1, 2, 3].map((item) => (
                    <div key={item} className="w-full flex-shrink-0">
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                        <div className="space-y-3">
                          <div className="flex flex-col gap-1">
                            <label htmlFor={`avis${item}`}>Commentaire</label>
                            <div className="min-h-fit h-fit bg-white overflow-hidden border-b">
                              <TextEditor
                                texteditor={formData?.[`avis${item}`] || ""}
                                setTexteditor={(value) =>
                                  handleChange({
                                    target: { name: `avis${item}`, value },
                                  })
                                }
                                disabled={!edit}
                                style={!edit && "opacity-70"}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col gap-1">
                            <label htmlFor={`titleAvis${item}`}>
                              Nom Institut
                            </label>
                            <input
                              type="text"
                              name={`titleAvis${item}`}
                              id={`titleAvis${item}`}
                              placeholder="Titre"
                              value={formData?.[`titleAvis${item}`] || ""}
                              onChange={handleChange}
                              className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                                !edit && "opacity-70"
                              }`}
                              readOnly={!edit}
                              required={true}
                            />
                          </div>
                          <div className="flex flex-col gap-1">
                            <label htmlFor={`subtitleAvis${item}`}>
                              Type Institut
                            </label>
                            <input
                              type="text"
                              name={`subtitleAvis${item}`}
                              id={`subtitleAvis${item}`}
                              placeholder="Titre"
                              value={formData?.[`subtitleAvis${item}`] || ""}
                              onChange={handleChange}
                              className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                                !edit && "opacity-70"
                              }`}
                              readOnly={!edit}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="space-y-2">
                          <div className="">
                            <div className="h-72 w-full mx-auto mb-2 overflow-hidden">
                              {selectedImages[item] ? (
                                <img
                                  src={selectedImages[item]}
                                  className="object-cover w-full h-full rounded-md"
                                  alt={`Avis ${item}`}
                                />
                              ) : (
                                <div className="w-full h-full border-2 border-dashed border-gray-600 rounded-md overflow-hidden flex items-center justify-center">
                                  <img
                                    src={IconImg}
                                    className="w-1/3"
                                    alt="image icon"
                                  />
                                </div>
                              )}
                            </div>
                            <input
                              type="file"
                              name={`imageAvis${item}`}
                              id={`imageAvis${item}`}
                              accept="image/*"
                              className="sr-only"
                              onChange={(e) => handleFileChange(e, item)}
                              disabled={!edit}
                            />
                            <label
                              htmlFor={`imageAvis${item}`}
                              className={`${
                                !edit && "cursor-not-allowed opacity-50"
                              }`}
                            >
                              <div
                                className={`w-full h-12 rounded-md flex items-center justify-center ${
                                  edit
                                    ? "bg-slate-200 hover:bg-gray-300 cursor-pointer"
                                    : "bg-gray-100"
                                }`}
                              >
                                <h4 className="font-bold">Choisir une image</h4>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-end gap-3">
                <div className="font-bold">
                  Slide {currentIndex + 1} / {totalSlides}
                </div>
                <button
                  type="button"
                  variant="outline"
                  size="icon"
                  onClick={prevSlide}
                  className={`p-3 rounded-full overflow-hidden ${
                    currentIndex === 0
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "bg-gray-300 text-gray-800 hover:bg-gray-800 hover:text-white"
                  }`}
                  disabled={currentIndex === 0}
                >
                  <ChevronLeft className="w-5 h-5 md:h-7 md:w-7" />
                </button>
                <button
                  type="button"
                  variant="outline"
                  size="icon"
                  onClick={nextSlide}
                  className={`p-3 rounded-full overflow-hidden ${
                    currentIndex === totalSlides - 1
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "bg-gray-300 text-gray-800 hover:bg-gray-800 hover:text-white"
                  }`}
                  disabled={currentIndex === totalSlides - 1}
                >
                  <ChevronRight className="w-5 h-5 md:h-7 md:w-7" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Temoi;
