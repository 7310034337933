import { BLOGS, CHATS } from "../constants/actions";
import { apiClient } from "./api";
import { FetchData } from "./data";

export const payerEnEspece = async (paymentId) => {
  try {
    const response = await apiClient().put(`/payments/${paymentId}`, {
      payment_status: true,
    });
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const addBlog = (blogData) => async (dispatch) => {
  try {
    await apiClient().post("/blogs", blogData);
    dispatch(FetchData("/blogs", BLOGS));
  } catch (error) {
    console.error("Failed to add blog", error);
  }
};

export const handleSeenMessages =
  (userId, chats = []) =>
  async (dispatch) => {
    try {
      await Promise.all(
        chats?.map(
          async (chat) =>
            await apiClient().put(`/chats/${chat?.id}`, {
              seen: 1,
            })
        )
      );
      dispatch(FetchData(`/chatsById/${userId}`, CHATS));
    } catch (error) {
      console.error("Failed to seen chats", error);
    }
  };
