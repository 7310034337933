import React, { useEffect, useState } from "react";
import TextEditor from "../globalcomponents/TextEditor";
import { BASE_IMAGE_URL } from "../../constants/actions";
import { IconImg } from "../../assets/images";
import { Check, RefreshCcw } from "lucide-react";

const Home = ({
  formData,
  handleChange,
  edit,
  SubmitHandler,
  ResetHandler,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (formData?.homeImage) {
      setSelectedImage(
        typeof formData?.homeImage === "string"
          ? BASE_IMAGE_URL + formData?.homeImage
          : URL.createObjectURL(formData?.homeImage)
      );
    } else {
      setSelectedImage(null);
    }
  }, [formData?.homeImage]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      handleChange({
        target: {
          name: e.target.name,
          value: file,
        },
      });
    }
  };

  const fields = [
    "homeTitle",
    "homeDescription",
    "homeButton1",
    "homeButton2",
    "homeLien2",
    "homeImage",
  ];

  const resetFunc = () => {
    ResetHandler(fields);
    setSelectedImage(null);
  };

  return (
    <form onSubmit={(e) => SubmitHandler(e, fields)} className="">
      <div className="flex items-center justify-between">
        <h1 className="text-lg md:text-xl lg:text-2xl font-semibold my-2">
          Section Home
        </h1>
      </div>
      <div className="p-2 border rounded-lg bg-white space-y-4">
        {edit && (
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              onClick={resetFunc}
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-gray-500 hover:bg-gray-600"
            >
              <RefreshCcw className="w-4 h-4 md:h-6 md:w-6" />
            </button>
            <button
              type="submit"
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-blue-900 hover:bg-blue-950"
            >
              <Check className="w-4 h-4 md:h-6 md:w-6" />
            </button>
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 text-xs md:text-sm lg:text-base">
          <div className="p-2 space-y-3">
            <div className="flex flex-col gap-2">
              <label htmlFor="homeTitle">Titre</label>
              <input
                type="text"
                name="homeTitle"
                id="homeTitle"
                placeholder="Titre"
                value={formData?.homeTitle || ""}
                onChange={handleChange}
                className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                  !edit && "opacity-70"
                }`}
                readOnly={!edit}
                required={true}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="homeDescription">Description</label>
              <div className="min-h-fit h-fit bg-white overflow-hidden border-b">
                <TextEditor
                  texteditor={formData?.homeDescription}
                  setTexteditor={(value) =>
                    handleChange({ target: { name: "homeDescription", value } })
                  }
                  disabled={!edit}
                  style={!edit && "opacity-70"}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="homeButton1">Titre Button 1</label>
              <input
                type="text"
                name="homeButton1"
                id="homeButton1"
                placeholder="Titre button 1"
                value={formData?.homeButton1 || ""}
                onChange={handleChange}
                className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                  !edit && "opacity-70"
                }`}
                readOnly={!edit}
                required={true}
              />
            </div>
            {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
              <div className="flex flex-col gap-2">
                <label htmlFor="homeButton1">Titre Button 1</label>
                <input
                  type="text"
                  name="homeButton1"
                  id="homeButton1"
                  placeholder="Titre button 1"
                  value={formData?.homeButton1 || ""}
                  onChange={handleChange}
                  className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                    !edit && "opacity-70"
                  }`}
                  readOnly={!edit}
                  required={true}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="homeButton2">Titre Button 2</label>
                <input
                  type="text"
                  name="homeButton2"
                  id="homeButton2"
                  placeholder="Titre button 2"
                  value={formData?.homeButton2 || ""}
                  onChange={handleChange}
                  className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                    !edit && "opacity-70"
                  }`}
                  readOnly={!edit}
                  required={true}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="homeLien2">Lien Button 2</label>
                <input
                  type="text"
                  name="homeLien2"
                  id="homeLien2"
                  placeholder="Lien button 2"
                  value={formData?.homeLien2 || ""}
                  onChange={handleChange}
                  className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                    !edit && "opacity-70"
                  }`}
                  readOnly={!edit}
                  required={true}
                />
              </div>
            </div> */}
          </div>
          <div className="">
            <div className={"h-80 w-full rounded-md overflow-hidden mb-2"}>
              {selectedImage ? (
                <img
                  src={selectedImage}
                  className="object-cover w-full h-full"
                  alt="image home"
                />
              ) : (
                <div className="w-full h-full border-2 border-dashed border-gray-600 overflow-hidden flex items-center justify-center">
                  <img src={IconImg} className="w-1/3" alt="image icon" />
                </div>
              )}
            </div>
            <input
              type="file"
              name="homeImage"
              id="homeImage"
              accept="image/*"
              className="sr-only"
              onChange={handleFileChange}
              disabled={!edit}
            />
            <label
              htmlFor="homeImage"
              className={`${!edit && "cursor-not-allowed opacity-50"}`}
            >
              <div
                className={`w-full h-16 rounded-md flex items-center justify-center ${
                  edit
                    ? "bg-slate-200 hover:bg-gray-300 cursor-pointer"
                    : "bg-gray-100"
                }`}
              >
                <h4 className="font-bold">Choisir une image</h4>
              </div>
            </label>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Home;
