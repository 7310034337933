import { useLocation, useNavigate, useParams } from "react-router";
import { ComeBackButton, Layout, PaiementsDetailsComponent } from "../../components";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";


const PaiementDetails = () => {
  const { id } = useParams();
  const payments = useSelector((state) => state?.data?.payments) || [];
  const [payment, setPayment] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isNaN(id)) {
      navigate("/paiements");
      return;
    }
    const pay = payments?.find((payment) => payment?.id === parseInt(id));
    if (payments?.length > 0 && !pay) {
      navigate("/paiements");
      return;
    }
    setPayment(pay);
  }, [id, payments]);

  return (
    <Layout>
      <div className="p-1">
        <ComeBackButton prev={location?.state?.prevPath} next={"/paiements"} />
        <PaiementsDetailsComponent payment={payment} />
      </div>
    </Layout>
  );
};

export default PaiementDetails;
