import React, { useState } from "react";
import { Check, ChevronLeft, ChevronRight, RefreshCcw } from "lucide-react";
import TextEditor from "../globalcomponents/TextEditor";

const Fonct = ({
  formData,
  handleChange,
  edit,
  SubmitHandler,
  ResetHandler,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = 3;

  const nextSlide = () => {
    if (currentIndex < totalSlides - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const resetFields = [
    "fonctTitle",
    "titleFonct1",
    "descriptionFonct1",
    "titleFonct2",
    "descriptionFonct2",
    "titleFonct3",
    "descriptionFonct3",
  ];

  return (
    <form onSubmit={(e)=>SubmitHandler(e, resetFields)} className="w-full">
      <div className="flex items-center justify-between">
        <h1 className="text-lg md:text-xl lg:text-2xl font-semibold my-2">
          Section des Fonctionnalités
        </h1>
      </div>
      <div className="space-y-4 p-2 border rounded-lg bg-white">
        {edit && (
          <div className="flex items-center justify-end gap-3">
            <button
              type="button"
              onClick={() => ResetHandler(resetFields)}
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-gray-500 hover:bg-gray-600"
            >
              <RefreshCcw className="w-4 h-4 md:h-6 md:w-6" />
            </button>
            <button
              type="submit"
              className="flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-white bg-blue-900 hover:bg-blue-950"
            >
              <Check className="w-4 h-4 md:h-6 md:w-6" />
            </button>
          </div>
        )}
        <div className="text-xs md:text-sm lg:text-base">
          <div className="p-2 space-y-3">
            <div className="flex flex-col gap-2">
              <label htmlFor="fonctTitle">Titre</label>
              <input
                type="text"
                name="fonctTitle"
                id="fonctTitle"
                placeholder="Titre"
                value={formData?.fonctTitle || ""}
                onChange={handleChange}
                className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                  !edit && "opacity-70"
                }`}
                readOnly={!edit}
                required={true}
              />
            </div>
            <div className="space-y-4">
              <div className="overflow-hidden">
                <div
                  className="flex transition-transform duration-300 ease-in-out"
                  style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                  {[1, 2, 3].map((item) => (
                    <div key={item} className="w-full flex-shrink-0">
                      <div className="space-y-2">
                        <div className="flex flex-col gap-1">
                          <label htmlFor={`titleFonct${item}`}>
                            Fonctionnalité
                          </label>
                          <input
                            type="text"
                            name={`titleFonct${item}`}
                            id={`titleFonct${item}`}
                            placeholder="Titre"
                            value={formData?.[`titleFonct${item}`] || ""}
                            onChange={handleChange}
                            className={`w-full p-2 border rounded-md outline-none focus:outline-none ${
                              !edit && "opacity-70"
                            }`}
                            readOnly={!edit}
                            required={true}
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <label htmlFor={`descriptionFonct${item}`}>
                            Description
                          </label>
                          <div className="min-h-fit h-fit bg-white overflow-hidden border-b">
                            <TextEditor
                              texteditor={
                                formData?.[`descriptionFonct${item}`] || ""
                              }
                              setTexteditor={(value) =>
                                handleChange({
                                  target: {
                                    name: `descriptionFonct${item}`,
                                    value,
                                  },
                                })
                              }
                              disabled={!edit}
                              style={!edit && "opacity-70"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-end gap-3">
                <div className="font-bold">
                  Slide {currentIndex + 1} / {totalSlides}
                </div>
                <button
                  type="button"
                  variant="outline"
                  size="icon"
                  onClick={prevSlide}
                  className={`p-3 rounded-full overflow-hidden ${
                    currentIndex === 0
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "bg-gray-300 text-gray-800 hover:bg-gray-800 hover:text-white"
                  }`}
                  disabled={currentIndex === 0}
                >
                  <ChevronLeft className="w-5 h-5 md:h-7 md:w-7" />
                </button>
                <button
                  type="button"
                  variant="outline"
                  size="icon"
                  onClick={nextSlide}
                  className={`p-3 rounded-full overflow-hidden ${
                    currentIndex === totalSlides - 1
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "bg-gray-300 text-gray-800 hover:bg-gray-800 hover:text-white"
                  }`}
                  disabled={currentIndex === totalSlides - 1}
                >
                  <ChevronRight className="w-5 h-5 md:h-7 md:w-7" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Fonct;
