import { useLocation } from "react-router";
import { AddEmployeeCpt, ComeBackButton, ParametresLayout } from "../../components";

const AddEmployee = () => {
    const location = useLocation();

  return (
    <ParametresLayout>
      <div className="p-1 min-h-screen space-y-5">
        <ComeBackButton prev={location?.state?.prevPath} next={"/parametres/employees"} />
        <AddEmployeeCpt />
      </div>
    </ParametresLayout>
  );
};
    
export default AddEmployee;
