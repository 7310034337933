import React, { useRef } from "react";
import { Layout } from "../../components";
import { useLocation, useNavigate } from "react-router";

const CmsLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const isActive = (path) => {
    const currentPath = location.pathname;

    if (currentPath === path) {
      return true;
    }

    if (path === "/pages") {
      return [
        "/pages/propos",
        "/pages/blogs",
        "/pages/addBlog",
        "/pages/conditions",
        "/pages/politiques",
        "/pages/rgbd",
      ].includes(currentPath);
    }

    if (path === "/pages/blogs") {
      return /^\/pages\/blogs\/\d+$/.test(currentPath) || "/pages/addBlog" === currentPath;
    }

    return false;
  };

  const tabs = [
    { title: "Blog", path: "/pages/blogs" },
    { title: "Accueil", path: "/pages/accueil" },
    { title: "A-Propos", path: "/pages/propos" },
    // { title: "Entreprise", path: "/pages/entreprise" },
    { title: "Conditions", path: "/pages/conditions" },
    { title: "Politiques", path: "/pages/politiques" },
    { title: "RGBD", path: "/pages/rgbd" },
    // { title: "Footer", path: "/pages/footer" },
  ];

  const scrollLeft = () => {
    if (sliderRef.current) { 
      sliderRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    }
  };

  return (
    <Layout>
      <div className="relative overflow-hidden">
        <div className="md:hidden flex items-center">
          <button onClick={scrollLeft} className="p-2 text-orange-primary">
            <i className="bi bi-chevron-left"></i>
          </button>
          <div
            ref={sliderRef}
            className="flex overflow-x-auto scrollbar-hide"
            style={{ scrollSnapType: 'x mandatory' }}
          >
            {tabs?.map((t, i) => (
              <div
                key={i}
                onClick={() => navigate(t?.path)}
                className={`flex-shrink-0 px-4 py-2 text-center text-xs whitespace-nowrap cursor-pointer ${
                  isActive(t?.path)
                    ? "font-bold text-orange-primary"
                    : "font-medium"
                }`}
                style={{ scrollSnapAlign: 'start' }}
              >
                {t?.title}
              </div>
            ))}
          </div>
          <button onClick={scrollRight} className="p-2 text-orange-primary">
            <i className="bi bi-chevron-right"></i>
          </button>
        </div>
        <div className="hidden md:flex p-2 items-center justify-center gap-3 w-full md:max-w-3xl mx-auto">
          {tabs?.map((t, i) => (
            <div
              key={i}
              onClick={() => navigate(t?.path)}
              className={`flex items-center justify-center text-center text-xs sm:text-sm cursor-pointer p-3 ${
                isActive(t?.path)
                  ? "font-bold border-b-2 border-orange-primary text-orange-primary"
                  : "font-medium"
              }`}
            >
              {t?.title}
            </div>
          ))}
        </div>

        <div className="mt-4 md:mt-0 border-t">{children}</div>
      </div>
    </Layout>
  );
};

export default CmsLayout;