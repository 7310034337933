

// global
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const VIEW_MODE = "VIEW_MODE";


// base urls
export const BASE_URL = "https://gestion.skipp.ma/public/api";
export const BASE_URL_PROD = "BASE_URL_PROD";
export const BASE_IMAGE_URL = "https://gestion.skipp.ma/public/";
// export const BASE_IMAGE_URL = "BASE_IMAGE_URL_PROD";


// auth actions
export const STROE_USER_TOKEN = "STROE_USER_TOKEN";
export const REMOVE_USER_TOKEN = "REMOVE_USER_TOKEN";
export const STROE_USER_INFOS = "STROE_USER_INFOS";
export const REMOVE_USER_INFOS = "REMOVE_USER_INFOS";
export const DELETE_ALL_DATA = "DELETE_ALL_DATA";


// data
export const USERS = "USERS";
export const CLIENTS = "CLIENTS";
export const SALONS = "SALONS";
export const COMPLETEDSALONS = "COMPLETEDSALONS";
export const SERVICES = "SERVICES";
export const AVAILABILITIES = "AVAILABILITIES";
export const BOOKINGS = "BOOKINGS";
export const PAYMENTS = "PAYMENTS";
export const REVIEWS = "REVIEWS";
export const COMPLAINTS = "COMPLAINTS";
export const CHATS = "CHATS";
export const CALENDARS = "CALENDARS";
export const NOTES = "NOTES";
export const EMPLOYEES = "EMPLOYEES";
export const BLOGS = "BLOGS";
export const PROPOS = "PROPOS";
export const EMAILS = "EMAILS";
export const ADD_BLOG = "ADD_BLOG";
export const DEMANDES = "DEMANDES";
export const GUESTS = "GUESTS";
export const SUGGESTIONS = "SUGGESTIONS";
export const CONDITIONS = "CONDITIONS";
export const POLITIQUES = "POLITIQUES";
export const RGBD = "RGBD";
export const ACCUEIL = "ACCUEIL";
export const REASONS = "REASONS";
export const FEEDBACKS = "FEEDBACKS";
export const ENTREPRISE = "ENTREPRISE";
export const GALERIE = "GALERIE";
export const FOOTER = "FOOTER";
export const FAQS = "FAQS";

export const SALON = "SALON";

// export const RESERVATIONS = "RESERVATIONS";
// export const CLIENTS = "CLIENTS";
// export const TACHES = "TACHES";
// export const NOTIFICATIONS = "NOTIFICATIONS";
// export const PARAMETRES = "PARAMETRES";


