import { useLocation, useNavigate } from "react-router-dom";
import { SkippSvg1 } from "../../assets/images";
import "./style.css";
import { useAuth } from "../../context/authContext";

const ReseIcon = ({ style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={style}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
    />
  </svg>
);

const CalendarIcon = ({ style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className={style}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
    />
  </svg>
);

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { isSuperAdmin } = useAuth();

  const links = [
    { bt: true, title: "Dashboard", icon: "columns-gap", path: "/" },
    { bt: false, title: "Réservations", icon: ReseIcon, path: "/reservations" },
    { bt: true, title: "Clients", icon: "people", path: "/clients" },
    { bt: true, title: "Paiements", icon: "wallet2", path: "/paiements" },
    // { bt: true, title: "Messagerie", icon: "chat-dots", path: "/messagerie" },
    { bt: false, title: "Calendrier", icon: CalendarIcon, path: "/calendrier" },
    isSuperAdmin && {
      bt: true,
      title: "Demandes",
      icon: "list-ol",
      path: "/demandes",
    },
    // { bt: true, title: "Notifications", icon: 'bell', path: '/notifications' },
    isSuperAdmin && {
      bt: true,
      title: "Pages",
      icon: "layout-three-columns",
      path: "/pages",
    },
    { bt: true, title: "Paramètres", icon: "gear", path: "/parametres/avis" },
    
  ]?.filter(Boolean);

  const isActive = (path) => {
    const isExactMatch = location.pathname === path;
    const isReservationsMatch =
      path === "/reservations" &&
      /^\/reservations\/\d+$/.test(location.pathname);
    const isClientsMatch =
      path === "/clients" && /^\/clients\/\d+$/.test(location.pathname);
    const isPaiementsMatch =
      path === "/paiements" && /^\/paiements\/\d+$/.test(location.pathname);
    const isMessagerieMatch =
      path === "/messagerie" && /^\/messagerie\/\d+$/.test(location.pathname);
    const isParametresMatch =
      path === "/parametres/avis" && /^\/parametres/.test(location.pathname);
    const isPagesMatch =
      path === "/pages" && /^\/pages/.test(location.pathname);
    return (
      isExactMatch ||
      isReservationsMatch ||
      isClientsMatch ||
      isPaiementsMatch ||
      isParametresMatch ||
      isMessagerieMatch ||
      isPagesMatch
    );
  };

  return (
    <div
      className={`fixed top-0 left-0 z-40 h-full border bg-white overflow-y-auto transition-transform transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } lg:translate-x-0 scrollbar-thin scrollbar-thumb-lightOrange scrollbar-track-white`}
    >
      <div className="w-[230px] max-w-[230px] min-w-[230px] lg:w-[200px] lg:max-w-[200px] lg:min-w-[200px] mx-auto relative">
        <button
          className="fixed top-1 right-1 text-xl px-2 py-1 text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          onClick={toggleSidebar}
        >
          <i className="bi bi-x-lg"></i>
        </button>
        <div
          onClick={() => navigate("/")}
          className="flex justify-start pt-2 pl-2 lg:pl-0 lg:justify-center lg:mt-4 mb-5 w-fit lg:mx-auto cursor-pointer"
        >
          <img src={SkippSvg1} alt="logo" className="h-10 w-auto" />
        </div>
        <div className="flex flex-col gap-y-3 px-4 text-sm md:text-base">
          {links?.map((l, i) => (
            <div
              key={i}
              onClick={() => navigate(l?.path)}
              className={`rounded-full overflow-hidden ${
                isActive(l?.path) ? "linkactive" : "linkdisable"
              }`}
            >
              {l?.bt ? (
                <div className="flex items-center cursor-pointer">
                  <div
                    className={`w-10 h-10 md:w-11 md:h-11 cursor-pointer flex justify-center items-center rounded-full overflow-hidden border icon`}
                  >
                    <i className={`bi bi-${l?.icon}`}></i>
                  </div>
                  <span className={`px-3 font-semibold title`}>
                    {l?.title}
                  </span>
                </div>
              ) : (
                <div className="flex items-center cursor-pointer">
                  <div
                    className={`w-10 h-10 md:w-11 md:h-11 cursor-pointer flex justify-center items-center rounded-full overflow-hidden border icon`}
                  >
                    <l.icon style={"size-4 sm:size-5 md:size-6"} />
                  </div>
                  <span className={`px-3 font-semibold title`}>
                    {l?.title}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
