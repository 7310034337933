import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BASE_IMAGE_URL } from '../../constants/actions';
import { ProfileImg } from '../../assets/images';
import { logoutUser } from '../../actions/auth';

const ProfileIcon = () => {
  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const user = useSelector(state => state?.auth?.user);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleDropdown = () => {
    setIsProfileVisible(!isProfileVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsProfileVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Avatar Button */}
      <button
        onClick={toggleDropdown}
        aria-haspopup="true"
        aria-expanded={isProfileVisible}
        className='w-10 h-10 md:w-12 md:h-12 text-xl rounded-full overflow-hidden focus:outline-none bg-gray-100 hover:bg-gray-200 transition-all duration-200'
      >
        <img 
          src={user?.avatar ? BASE_IMAGE_URL + user?.avatar : ProfileImg} 
          alt="avatar" 
          className='object-cover w-full h-full' 
        />
      </button>

      {/* Dropdown Menu */}
      {isProfileVisible && (
        <div className="absolute top-14 right-0 bg-white shadow-lg rounded-lg w-64 py-4 px-6 z-50 transition-opacity duration-300">
          {/* User Info */}
          <div className="flex items-center mb-4">
            <div className="w-12 h-12 bg-gray-300 rounded-full overflow-hidden mr-3">
              <img 
                src={user?.avatar ? BASE_IMAGE_URL + user?.avatar : ProfileImg} 
                alt="avatar" 
                className='object-cover w-full h-full' 
              />
            </div>
            <div>
              <h3 className="text-gray-900 font-semibold">{user?.first_name} {user?.last_name}</h3>
              <p className="text-sm text-gray-500">{user?.email}</p>
            </div>
          </div>

          {/* Profile Link */}
          <button 
            className='flex items-center gap-2 text-gray-700 hover:text-gray-900 transition-colors mb-2' 
            onClick={() => navigate('/profile')}
          >
            <i className="bi bi-person-fill text-lg"></i>
            <p className="text-sm">Mon Profil</p>
          </button>

          {/* Logout Button */}
          <hr className="my-2 border-gray-200" />
          <button
            onClick={() => dispatch(logoutUser())}
            className="w-full mt-2 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition-colors duration-200 text-sm"
          >
            Se déconnecter
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileIcon;