import { combineReducers } from "redux";
import global from "./global";
import auth from "./auth";
import tokens from "./tokens";
import data from "./data";
import parametres from "./parametres";

const rootReducer = combineReducers({ global, auth, tokens, data, parametres });

export default rootReducer;
