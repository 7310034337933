import { useEffect, useRef, useState } from "react";
import FormattingDate from "../../helpers/FormattingDate";
import { apiClient } from "../../actions/api";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FetchData } from "../../actions/data";
import TextEditor from "../globalcomponents/TextEditor";
import { BASE_IMAGE_URL, BLOGS } from "../../constants/actions";
import uploadImage from "../../helpers/uploadImages";
import { IconImg } from "../../assets/images"; // Ensure your loader icon is imported
import { Loader } from "lucide-react";
import { PencilIcon, CheckIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline'; // Importing Hero Icons

const BlogDetailsForm = ({ blog }) => {
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState(blog);
  const [selectedImage, setSelectedImage] = useState(
    blog?.image ? BASE_IMAGE_URL + blog?.image : null
  );
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (blog) {
      setFormData(blog);
      setSelectedImage(blog?.image ? BASE_IMAGE_URL + blog?.image : null);
    }
  }, [blog]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const ToggleEdit = () => {
    setEdit(!edit);
    setFormData(blog);
    setSelectedImage(blog?.image ? BASE_IMAGE_URL + blog?.image : null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChangeAvatar = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSize = 2 * 1024 * 1024; // 2MB

      if (!validTypes.includes(file.type)) {
        alert("Veuillez télécharger une image valide (JPEG, PNG, GIF).");
        return;
      }

      if (file.size > maxSize) {
        alert("L'image ne doit pas dépasser 2 Mo.");
        return;
      }

      setIsUploading(true);
      try {
        const newPath = await uploadImage(
          "image",
          { image: file },
          { image: "" },
          "Blogs"
        );

        // Set the uploaded image URL
        const imageUrl = newPath;
        setSelectedImage(imageUrl);
        setFormData({ ...formData, image: imageUrl });
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Échec du téléchargement de l'image.");
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start loading state
    setLoading(true);
    try {
      if (JSON.stringify(formData) === JSON.stringify(blog)) {
        toast.info("Aucune modification détectée.");
        setLoading(false);
        return;
      }
      const data = {
        ...formData,
      };
      const response = await apiClient().put(`/blogs/${blog?.id}`, data);
      if (response?.status === 200) {
        toast.success("Les informations de l'blog ont été modifiées avec succès.");
        dispatch(FetchData("/blogs", BLOGS));
        setSelectedImage(BASE_IMAGE_URL + response?.data?.image);
      }
    } catch (err) {
      toast.error("Échec de la modification des informations de l'blog.");
      console.error(err);
    } finally {
      setLoading(false);
    }
    setIsSubmitting(false); // Stop loading state
  };

    useEffect(() => {
      setImageUrl(selectedImage && !selectedImage.startsWith(BASE_IMAGE_URL)
      ? BASE_IMAGE_URL + selectedImage
      : selectedImage)
    }, [selectedImage])

  return (
    <div className="space-y-8 p-5 bg-gray-100 rounded-md shadow-md">
      <div className="flex items-center justify-between">
        <div className="text-gray-600">
          Créé le {formData?.created_at && FormattingDate(formData?.created_at)}
        </div>
        <button
          onClick={ToggleEdit}
          className={`min-w-20 h-8 font-semibold px-4 flex items-center justify-center transition-colors duration-300 rounded-md ${
            edit ? "bg-gray-400 text-white" : "bg-orange-500 text-white"
          } hover:bg-orange-600`}
        >
          <PencilIcon className="h-5 w-5 mr-1" />
          {edit ? "Annuler" : "Modifier"}
        </button>
      </div>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="space-y-2">
            <div className="flex flex-col gap-2">
              <label htmlFor="title" className="font-semibold">Titre</label>
              <div className="flex items-center border rounded-md">
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={formData?.title}
                  onChange={handleChange}
                  className={`w-full p-3 outline-none focus:ring-2 focus:ring-orange-300 transition ${
                    !edit ? "bg-gray-200 opacity-70" : "bg-white"
                  }`}
                  readOnly={!edit}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="author" className="font-semibold">Auteur</label>
              <div className="flex items-center border rounded-md">
                <input
                  type="text"
                  name="author"
                  id="author"
                  value={formData?.author}
                  onChange={handleChange}
                  className={`w-full p-3 outline-none focus:ring-2 focus:ring-orange-300 transition ${
                    !edit ? "bg-gray-200 opacity-70" : "bg-white"
                  }`}
                  readOnly={!edit}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="subject" className="font-semibold">Objet</label>
              <div className="flex items-center border rounded-md">
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  value={formData?.subject}
                  onChange={handleChange}
                  className={`w-full p-3 outline-none focus:ring-2 focus:ring-orange-300 transition ${
                    !edit ? "bg-gray-200 opacity-70" : "bg-white"
                  }`}
                  readOnly={!edit}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-semibold">Image</label>
            <div className="bg-white h-56 max-h-56 rounded-md overflow-hidden border border-gray-300 relative">
              {loading && (
                <div className="absolute inset-0 flex justify-center items-center bg-gray-100 opacity-75">
                  <Loader className="h-5 w-5 animate-spin" /> {/* Loader icon */}
                </div>
              )}
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="Blog"
                  className={`object-cover w-full h-full ${loading ? "opacity-30" : ""}`}
                />
              ) : (
                <div className={`border-2 border-black border-dashed rounded-lg h-56 max-h-56 flex justify-center items-center ${edit && 'cursor-pointer'}`}>
                  <img src={IconImg} alt="img" className="w-1/4 max-w-full max-h-full mx-auto" />
                </div>
              )}
              <input
                type="file"
                id="img"
                onChange={handleChangeAvatar}
                className="sr-only"
                disabled={!edit}
                ref={fileInputRef}
              />
              {isUploading && (
              <div className="absolute inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50 rounded-lg">
                <svg
                  className="animate-spin h-8 w-8 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="none"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v3.6A4.4 4.4 0 002 12a4.4 4.4 0 001 2.8V12z"
                  />
                </svg>
              </div>
            )}
            </div>
            {edit && (
              <label htmlFor="img">
                <div className="h-12 flex items-center justify-center bg-slate-200 hover:bg-slate-300 cursor-pointer rounded-md">
                  <ArrowUpTrayIcon className="h-5 w-5 text-gray-600 mr-2" />
                  Choisir une image
                </div>
              </label>
            )}
            
          </div>
          
        </div>
        <div className="flex flex-col gap-2 col-span-1 md:col-span-2">
          <label htmlFor="contenue" className="font-semibold">Contenu</label>
          <TextEditor
            texteditor={formData?.contenue}
            setTexteditor={(value) =>
              handleChange({ target: { name: "contenue", value } })
            }
            style={!edit && "opacity-70"}
            disabled={!edit}
          />
        </div>
        {edit && (
          <div className="flex justify-end gap-4 col-span-1 md:col-span-2">
            {loading ? (
              <>
                <Loader className="h-5 w-5 mr-2 animate-spin" />
                Chargement...
              </>
            ) : (
              <button
            type="submit"
            disabled={isSubmitting} // Disable button when submitting
            className={`bg-orange-500 text-white rounded-md px-6 py-2 flex items-center justify-center transition duration-300 ${
              isSubmitting
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-orange-600"
            }`}
          >
            {isSubmitting ? (
              <svg
                className="animate-spin h-5 w-5 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                  fill="none"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v3.6A4.4 4.4 0 002 12a4.4 4.4 0 001 2.8V12z"
                />
              </svg>
            ) : (
              <CheckIcon className="w-5 h-5 mr-2" />
            )}
            Valider
          </button>
            )}
            </div>
        )}
      </form>
      <ToastContainer />
    </div>
  );
};

export default BlogDetailsForm;