import React from 'react';
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from '@heroicons/react/24/solid';

const ComeBackButton = ({ prev, next }) => {
  const navigate = useNavigate();

  return (
    <div className="p-4">
      <button
        className="group flex items-center space-x-2 px-4 py-2 bg-white text-orange-600 border border-orange-300 rounded-full font-semibold shadow-sm hover:bg-orange-50 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
        onClick={() => navigate(prev || next)}
      >
        <ArrowLeftIcon className="w-5 h-5 group-hover:transform group-hover:-translate-x-1 transition-transform duration-200" />
        <span className="text-sm sm:text-base">Revenir</span>
      </button>
    </div>
  );
};

export default ComeBackButton;