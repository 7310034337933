import { useLocation, useNavigate, useParams } from "react-router";
import { ComeBackButton, Layout, UserProfile } from "../../components";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";

const UserDetails = () => {
  const { id } = useParams();
  const { isSuperAdmin } = useAuth();
  const users = useSelector((state) => isSuperAdmin ? state?.data?.users : state?.data?.clients) || [];
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isNaN(id)) {
      navigate("/parametres/utilisateurs");
      return;
    }
    const clt = users?.find((user) => user?.id === parseInt(id));
    if (users?.length > 0 && !clt) {
      navigate("/parametres/utilisateurs");
      return;
    }
    setUser(clt);
  }, [id, users]);

  return (
    <Layout>
      <div className="p-1 py-5 min-h-screen">
        <ComeBackButton prev={location?.state?.prevPath} next={"/parametres/utilisateurs"} />
        <div className="">
          <UserProfile user={user} />
        </div>
      </div>
    </Layout>
  );
};

export default UserDetails;
