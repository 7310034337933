import React, { useEffect, useState } from "react";
import {
  BlogDetailsform,
  ComeBackButton,
} from "../../components";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import CmsLayout from "../cms";

const BlogDetails = () => {
  const { id } = useParams();
  const blogs = useSelector((state) => state?.data?.blogs) || [];
  const [blog, setBlog] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isNaN(id)) {
      navigate("/pages/blogs");
      return;
    }
    const clt = blogs?.find((client) => client?.id === parseInt(id));
    if (blogs?.length > 0 && !clt) {
      navigate("/pages/blogs");
      return;
    }
    setBlog(clt);
  }, [id, blogs]);


  return (
    <CmsLayout>
        <div className="p-4 min-h-screen">
          <ComeBackButton prev={location?.state?.prevPath} next={"/pages/blogs"} />
          <BlogDetailsform blog={blog} />
        </div>
    </CmsLayout>
  );
};

export default BlogDetails;
