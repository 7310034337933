import React, { useState, useEffect, useRef } from 'react';

const SortByBtn = ({ sortOptions, sortOption, setSortOption }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleSortOptionClick = (option) => {
    setSortOption(option);
    setDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative text-gray-gt font-medium h-full" ref={dropdownRef}>
      <button 
        className='w-full md:w-auto bg-white border rounded-full py-2 px-4 flex items-center justify-between'
        onClick={toggleDropdown}
      >
        Trier par: {sortOption} <i className="bi bi-chevron-down"></i>
      </button>
      {isDropdownOpen && (
        <div className="absolute bg-white border rounded-lg mt-2 w-full z-10">
          {sortOptions?.map((option, index) => (
            <div
              key={index}
              className="px-4 py-2 cursor-pointer hover:bg-gray-200"
              onClick={() => handleSortOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SortByBtn;
