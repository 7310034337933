import { useLocation, useNavigate } from "react-router-dom";
import { ProfileImg } from "../../../assets/images";
import { useEffect, useState } from "react";
import FormateDateRange from "../../../helpers/FormateDateRange";
import { BASE_IMAGE_URL } from "../../../constants/actions";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid"; // Heroicons

const ReservationsClients = ({ reservations, itemsPerPage = 6 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(reservations?.length / itemsPerPage);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPage(1);
  }, [reservations]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleRows = reservations?.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const getPaginationButtons = () => {
    const maxButtons = 3;
    const buttons = [];
    let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxButtons - 1);

    if (currentPage <= Math.floor(maxButtons / 2)) {
      endPage = Math.min(totalPages, maxButtons);
    }
    if (currentPage > totalPages - Math.floor(maxButtons / 2)) {
      startPage = Math.max(1, totalPages - maxButtons + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 ${
            currentPage === i
              ? "bg-orange-primary text-white"
              : "text-gray-950 bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden min-h-screen py-4">
      <div className="max-w-xl space-y-4">
        <div className="px-3">
          <h2 className="text-gray-gt font-bold">Réservations</h2>
        </div>
        <div className="flex flex-col justify-between">
          <div>
            {reservations?.length ? (
              visibleRows?.map((reservation) => (
                <div
                  key={reservation?.id}
                  onClick={() =>
                    navigate(`/reservations/${reservation?.id}`, {
                      state: { prevPath: location?.pathname },
                    })
                  }
                  className="flex items-center gap-4 px-4 py-3 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 cursor-pointer hover:bg-blue-50"
                >
                  {/* Avatar */}
                  <div className="w-14 h-12 bg-gray-200 rounded-full overflow-hidden border-2 border-gray-300">
                    <img
                      src={
                        reservation?.user?.Avatar
                          ? BASE_IMAGE_URL + reservation?.user?.Avatar
                          : ProfileImg
                      }
                      alt="avatar"
                      className="object-cover w-full h-full"
                    />
                  </div>

                  {/* User and reservation details */}
                  <div className="flex flex-col gap-1 w-full">
                    <h3 className="text-gray-900 font-semibold text-base capitalize leading-tight">
                      {reservation?.user?.first_name}{" "}
                      {reservation?.user?.last_name}
                    </h3>

                    {/* Date and time */}
                    <div className="text-gray-600 text-sm flex justify-between items-center w-full">
                      <span>
                        {reservation?.availability?.date &&
                          FormateDateRange(
                            reservation?.availability?.date,
                            reservation?.availability?.start_time,
                            reservation?.availability?.end_time
                          )
                        }
                      </span>
                      <span className="text-gray-800 font-medium">
                        {reservation?.payment?.amount} Dhs
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500 font-light flex flex-col items-center gap-3 mt-10">
                <CalendarIcon className="w-12 h-12 text-orange-300" />
                <p className="text-lg text-black font-bold">
                  Aucune Réservation
                </p>
                <p className="text-sm text-gray-400 font-semibold">
                  Ce client n'a pas encore de réservations
                </p>
              </div>
            )}
          </div>
          {reservations?.length > itemsPerPage && (
            <div className="flex items-center justify-center text-xs sm:text-sm md:text-base px-7 py-5">
              <nav className="flex items-center space-x-1 md:space-x-2 font-medium">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 text-gray-950 bg-gray-200 disabled:opacity-50"
                >
                  <ChevronLeftIcon className="w-4 h-4" />
                </button>
                {getPaginationButtons()}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="w-7 min-w-7 sm:w-8 md:w-9 h-5 min-h-5 sm:h-6 md:h-7 text-gray-950 bg-gray-200 disabled:opacity-50"
                >
                  <ChevronRightIcon className="w-4 h-4" />
                </button>
              </nav>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReservationsClients;
