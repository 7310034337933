import React, { useEffect, useState } from "react";
import { ParametresLayout, ServicesListe } from "../../components";
import { useSelector } from "react-redux";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router";

const Services = () => {
  const services = useSelector((state) => state?.data?.services);
  const [filtredServices, setFiltredServices] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const user = useSelector((state) => state?.auth?.user);
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!searchInput) {
      setFiltredServices(services);
      return;
    }

    const searchLowerCase = searchInput.trim().toLowerCase();

    const filtered = services?.filter((service) => {
      const firstName = service?.name?.toLowerCase();

      return firstName?.includes(searchLowerCase);
    });

    setFiltredServices(filtered);
  }, [searchInput, services]);


  return (
    <ParametresLayout>
      <div className="min-h-screen px-1 py-5">
        <div className="flex justify-between">
          <div className="min-w-72">
            <input
              type="text"
              name="nom"
              id="nom"
              placeholder="Nom"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              className="w-full py-2 px-3 border rounded-full outline-none focus:outline-none"
            />
          </div>
          <button
            onClick={() =>
              navigate("/parametres/addservices", {
                state: { prevPath: location?.pathname },
              })
            }
            className="flex items-center justify-center py-2 px-4 bg-orange-600 text-white rounded-full hover:bg-orange-700 transition duration-300"
          >
            <UserPlusIcon className="h-5 w-5 mr-2" />
            Ajouter
          </button>
        </div>
        <ServicesListe services={filtredServices} user={user} />
      </div>
    </ParametresLayout>
  );
};

export default Services;
